<template>
  <div style="height: 100%; padding-top: 67px" class="pa-5">
    <v-breadcrumbs divider="/" :items="breadCrumsItems"> </v-breadcrumbs>
    <v-toolbar color="transparent" flat > 
      <v-tabs
          color="deep-purple accent-4 "
          right
          v-model="currentItem"
          style="background: rgba(255, 255, 255, 0);!important"
        > 
          <v-tab href="#locations">Ubicaciones</v-tab>
          <v-tab href="#dashboard">Dashboard</v-tab>
        </v-tabs>
      <!-- <template v-slot:extension>
        
      </template> -->
    </v-toolbar>

    <v-tabs-items
      v-model="currentItem"
      style="background: rgba(255, 255, 255, 0)"
    >
      <v-tab-item :key="0" value="locations">
        <LocationsCard :customer="customer"></LocationsCard>
      </v-tab-item>

      <v-tab-item :key="1" value="dashboard">
        <div class="row justify-center align-center">
          <div class="col-12" >
            <LocationChart
              :customer="customer"
              @tableOption="table = $event"
              @type="locationChartType=$event"
              @updateDate="dateRange=$event"
            ></LocationChart >
          </div>
          <div class="col-12">
            <LocationDataInfo
              :customer="customer"
              @tableOption="table = $event"
              :dateRange="dateRange"
            ></LocationDataInfo>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LocationsCard from "./components/LocationsCard";
import LocationChart from "./components/LocationChart";
import LocationDataInfo from "./components/LocationDataInfo";
import moment from 'moment'
export default {
  mapType: "DashboardClient",
  props: ["customer"],
  components: { LocationsCard, LocationChart, LocationDataInfo },
  data: () => ({
    currentItem: "locations",
    locationChartType:'bar',
    dateRange: [
      moment(new Date()).format("YYYY-MM-DD ") + "00:00",
      moment(new Date()).format("YYYY-MM-DD ") + "23:59",
    ],
  }),
  methods: {},

  created() {
    if (this.$route.query.dstart)
      this.dateRange = [this.$route.query.dstart, this.$route.query.dend];
  },
  computed: {
    breadCrumsItems(){
      return [
      {
          text: "Mapa",
          disabled: false,
          href: "/",
        },
        {
          text: "Clientes",
          disabled: false,
          href: "/clients/" ,
        },
        {
          text: "Locaciones",
          disabled: true,
        },
      ]
    }
  },
};
</script>
