import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"aplications";
class User {
	static async get(headers) {		
		try {
			var { data } = await Connection.get(URL,headers);
			return data
		} catch ( error ) {
			return error
		}
	}
	static save(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL
			try {
				const res = await Connection.post(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await Connection.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static async update ( id,userData ) {
		var url = (URL+"/"+id)
		try {
			var { data } = await Connection.put(url,userData);
			return data
		} catch ( error ) {
			return error
		}
    }
    static async del ( id ) {
		var url = (URL+"/"+id)
		try {
			var { data } = await Connection.delete(url);
			return data
		} catch ( error ) {
			return error
		}
    }
}

export default User;