<template>
    <div style="position:relative;width:120px" class="text-center mt-2">
        <v-badge
            :value="sensor.selected"
            bordered
            color="primary"
            icon="fa-check"
            overlap
        >
            <v-btn @click="selectSensor();" :color="sensor.color" dark fab large><div style="font-size:15px;width:100%">{{!!sensor?sensor.datas[sensor.datas.length-1].values[sensor.pos].value:''}}</div> <div style="position:absolute;bottom:-18px;font-size:8px;">{{!!sensor?sensor.datas[sensor.datas.length-1].values[0].type.unit:''}}</div> </v-btn>
        </v-badge>
        
        <div style="width:100%;font-size:12px" class="text-center">{{!!sensor?sensor.datas[sensor.datas.length-1].values[sensor.pos].type.text:''}}</div>        
    </div>
</template>
<script>
import MiniLinearChart from './MiniLinearChart.vue'
import SensorDetails from './SensorDetails.vue'
// import Rangos from './Rangos.vue'
export default {
    props:["sensor"],
    components:{MiniLinearChart,SensorDetails},
    data:()=>({
        tab:0,
        dialogSettings:false,
        dialogAlerts:false,
        pos:0
    }),
    methods:{
        createdChart(){

        },
        options(){
            this.dialogSettings=true;
        },
        updateSensor(sensor){
            this.$emit('sensor-update',sensor);
        },
        selectSensor(){
            if(!this.sensor.selected && this.pos>=0){
                this.$emit('sensor-update',{...this.sensor,selected:true});
            }else if(this.sensor.selected){
                this.addSelected();
                if(this.pos==0) this.$emit('sensor-update',{...this.sensor,selected:false,pos:this.pos});
                else this.$emit('sensor-update',{...this.sensor,selected:true,pos:this.pos});
            }
            
        },
        addSelected(){
            this.pos=this.pos<this.sensor.datas[this.sensor.datas.length-1].values.length-1?this.pos+1:0
        }
    },
    created(){
        if(!this.width) this.width="400";
    }
}
</script>
<style >
    .card-container{
        position: relative;
        overflow: hidden;
    }
    .card-icon{
        position: absolute;
        top:10px;
        right: 15px;
    }
    .sonda-type{
        position: absolute;
        top:-2px;
        left: 52px;
    }
</style>