<template>
    <div >
        <v-img
              src="@/assets/images/ivan-bandura-6wSevhW1Dzc-unsplash.jpg"              
              class="grey darken-4"
              max-height="100%"
            >
            <Loading size="20" :active="isLogin"/>
            <div class="fill-height" style="background:#000000aa">
                <v-row align="center" justify="center" style="height:100vh;max-width:450px; background: #00000080;">
            <v-col cols="12" >
                <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="submit()">
                    <v-card color="transparent" flat elevation="0" dark>
                        <v-card-title primary-title class="text-center justify-center">
                            <div class="">
                                <img src="@/assets/logo.2.svg" alt=""  height="50px">
                            </div>
                            
                        </v-card-title>
                        <v-card-text>
                            <div style="width:100%;font-size:25px" class="mb-5 text-center secondary--text">
                                Inicia sesión
                            </div>
                            
                            <v-text-field
                                label="Correo electrónico"
                                v-model="email"
                                :rules="emailRules"
                                required                                
                                
                                filled
                                dense
                                prepend-inner-icon="email"
                                color="secondary"
                            ></v-text-field>
                            <v-text-field
                                label="Contraseña"
                                v-model="password"
                                :rules="passRules"
                                type="password"
                                required                                
                                
                                dense
                                filled
                                prepend-inner-icon="lock"
                                color="secondary"
                            ></v-text-field>
                            <v-checkbox
                                label="Recordarme"
                                v-model="rememberme"
                            ></v-checkbox>
                            <div style="width:100%" class="text-center error--text">{{error}}</div>
                            <v-btn color="secondary" text to="/sendCode">No recuerdo mi contraseña</v-btn>
                            <v-btn color="secondary" text to="/register">Registrate</v-btn>
                            
                        </v-card-text>
                        <v-card-actions > 
                            <v-spacer   ></v-spacer>                      
                            <v-btn
                                
                                large
                                
                                type="submit"
                                color="primary"
                                >
                                    <span class="ma-5">
                                        Iniciar sesión
                                    </span>
                                    
                                </v-btn>
                            
                        </v-card-actions>

                    </v-card>                    
                    
                    
                </v-form>
            </v-col>
        </v-row>
            </div>
        </v-img>
    </div>
</template>
<script>
import User from "../../services/LoginService";
import Loading from "../../components/Loading";
export default {    
    components:{Loading},
    data: () => ({
        isLogin:false,
        error:"",
        valid: true,
        password: "",
        passRules: [v => !!v || "Este es obligatorio"],
        email: "",
        emailRules: [v => !!v || "El correo electrónico es obligatorio"],        
        rememberme: false
    }),

    methods: {
        async submit() {
            try {
               if(this.$refs.form.validate()){
                   this.isLogin=true;
                    let res= await User.login({email:this.email,password:this.password,rememberme:this.rememberme});
                    if(res){
                        this.$session.set("session",res.token);
                        this.$session.set("user",res.user);
                        location.href="#/";
                    } 
               }
                
            } catch (error) {
                this.error="Usuario y/o contraseña incorrectos"     
            }finally{
                this.isLogin=false;
            }           
        },
        clear() {
            this.$refs.form.reset();
        }
    }
};
</script>