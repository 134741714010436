<template>
  <div style="height: 100%; padding-top: 67px" class="pa-5">
    <Loading :active="loading"></Loading>
    <v-row>
      <v-col cols="12" md="4">
        <v-breadcrumbs divider="/" :items="breadCrumsItems"> </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row style="height: 80%">
      <v-col>
        <v-card elevation="0" height="100%">
          <v-icon color="primary"></v-icon>
          <v-card-title primary-title class="white">
            <v-select
              color="primary--text"
              v-model="locationObj"
              :items="locations"
              item-text="name"
              return-object
              rounded
              dense
              filled
              hint="Selecciona una locación"
              persistent-hint
              style="max-width: 300px"
              @change="openLink($event)"
            >
              <template v-slot:prepend-inner>
                <v-icon color="primary">water_damage</v-icon>
              </template>
            </v-select>
            <!-- <v-select
              color="primary--text"
              v-model="selectedDevice"
              :items="items"
              item-text="name"
              return-object
              rounded
              dense
              filled
              hint="Selecciona un dispositivo"
              persistent-hint
              style="max-width:300px"
              @change="sensors = selectedDevice.sensors"
            > -->
            <v-select
              color="primary--text"
              v-model="selectedDevice"
              :items="items"
              return-object
              rounded
              dense
              filled
              hint="Selecciona un dispositivo"
              persistent-hint
              style="max-width: 300px"
              @change="openLinkByDeviceSelect"
            >
              <template v-slot:prepend-inner>
                <v-icon color="warning" small>fa-broadcast-tower</v-icon>
              </template>
              <template v-slot:selection="{ item }">
                <v-avatar
                  :color="
                    $moment().diff(item.last_send, 'hours') > 24
                      ? 'deep-orange lighten-2'
                      : 'green accent-2'
                  "
                  size="22"
                >
                  {{ items.indexOf(item) + 1 }}
                </v-avatar>
                <div class="text-truncate">
                  {{ item.name }}
                  <span class="text-caption text-truncate"
                    >({{ item.description }})</span
                  >
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div>
                  <v-avatar
                    :color="
                      $moment().diff(item.last_send, 'hours') > 24
                        ? 'deep-orange lighten-2'
                        : 'green accent-2'
                    "
                    size="22"
                  >
                    {{ items.indexOf(item) + 1 }}
                  </v-avatar>
                  {{ item.name }}
                  <span class="text-caption text-truncate">
                    ({{ item.description }})
                  </span>
                </div>
              </template>
            </v-select>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              small
              @click="tableView = !tableView"
              ><v-icon small v-if="tableView">timeline</v-icon>
              <v-icon small v-else> toc</v-icon>
            </v-btn>
            <v-btn color="primary" small @click="setDeviceDialog = true">
              <v-icon small>fa-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title primary-title class="secondary--text">
                    <div>
                      {{ selectedDevice.name }}
                      <span
                        class="text-caption text-truncate grey--text text--darken-2"
                      >
                        ({{ selectedDevice.description }})</span
                      >
                    </div>
                    <v-spacer></v-spacer>
                    <span
                      style="text-transform: capitalize; font-size: 13px"
                      class="grey--text text--darken-4 mx-5"
                    >
                      {{ $moment(dateRange[0]).format("MMMM-DD YYYY") }} -
                      {{ $moment(dateRange[1]).format("MMMM-DD YYYY") }}
                    </span>
                    <!-- <v-btn color="purple" dark elevation="0" fab x-small class="" :to="`/devices/relaymodules/${selectedDevice._id}`">
                                            <v-icon x-small>settings_input_component</v-icon>
                                        </v-btn> -->
                    <CreateXls :sensors="sensors" :device="selectedDevice" />
                    <v-btn
                      color="secondary"
                      elevation="0"
                      fab
                      x-small
                      class="mr-2"
                      @click="editDevice()"
                    >
                      <v-icon x-small>fa-pen</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      elevation="0"
                      fab
                      x-small
                      class="mr-2"
                      @click="restoreDate()"
                    >
                      <v-icon x-small>fa-sync-alt</v-icon>
                    </v-btn>
                    <FilterMenu
                      :value="dateRange"
                      @input="updateConfig($event)"
                    />
                  </v-card-title>
                  <v-card-text v-if="!tableView">
                    <v-row>
                      <!-- <v-col cols="6" md="4" lg="3" xl v-for="(sensor,sensorIndex) in selectedDevice.sensors" :key="sensorIndex+sensor.name"> -->
                      <SliderCardSensors
                        :device="selectedDevice"
                        :restore="restore"
                        :sensors="sensors"
                        @sensor-update="updateSensor($event)"
                      />
                      <!-- </v-col> -->
                    </v-row>
                  </v-card-text>
                  <v-card-text v-if="tableView">
                    <LocationDataInfo
                      :location="location"
                      :dateRange="dateRange"
                    ></LocationDataInfo>
                  </v-card-text>
                </v-card>
                <div
                  v-if="selectedDevice.device_type != 'FlowV3' && !tableView"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-card class="cloud-style2 secondary--text">
                        <v-card-title primary-title>
                          Grafica de sensores
                        </v-card-title>
                        <v-card-text>
                          <SensorDataChart
                            :restore="restore"
                            :device="selectedDevice"
                            :updateData="updateData"
                            :sensors="sensors.length > 0 ? sensors : []"
                            :dateRange="getDateRange"
                            @data-updated="dataUpdated()"
                          ></SensorDataChart>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col
                cols="12"
                v-if="
                  sensors.filter((sensor) => sensor.type._id == 7).length > 0 &&
                  !tableView
                "
              >
                <FlowChart
                  :restore="restore"
                  :device="selectedDevice"
                  :sensors="sensors.filter((sensor) => sensor.type._id == 7)"
                  :dateRange="getDateRange"
                ></FlowChart>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <LocationMap
                  :location="locationObj"
                  @update-latLng="updateLatLng($event)"
                />
              </v-col>
              <v-col cols="12" md="6" lg="8">
                <SvgLocationDevice
                  :location="locationObj"
                  :devices="items"
                ></SvgLocationDevice>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogDeviceEdit"
      scrollable
      persistent
      max-width="400px"
      transition="dialog-transition"
      class="rounded-lg"
    >
      <v-form v-on:submit.prevent="save()">
        <v-card rounded="lg">
          <v-card-title primary-title class="primary white--text">
            Editar dispositivo
            <v-spacer></v-spacer>
            <v-btn color="white" fab text @click="closeEdited()">
              <v-icon small>fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  color="primary--text"
                  v-model="editedItem.deviceLocationType"
                  :items="deviceLocationTypes"
                  item-text="text"
                  rounded
                  filled
                  return-object
                  label="Tipo de ubicacion del dispositivo"
                  @change="changeName($event)"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  name="name"
                  label="Nombre del dispositivo"
                  id="id"
                  required
                  rounded
                  dense
                  filled
                  readonly
                ></v-text-field>
                <v-textarea
                  v-model="editedItem.description"
                  name="name"
                  label="Descripción del dispositivo"
                  id="id"
                  required
                  rounded
                  dense
                  filled
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">
              <span class="px-5">Guardar</span>
            </v-btn>
            <v-btn color="secondary darken-2" outlined @click="closeEdited()">
              <span class="px-5">Cancelar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="setDeviceDialog"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-form>
        <v-card>
          <v-card-title primary-title class="primary white--text">
            Agregar dispositivo
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="closeAddDeviceDialog()">
              <v-icon small>fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="deviceId"
                  name="deviceCode"
                  label="Código del dispositivo"
                  hint="Ingresa el código del dispositivo"
                  required
                  rounded
                  dense
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="addDeviceToLocation()"
              >Agregar</v-btn
            >
            <v-btn color="error" outlined @click="closeAddDeviceDialog()"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import Devices from "../../services/DevicesService";
import DeviceType from "../../services/DeviceType";
import SensorMiniCard1 from "../Sensors/SensorMiniCard1";
import SliderCardSensors from "../Sensors/SliderCardSensors";
import FlowChart from "../Sensors/FlowChart";
import FlowSensorCard from "../Sensors/FlowSensorCard";
import SensorDataChart from "../Sensors/SensorDataChart";
import Locations from "../../services/Locations";
import LocationMap from "../Locations/LocationMap";
import FilterMenu from "../../components/FilterMenu";
import { gmapApi } from "vue2-google-maps";
import Loading from "@/components/Loading";
import moment from "moment";
import SvgLocationDevice from "../../components/SvgLocationDevice";
import CreateXls from "@/components/CreateXls";
import LocationDataInfo from "./components/LocationDataInfo";
export default {
  props: ["location", "device"],
  components: {
    LocationDataInfo,
    CreateXls,
    LocationMap,
    SensorMiniCard: SensorMiniCard1,
    FlowSensorCard,
    SensorDataChart,
    FlowChart,
    FilterMenu,
    SvgLocationDevice,
    SliderCardSensors,
    Loading,
  },
  data: () => ({
    tableView: false,
    loading: false,
    rulesArea: [
      (v) => !!v || "La superficie es requerida",
      (v) => (!!v && v > 0) || "El valor no puede ser menor a cero",
    ],
    restore: false,
    totalFlow1: 0,
    weather: {},
    mapType: "hybrid",
    setDeviceDialog: false,
    dialogDeviceEdit: false,
    deviceId: "",
    selectedDevice: {
      sensors: [],
    },
    updateData: -1,
    dateRange: [
      moment().format("YYYY-MM-DD ") + "00:00",
      moment().format("YYYY-MM-DD ") + "23:59",
    ],
    center: {
      lat: 23.3143515,
      lng: -103.3223592,
    },

    items: [],
    search: "",
    editedItem: {
      name: "",
      location: {},
      status_id: 0,
      area: null,
      unit: null,
    },
    defaultItem: {
      name: "",
      location: {},
      status_id: 0,
    },
    editedIndex: -1,
    locationObj: {},
    locations: [],
    icon: {
      path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
      fillColor: "#E71C26",
      fillOpacity: 0.9,

      strokeWeight: 1,
      scale: 0.08,
    },
    sensors: [],
    deviceLocationTypes: [],
  }),
  methods: {
    changeName(e) {
      if (this.locationObj.locationType) {
        this.editedItem.name =
          this.editedItem.device_id +
          "-" +
          e.text +
          "-" +
          this.locationObj.locationType.format;
      } else {
        console.error("No se ha seleccionado un tipo de locación");
      }
    },
    async loadDeviceLocationType() {
      try {
        let res = await DeviceType.findByCustomer(this.locationObj.customer);
        this.deviceLocationTypes = res;
      } catch (error) {
        console.log(error);
      }
    },
    validateSuperfice(event) {
      if (event <= 0) {
        this.editedItem.area = 1;
      }
    },
    validate() {
      if (this.editedItem.unit == "m²") {
        this.editedItem.area = Math.trunc(this.editedItem.area);
      }
    },
    changeValueArea(unit) {
      if (unit === "ha") {
        this.editedItem.area = this.editedItem.area / 10000;
      }
      if (unit === "m²") {
        this.editedItem.area = this.editedItem.area * 10000;
        this.editedItem.area = Math.trunc(this.editedItem.area);
      }
    },
    pruebaFlow(e) {
      this.totalFlow1 = e;
    },
    dataUpdated() {
      this.updateData = -1;
    },
    updateSensor(obj) {
      this.sensors[obj.index] = Object.assign(
        this.sensors[obj.index],
        obj.sensor
      );
      this.updateData = !this.updateData;

      // this.updateData=-1
    },
    updateLocation(location) {
      this.locationObj = Object.assign(this.locationObj, location);
    },
    updateConfig(d) {
      this.dateRange = d;
    },
    restoreDate() {
      this.restore = !this.restore;
    },
    openLink(e) {
      location.href = "/mylist/location/" + e._id;
    },
    openLinkByDeviceSelect(e) {
      // dateRange
      // this.$router.replace({
      //   path: "/mylist/location/" + e.location + "/" + e._id,
      //   query: { dstart: v[0], dend: v[1] },
      // });
      location.href =
        "/mylist/location/" +
        e.location +
        "/" +
        e._id +
        "?" +
        "dstart=" +
        this.dateRange[0] +
        "&dend=" +
        this.dateRange[1];
    },
    async loadLocation() {
      this.loading = true;
      try {
        let res = await Locations.find(this.location, { populate: true }); 
        for (let index = 0; index < res.devices.length; index++) {
          const device = res.devices[index];
          res.devices[index].sensors = device.sensors.map((item, i) => ({
            ...item,
            pos:
              !!device.device_type &&
              ["FlowV2", "FlowV3"].includes(device.device_type)
                ? 0
                : item.type._id == 7
                ? 1
                : 0,
            selected: i == 0 ? true : false,
          }));
        }
        this.locationObj = res;

        this.items = res.devices;
        if (!!this.device) {
          let d = this.items.find((v) => v._id == this.device);
          this.selectedDevice = d;
        } else if (this.items.length > 0) {
          this.selectedDevice = this.items[0];
        }
        this.sensors = this.selectedDevice.sensors;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async loadLocations() {
      try {
        let res = await Locations.getMyLocations(this.locationObj.customer);
        this.locations = res;
      } catch (error) {
        console.log(error);
      }
    },
    closeEdited() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.dialogDeviceEdit = false;
    },
    updateDeviceData(device) {
      this.items[this.editedIndex] = Object.assign(
        this.items[this.editedIndex],
        device
      );
      this.closeEdited();
    },
    async save() {
      if (this.editedItem.area < 0) {
        this.editedItem.area = 0;
      } else {
        let res = await Devices.update(this.editedItem._id, this.editedItem);
        this.updateDeviceData(this.editedItem);
      }
    },
    editDevice() {
      this.editedItem = Object.assign({}, this.selectedDevice);
      this.editedIndex = this.items.indexOf(this.selectedDevice);
      this.dialogDeviceEdit = true;
    },
    async addDeviceToLocation() {
      try {
        let device = await Locations.addDevice(this.location, {
          device: this.deviceId,
        });
        this.closeAddDeviceDialog();
      } catch (error) {
        console.log(error);
        this.closeAddDeviceDialog();
      }
    },
    closeAddDeviceDialog() {
      this.loadLocations();
      this.loadLocation();
      this.setDeviceDialog = false;
    },
  },
  async created() {
    if (this.$route.query["dstart"])
      this.dateRange[0] = this.$route.query["dstart"];
    else
      this.$router.replace({
        query: { dstart: this.dateRange[0], dend: this.dateRange[1] },
      });
    if (this.$route.query["dend"])
      this.dateRange[1] = this.$route.query["dend"];
    await this.loadLocation();
    await this.loadLocations();
    this.loadDeviceLocationType();
  },
  computed: {
    getDateRange() {
      return [
        this.$route.query["dstart"]
          ? this.$route.query["dstart"]
          : this.dateRange[0],
        this.$route.query["dend"]
          ? this.$route.query["dend"]
          : this.dateRange[0],
      ];
    },
    breadCrumsItems() {
      return [
        {
          text: "Mapa",
          disabled: false,
          href: "/",
        },
        {
          text: "Clientes",
          disabled: false,
          href: "/clients/" ,
        },
        
        {
          text: "Locaciones",
          disabled: false,
          href: "/mylist/"+this.locationObj.customer,
        },
        {
          text: "Dispositivos",
          disabled: true,
        },
      ];
    },
    google: gmapApi,
    mapStyle() {
      let opt = {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
      // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
      return this.$vuetify.theme.dark
        ? Object.assign(darkStyle, opt)
        : Object.assign(rieggoStyle, opt);
    },
  },
};
</script>
