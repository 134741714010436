<template>
    <div :class="`${$store.state.rounded?'map-rounded':''}`" style="position:relative;overflow:hidden;height:100%;width:100%">
        <GmapMap
            :center="center"
            :zoom="zoom"
            :map-type-id="mapType"
            v-bind:options="mapStyle"
            style="width: 100%; height: 100%"
            @click="centerChanged"
            @center_changed="centerChanged"
            @zoom_changed="zoomChanged"
           

        >
            <GmapMarker                        
                v-for="(device, index) in devices"
                v-if="zoom>15 && google && devices.length>0"
                :key="index"
                :position="device.location"
                :clickable="true"
                :draggable="false"
                :icon="{...icon,anchor:new google.maps.Point(200,500),scale:(markerActive && actualDevice.device_id==device.device_id)?0.15:0.08}"
                :title="`${device.name}`"
                @click="center=device.location;sensorOptions($event,device,index)"
            />
            <GmapMarker                        
                v-for="(location, index) in fields"
                v-if="zoom<=15 && google && !markerActive"
                :key="index+'asdasd'"
                :position="getCenter(location.polygon)"
                :clickable="true"
                :draggable="false"
                :icon="{...icon2,anchor:new google.maps.Point(260,300),scale:(location.devices.length+4)/100}"
                :title="`Dispositivos: ${location.devices.length} \nLocación: ${location.name} `"
                @click="center=getCenter(location.polygon);zoom=16"
            />
            
            <gmap-polygon v-if="zoom>15" :options="polygonOptions" v-for="location in fields" :key="location.code" :paths="location.polygon"></gmap-polygon>
        </GmapMap>
        <div style="position:absolute;top:100%;left:100%;margin-left:-50px;margin-top:-60px">
            <v-speed-dial
            v-model="fab"
            v-if="!(markerActive && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))"
            bottom
            right
            direction="left"
            transition="slide-y-transition"
            >
                <template v-slot:activator>
                    <v-btn
                    v-model="fab"
                    color="primary"
                    dark
                    fab
                    >
                    <v-icon v-if="fab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        fa-layer-group
                    </v-icon>
                    </v-btn>
                </template>
                
                <v-btn
                    rounded
                    small
                    color="white"
                    @click="mapType='roadmap'"
                >
                    
                    Mapa 
                </v-btn>
                <v-btn
                    rounded
                    small
                    color="white"
                    @click="mapType='hybrid'"
                >
                    Satelite
                </v-btn>
                
            </v-speed-dial>
        </div>
        <MobileDeviceInfo v-if="(markerActive && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :active="(markerActive && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :device="actualDevice" :weather="weather" :days="days" @select-days="loadDeviceByDay($event)"/>
        <SensorsRadar v-if="(markerActive && !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :active="(markerActive && !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :sensors="actualDevice.sensors" @onsensor-select="sensorSelect"/>
        <DeviceInfo v-if="(markerActive && !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :active="(markerActive && !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :device="actualDevice"/>
        <OneSensorLinearChart v-if="(sensorActive && markerActive && !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" :sensor="actualSensor" :active="(sensorActive && markerActive && !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm))" @open-dashboard="openDashboard()"/>
        
    </div>
</template>
<script>
import {gmapApi} from 'vue2-google-maps'

import SensorsRadar from '../../components/SensorsRadar.vue'
import OneSensorLinearChart from '../../components/OneSensorLinearChart.vue'
import MobileDeviceInfo from '../../components/MobileDeviceInfo.vue'
import DeviceInfo from '../../components/DeviceInfo.vue'
import darkStyle from '../../components/maps/darkStyle.json'
import rieggoStyle from '../../components/maps/rieggoStyle.json'
import rieggoMapImage from '../../assets/img/roadmap-rotoplas-maps.png'
import DevicesService from '../../services/DevicesService.js'
import Location from '../../services/Locations.js'
import Sensors from '../../services/SensorsService.js'
import OpenWeather from '../../services/OpenWeatherService'
export default {
    props:["deviceId"],
    components:{DeviceInfo,SensorsRadar,OneSensorLinearChart,MobileDeviceInfo},
    data:()=>({
        zoom:5,
        mapType:"hybrid",
        markerActive:false,
        sensorActive:false,
        actualSensor:{},
        center:{lat:23.8579465,lng:-101.9935437},
        fab:false,
        devices:[],
        actualDevice:{},
        fields:[],
        days:0,
        polygonOptions:{
            strokeColor: 'transparent',
            fillColor: "#FF0000"
        },
        icon:{
            path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
            fillColor: '#00C7B1',
            fillOpacity: .9,
            
            strokeWeight: 1,
            scale: .08
        },
        icon2:{
            path: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z",
            fillColor: '#E71C26',
            fillOpacity: .9,
            
            strokeWeight: 0,
            scale: .05
        },
        weather:{

        }
    }),
    methods:{
        async loadLocations(){
            this.fields=await Location.get({includes:["status",{"devices":{includes:["sensors"]}},"polygon"]});
         
        },
        openDashboard(){
            location.href='#/dashboard/'+this.actualDevice.device_id
        },
        centerChanged(e){
            this.center={}
            this.markerActive=false;
            this.ctualSensor={};
            this.sensorActive=false;
        },
        zoomChanged(e){
            this.zoom=e;
            
        },
        getCenter(path){
            var bounds = new this.google.maps.LatLngBounds();
            for (let i = 0; i < path.length; i++) {
                bounds.extend(path[i]);
            }
            return bounds.getCenter();
        },
        async loadDevices(){
            let self =this;
            let result=await DevicesService.get({includes:["location"]});
            this.devices=result;
            
            
        },
        async loadDeviceByDay(days){
            this.days=days;
            let res=await this.loadOneDevice(this.actualDevice.device_id);
            this.actualDevice=res
        },
        async loadOneDevice(id){
            
            let self =this;
            let result=await DevicesService.find(
                id,{
                    includes:[
                        'location',
                        'status',{
                            sensors:{
                                includes:[{
                                    data:{
                                        filter:{
                                            createdAt:{
                                                'Op.between':[
                                                    this.$moment().subtract(this.days,'days').format('YYYY-MM-DD ')+' 00:00:00 ',
                                                    this.$moment().format('YYYY-MM-DD ')+' 23:59:00 '
                                                ]
                                            }
                                        },
                                        includes:[{
                                            values:{
                                                includes:[
                                                    'type'
                                                ]
                                            }
                                        }]
                                    }
                                }]
                            }
                        }
                    ]
                }
            );
            setTimeout(() => {
                self.markerActive=true;
            }, 600);
            result.sensors=result.sensors.map(function(item){
                let div=3;
                if(Math.trunc(item.datas.length/30)>3) div=Math.trunc(item.datas.length/30)
                item.datas=item.datas.filter((data,i)=>{
                            return i%div==0
                    })
                if(item.type_id==4){
                    let cont=0;
                    
                    item.datas.map((data)=>{
                        cont+=data.values[1].value;
                        data.values.push({data_id:data.id,type:{text:'Volumen Acumulado',unit:'Lts'},value:cont});
                    })
                }
                return {...item,selected:false,pos:0}
            });
            if(result.sensors.length>0)result.sensors[0].selected=true
            return result;
        },
        async sensorOptions(marker,device,index){
            let self=this;
            this.center=new this.google.maps.LatLng(device.location.lat,device.location.lng);
            this.actualDevice=device;
            setTimeout(() => {
                 self.markerActive=true;
                }, 600);
            try {
                let sensors=await Sensors.get({
                    filter:{
                        device_id:device.device_id
                    },
                    includes:[
                        {data:{
                            limit: 1,
                            filter:{
                                createdAt:{
                                    'Op.between':[
                                        this.$moment().subtract(this.days,'days').format('YYYY-MM-DD ')+' 00:00:00 ',
                                        this.$moment().format('YYYY-MM-DD ')+' 23:59:00 '
                                    ]
                                }
                            },
                        }
                    }
                    ]
                });
                
                this.actualDevice.sensors=sensors;
            } catch (error) {
               console.log(error); 
            }

            
            // this.actualDevice=await this.loadOneDevice(device.device_id);
            this.weather=await OpenWeather.getOneCallLocation(this.actualDevice.location);
            
            this.sensorActive=false;

        },
        sensorSelect(index){
            this.actualSensor=this.actualDevice.sensors[index];            
            setTimeout(() => {
                this.sensorActive=true
            }, 300);
        }
    },
    async created(){
        await this.loadLocations();
        await this.loadDevices();
        
        if(!!this.deviceId) {
            let select=this.devices.find((item)=>{
                return item.device_id=this.deviceId
            });
            this.zoom=16;
            this.sensorOptions(null,select)
        }
    },
    computed:{
        google: gmapApi,
        mapStyle(){
            let opt={
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }
            // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
            return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign(rieggoStyle,opt)
        },
    }
}
</script>