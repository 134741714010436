<template>
  <v-card height="100%" max-height="800px" width="100%">
    <v-card-title primary-title>
      Mediciones <v-spacer></v-spacer>
      <div class="grey lighten-3" style="border-radius: 5px; padding: 2px">
        {{ $moment(dateRange[0]).format("DD-MM-YY HH:mm") }} /
        {{ $moment(dateRange[1]).format("DD-MM-YY HH:mm") }}
      </div>
      <v-btn
        color="warning mx-2 elevation-0"
        fab
        dark
        x-small
        @click="changeChartType('bar')"
        v-if="chartSelected == 'doughnut'"
      >
        <v-icon>bar_chart</v-icon>
      </v-btn>
      <v-btn
        color="warning mx-2 elevation-0"
        fab
        dark
        x-small
        @click="changeChartType('doughnut')"
        v-if="chartSelected == 'bar'"
      >
        <v-icon>pie_chart</v-icon>
      </v-btn>
      <FilterMenu :value="dateRange" @input="updateDateRange($event)" />
    </v-card-title>
    <v-card-text style="height: 350px" class="text-center">
      <BarChart
        :chartData="chartData"
        :options="optObject"
        v-if="chartSelected == 'bar'"
      />
      <div class="row" v-if="chartSelected == 'doughnut'">
        <div class="col-4">
          <v-card flat class="transparent">
            <v-card-text style="max-height: 300px; overflow-y: auto">
              <div
                v-for="(item, i) in chartData.labels"
                :key="i"
                class="row"
                style="margin: 0; padding: 0"
              >
                <div class="col-2" style="margin: 0; padding: 0">
                  <v-card
                    :style="{
                      background: chartData.datasets[0].backgroundColor[i],
                    }"
                    style="height: 10px; width: 30px"
                  >
                    <v-card-text> </v-card-text>
                  </v-card>
                </div>
                <div class="col-10">
                  {{ item }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-8">
          <v-card style="height: 280px; width: 300px" flat class="transparent">
            <v-card-text style="height: 280px; width: 300px">
              <Doughnut :chartData="chartData" :options="doughnutOptions" />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <Loading :active="loading"></Loading>
    </v-card-text>
    <v-dialog
      v-model="locationDialog"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Selección de dispositivos
          <v-spacer></v-spacer>
          <v-btn fab text x-small @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="editedIndex >= 0"
            v-model="itemsSelected[editedIndex]"
            :headers="headers"
            :items="locationSelect"
            :single-select="false"
            item-key="deviceName"
            show-select
            flat
            :items-per-page="-1"
            hide-default-footer
            :sort-by="['deviceName']"
            :sort-desc="[true]"
          >
            <template v-slot:item.total="{ item }">
              {{ selectMeasurement(item.total) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Clients from "@/services/Clients";
import Loading from "@/components/Loading";
import BarChart from "@/components/chartJs/BarsInit";
import Doughnut from "@/components/chartJs/Doughnut";
import FilterMenu from "@/components/FilterMenu";
import moment from "moment";
export default {
  props: {
    customer: { type: String },
  },
  components: { BarChart, Loading, FilterMenu, Doughnut },
  data: () => ({
    editedIndex: -1,
    chartSelected: "bar",
    locationDialog: false,
    loading: false,
    itemsSelected: [],
    locationSelect: [],

    clientData: [],
    chartData: {
      labels: [],
      datasets: [],
    },
    headers: [
      { text: "Dispositivo", value: "deviceName" },
      { text: "Consumo", value: "total" },
    ],
    dateRange: [
      moment(new Date()).format("YYYY-MM-DD ") + "00:00",
      moment(new Date()).format("YYYY-MM-DD ") + "23:59",
    ],
  }),
  watch: {
    itemsSelected: {
      deep: true,
      handler(v) {
        if (this.editedIndex < 0) return;
        const data = Object.assign(this.clientData);
        data[this.editedIndex].totalByDevice = v[this.editedIndex];
        this.createChartDate(data);
      },
    },
  },
  methods: {
    changeChartType(e) {
      this.chartSelected = e;
      this.$emit("type", e);
    },
    updateDateRange(e) {
      this.dateRange = e;
      this.$emit("updateDate", e);
      this.loadData();
    },
    async loadData() {
      this.loading = true;
      try {
        const res = await Clients.getGroupLocations(this.customer, {
          start: new Date(this.dateRange[0]),
          end: new Date(this.dateRange[1]),
        });
        this.clientData = res;
        this.itemsSelected = res.map((v) => v.totalByDevice);
        this.createChartDate(res);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.editedIndex = -1;
      this.locationDialog = false;
    },
    createChartDate(data) {
      const dt = {
        label: [],
        spanGaps: false,
        fondoColor: "#1B80CF",
        backgroundColor: [],

        borderWidth: 0,
        hidden: false,
        lineTension: 0.0,
        data: [],
      };
      let cont = 18943;
      this.chartData.labels = data.map((v) => {
        dt.data.push(v.totalByDevice.reduce((a, b) => a + b.total, 0));
        dt.backgroundColor.push("#" + cont.toString(16) + "a9");
        cont -= 1000;
        return v.name;
      });
      this.chartData = Object.assign({}, this.chartData, { datasets: [dt] });
    },
    selectMeasurement(value) {
      return value > 10000
        ? new Intl.NumberFormat("es-MX", {
            currency: "MXN",
            maximumFractionDigits: 2,
          }).format(value / 1000) + " m3"
        : parseFloat(value).toFixed(2) + " Lts";
    },
  },
  created() {
    if (this.$route.query.dstart)
      this.dateRange = [this.$route.query.dstart, this.$route.query.dend];
    this.loadData();
  },
  computed: {
    doughnutOptions() {
      const self = this;
      return {
        type: "doughnut",
        legend: {
          display: false,
          position: "right",
          align: "start",
        },
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (context, data) {
              var index = context.index;
              var currentValue =
                data.datasets[context.datasetIndex].data[index];
              let label = data.labels[context.datasetIndex];
              if (currentValue !== null) {
                label += "\n" + self.selectMeasurement(currentValue);
              }
              return label;
            },
          },
        },
        onClick: (v, clickedElements) => {
          if (!clickedElements[0]) return;
          this.editedIndex = clickedElements[0]._index;
          this.locationSelect =
            this.clientData[clickedElements[0]._index].totalByDevice;
          this.locationDialog = true;
        },
      };
    },

    optObject() {
      const self = this;
      return {
        interaction: {
          mode: "index",
        },
        spanGaps: true,

        maintainAspectRatio: false,
        responsive: true,
        chartArea: {
          background: "#ffffff",
        },
        legend: {
          position: "top",
          display: false,
          labels: {
            // fontColor:"#ffffff"
          },
        },
        title: {
          position: "bottom",
          display: true,
          text: "",
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (context) {
              let label = "";
              if (context.value !== null) {
                label = self.selectMeasurement(context.value);
              }
              return label;
            },
          },
        },
        hover: {
          mode: "index",
          intersect: false,
        },

        scales: {
          xAxes: [
            {
              fontColor: "#fff",
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Tiempo",
                // fontColor:"white"
              },
              gridLines: {
                color: "rgba(171,171,171,0.5)",
                lineWidth: 0.1,
                display: false,
              },

              ticks: {
                beginAtZero: true,
                maxTicksLimit: 100,
                maxRotation: 20,
                minRotation: 0, 
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                // labelString: 'Valor',
                fontSize: 16,
                fontStyle: "bold",
                // fontColor:"white"
              },
              ticks: {
                display: true,
                beginAtZero: true,
                steps: 10,
                // stepSize: 10,
                stepValue: 5,
                callback: function (value, index, values) {
                  return self.selectMeasurement(value);
                },
              },
              plotBands: [
                {
                  color: "orange", // Color value
                  from: 0, // Start of the plot band
                  to: 0, // End of the plot band
                },
              ],
              gridLines: {
                color: "rgba(171,171,171,0.5)",
                lineWidth: 0.5,
                display: false,
              },
            },
          ],
        },
        onClick: (v, clickedElements) => { 
          if (!clickedElements[0]) return;
          this.editedIndex = clickedElements[0]._index;
          this.locationSelect =
            this.clientData[clickedElements[0]._index].totalByDevice;
          this.locationDialog = true;
        },
      };
    },
  },
};
</script>
<style>
canvas {
  margin: 0 auto;
}
</style>
