<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
  *{
    font-family: "Rotoplas-Regular-Web"; 
    font-size:14px;
  }
  html, body{
    font-family: "Rotoplas-Regular-Web"; 
    background-color: rgb(248,248,248);
    font-size:14px;
    overflow: hidden;
  }
  .v-sheet.v-card:not(.v-sheet--outlined){
    -webkit-transition: all .4s ease .2s; 
    -moz-transition: all .4s ease 2s; 
    -o-transition: all .4s ease .2s; 
    -ms-transition: all .4s ease .2s; 
    transition: all .4s ease .2s;
    
  }
  .card-rounded.v-sheet.v-card:not(.v-sheet--outlined){
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;

  }
  .map-rounded{
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;

  }
  .card-resize.v-sheet.v-card:not(.v-sheet--outlined){
    border-radius: 25px 25px 25px 25px;
    -moz-border-radius: 25px 25px 25px 25px;
    -webkit-border-radius: 25px 25px 25px 25px;
    transform: translate(300px, 0) scale(1, 0.85);
  }
 .v-card.v-sheet.theme--light>.v-card__title{
    -webkit-transition: all .5s ease .3s; 
    -moz-transition: all .5s ease 3s; 
    -o-transition: all .5s ease .3s; 
    -ms-transition: all .5s ease .3s; 
    transition: all .5s ease .3s;
 }
 .v-card.v-sheet.theme--light>.hidden-title.v-card__title{
   transform: translate(0,-50px);
 }
  /* .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active{
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;
    display: inline-block;
    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain:none;
    
    
  } */
  .primary.v-navigation-drawer{
    transition-duration:.6s;
  }
  .primary.v-navigation-drawer.v-navigation-drawer--open.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border{
    background-color: rgba(255, 255, 255, 0.0);
  }
  .gm-style-iw.gm-style-iw-c{
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;
  }
</style>
