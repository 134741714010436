<template>
    <div :class=" `card-sensor-chart ${active?'move-up-card':'move-down-card'}`" :style="`width:${width-370}px`">
        <v-card class="card-rounded">   
            <v-card-title primary-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" small fab text @click="$emit('open-dashboard')">
                    <v-icon>fa-chart-line</v-icon>
                </v-btn>
                
            </v-card-title>         
            <v-card-text>
                <Loading :active="loading"/>
                <LineChart :chartData="chartData" :options="options" @success="successChart()" @start="restartChart()"/>                
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import LineChart from './chartJs/Line'
    import Loading from './Loading'
    export default {
        props:["sensor","active"],
        components:{LineChart,Loading},
        data:()=>({
            rangeDialog:false,
            rango:false,
            width:window.innerWidth,
            scales:{

            },
            loading:false,
            chartData:{
                labels:[],
                datasets:[]
            },
            options: {
                spanGaps: true,
                maintainAspectRatio: false,
                responsive: true,
                chartArea: {
                    background: '#ffffff'
                },
                legend: {
                    position: 'top',
                    display:false,
                    labels:{
                    // fontColor:"#ffffff"
                    }
                },
                title: {
                    position: 'bottom',
                    display: true,
                    text:''
                },
                tooltips: {
                    mode: 'index',
                    intersect: true,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                
                scales: {                    
                    xAxes: [{                        
                        // type: 'time',
                        // time: {                            
                        //     displayFormats: {
                        //         "minute": 'MMM DD HH:mm',                                
                        //     },                            
                        // },
                        fontColor:"#fff",
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Tiempo',
                            // fontColor:"white"
                        },
                        gridLines: {
                        color: 'rgba(171,171,171,0.5)',
                        lineWidth: 0.1,
                        display:false
                        },
                        
                        ticks: {
                            beginAtZero:true,
                            maxTicksLimit: 10
                            
                        },
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            // labelString: 'Valor',
                            fontSize:16,
                            fontStyle:"bold"
                            // fontColor:"white"
                        },                
                        ticks: {
                            display:true,
                            beginAtZero: true,
                            steps:10,
                            // stepSize: 10,
                            stepValue: 5,
                            callback: function(value, index, values) {
                            
                            
                                return value;
                            }
                        },
                        plotBands: [{
                        color: 'orange', // Color value
                        from: 0, // Start of the plot band
                        to: 0 // End of the plot band
                        }],
                        gridLines: {
                            color: 'rgba(171,171,171,0.5)',
                            lineWidth: 0.5,
                            display:false
                        }
                    }]
                }
            }

        }),
        watch:{
            sensor(d){
                this.chartData=this.convertirDatos();
            }
        },
        methods:{
            closeRangeDialog(){
               
                this.rangeDialog=false;
            },
            saveRange(){
                 this.options.scales.yAxes[0].ticks.min=this.scales.min
                 this.options.scales.yAxes[0].ticks.max=this.scales.max
                 this.rangeDialog=false;
            },
            autoRango(){
                if(this.rango){
                    this.rangeDialog=true;
                    this.options.scales.yAxes[0].ticks={
                        beginAtZero : true,
                        steps : 10,
                        stepValue:  5,
                        min:0,
                        max:50
                        // fontColor: "white"
                    }
                }else{
                    
                    this.options.scales.yAxes[0].ticks={
                            beginAtZero : true,
                            steps : 10,
                            stepValue:  5,
                            
                            // fontColor: "white"
                        }
                        
                }
            },
            restartChart(){
                this.loading=true;
            },
            successChart(){
                this.loading=false;
            },  
            convertirDatos(){
                var self=this;            
                var obj=Object.assign(
                    {
                        labels: [],
                        datasets: []
                    }
                );
                var dataset={ // one line graph
                    label: [],
                    spanGaps: false,
                    fondoColor:"",
                    backgroundColor: [
                    
                    //    "#f38b4a",
                        
                    ],
                // hoverBackgroundColor: [
                //  
                // ],
                    
                    borderWidth: 2,
                    hidden:false,
                    //fill: false,
                    lineTension: 0.3,
                    
                    //borderColor: "rgba(75,192,192,1)",
                    borderCapStyle: 'solid',
                    borderDash: [],
                    borderDashOffset: 0.2,
                    borderJoinStyle: 'miter',
                    pointBorderColor: "rgba(75,192,192,1)",
                    //pointBackgroundColor: "#000000",
                    pointBorderWidth:0,
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: "rgba(236,25,65,1)",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 0,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: [],
                    
                    
                };
                
                try {
                    dataset.data=this.sensor.datas.map(item=>{
                           
                        return item.values[0].value
                    });
                    obj.labels=this.sensor.datas.map(item=>this.$moment(item.createdAt).format("MMM DD HH:mm"));    
                    obj.datasets.push({...dataset,fondoColor:this.sensor.color});            
                    
                } catch (error) {
                    console.log(error);
                }
                return obj;
                
            },
             arrayObjToCsv(ar) {
                //comprobamos compatibilidad
                if(window.Blob && (window.URL || window.webkitURL)){
                var contenido = "",
                    d = new Date(),
                    blob,
                    reader,
                    save,
                    clicEvent;
                //creamos contenido del archivo
                let data=Object.assign([],this.sensors);
                data.unshift({alias:"Fecha"})
                 contenido=data.map((item)=>{
                    return item.alias
                }).join(";")+"\n"

                for (let i = 0; i < this.labels.length; i++) {
                    
                    let aux=data.map((item,index)=>{
                        if(index==0)return this.labels[i] 
                        return !!item.data[i]?item.data[i]+" "+item.unit:"";
                    }).join(";")+"\n"
                    contenido+=aux;
                }
                // creamos el blob
                blob =  new Blob(["\ufeff", contenido], {type: 'text/csv'});
                //creamos el reader
                var reader = new FileReader();
                reader.onload = function (event) {
                    //escuchamos su evento load y creamos un enlace en dom
                    save = document.createElement('a');
                    save.href = event.target.result;
                    save.target = '_blank';
                    //aquí le damos nombre al archivo
                    save.download = "log_"+ d.getDate() + "_" + (d.getMonth()+1) + "_" + d.getFullYear() +".csv";
                    try {
                    //creamos un evento click
                    clicEvent = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': true
                    });
                    } catch (e) {
                    //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
                    clicEvent = document.createEvent("MouseEvent");
                    clicEvent.initEvent('click', true, true);
                    }
                    //disparamos el evento
                    save.dispatchEvent(clicEvent);
                    //liberamos el objeto window.URL
                    (window.URL || window.webkitURL).revokeObjectURL(save.href);
                }
                //leemos como url
                
                
                reader.readAsDataURL(blob);
                this.dialogDownload=false;
                }else {
                //el navegador no admite esta opción
                alert("Su navegador no permite esta acción");
                }
            }
        },
        created(){
           window.onresize=function(){
             this.width= window.innerWidth;
           }
        },
        mounted(){

        }

    }
</script>
<style>
    .card-sensor-chart{
        -webkit-transition: all .4s ease .2s; 
        -moz-transition: all .4s ease 2s; 
        -o-transition: all .4s ease .2s; 
        -ms-transition: all .4s ease .2s; 
        transition: all .4s ease .2s;
        position:absolute;
        top:93%;
        margin-top:150px;
        background: #fff;
        left:350px;
        height:340px;
        overflow:hidden;
        border-radius: 20px 20px 20px 20px;
        -moz-border-radius: 20px 20px 20px 20px;
        -webkit-border-radius: 20px 20px 20px 20px;
    }
    .move-up-card{
        transform: scale(1,1) translate(0, -450px);
    }
    .move-down-card{
        transform:scale(0, 0) translate(0, 0);
    }
</style>