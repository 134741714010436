<template>
    <div style="height:100%">
        <div style="height:100%;width:100%;position:absolute">
            <img src="@/assets/images/background-01.jpg" alt="" >
        </div>
        <div style="height:100%;width:100%;position:absolute;background:#171E3Add">               
        </div>
        <v-row align="center" justify="center" style="height:100%">
            <v-col cols="12" md="8" lg="6" xl="4">    
                <v-card rounded="lg"  max-height="100%" >
                    <!-- <v-img src="@/assets/img/berrys-background.jpg" height="150px">
                        <div style="background:#00255480;height:100%;width:100%" >
                            <div style="width:100%;font-size:25px" class="white--text text-center pt-5">
                                Registro
                            </div>
                        </div>
                    </v-img> -->
                    <v-card-title primary-title >
                        
                    </v-card-title>
                    <v-card-text >
                        <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="submit()">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Correo electrónico"
                                        name="email"
                                        v-model="form.email"
                                        :rules="emailRules"
                                        required                                
                                        rounded
                                        filled
                                        dense
                                        prepend-inner-icon="email"
                                        color="primary"
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-text-field
                                        label="Contraseña"
                                        v-model="form.password"
                                        :rules="passRules"
                                        type="password"
                                        required                                
                                        rounded
                                        dense
                                        filled
                                        prepend-inner-icon="lock"
                                        color="primary"
                                    ></v-text-field>
                                </v-col> -->
                                <!-- <v-col cols="12">
                                    <v-text-field
                                        label="Repetir contraseña"
                                        v-model="form.rePass"
                                        :rules="repassRules"
                                        type="password"
                                        required                                
                                        rounded
                                        dense
                                        filled
                                        prepend-inner-icon="lock"
                                        color="primary"
                                    ></v-text-field>
                                </v-col> -->
                                <v-col cols="12">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="form.first_name"
                                        name="name"
                                        :rules="fieldRules"
                                        required                                
                                        rounded
                                        filled
                                        dense
                                        prepend-inner-icon="fa-user"
                                        color="primary"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Apellidos"
                                        v-model="form.last_name"
                                        :rules="fieldRules"
                                        name="last_name"
                                        required                                
                                        rounded
                                        dense
                                        filled
                                        prepend-inner-icon="fa-user"
                                        color="primary"
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-select
                                        v-model="form.gender"
                                        :items="['Masculino','Femenino']"
                                        :rules="fieldRules"
                                        menu-props="auto"
                                        label="Genero"
                                        
                                        rounded
                                        dense
                                        filled
                                        color="primary"
                                        prepend-inner-icon="fa-user-friends"
                                    ></v-select>
                                </v-col> -->
                                <v-col cols="12">
                                    <!-- <v-checkbox
                                        label="Acepto los terminos y condiciones"
                                        v-model="terms"
                                    ></v-checkbox> -->
                                    <div style="width:100%" class="text-center primary--text">{{error}}</div>
                                </v-col>
                            </v-row>
                            <Loading size="20" :active="isLogin"/>  
                        </v-form>                  
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn
                        rounded
                        large
                        type="submit"
                        color="primary"
                        @click="submit()"
                        >
                            <span class="ma-5">
                                Enviar datos
                            </span>
                            
                        </v-btn>
                    </v-card-actions>
                </v-card>      
            </v-col>
        </v-row>
        
    </div>
</template>
<script>
import User from "../../services/UserService";
import Loading from "../../components/Loading";
export default {    
    components:{Loading},
    data: () => ({
        windowHeight:window.innerHeight,
        isLogin:false,
        error:"",
        valid: true,
        form:{
            email: "",
            first_name:"",
            last_name:""
        },
        terms: false,
        passRules: [v => !!v || "El campo es obligatorio"],
        fieldRules: [v => !!v || "El campo es obligatorio"],
        
    }),

    methods: {
        async submit() {
            // this.$session.set("rieggoToken","dasdas12a1sd2a1sd");
            // this.$session.set("user",{name:"Usuario Prueba"});
            // location.href="#/";
            this.isLogin=true;
            try {
               if(this.$refs.form.validate()){
                   
                    let res= await User.autoregister(this.form);
                    location.href="/login/auth"
               }
                
            } catch (error) {
                this.error="Usuario y/o contraseña incorrectos"     
            }finally{
                this.isLogin=false;
            }           
        },
        clear() {
            this.$refs.form.reset();
        }
    },
    computed:{
        repassRules(){
            let self = this;
            return [
                    v => !!v || "El campo es obligatorio",
                    v=> (!!v && self.form.password && self.form.password==v) || 'Las contraseñas no coinciden'
                ]
        },
        emailRules(){
            let re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;    
            return [
                v => !!v || "El correo electrónico es obligatorio",
                v =>(!!v && re.test(v)) || "El correo no tiene el formato correcto"
            ]
        }
    }
};
</script>