import { Line, mixins } from 'vue-chartjs'
import moment from 'moment';
const { reactiveProp } = mixins


export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
   
    mounted() {   
        this.$refs.canvas.parentNode.style.height = '98px';
        this.chartData.datasets.forEach(element => {   
            var gradient = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 60);    
            gradient.addColorStop(0, element.fondoColor+"80");
            gradient.addColorStop(0.8, element.fondoColor+"05");
            gradient.addColorStop(1, element.fondoColor+"00");
            element.backgroundColor=gradient;
            element.pointBackgroundColor=element.fondoColor+"ff";
            element.pointBorderColor=element.fondoColor+"ff",
            element.borderColor=[
                element.fondoColor+"ff"
            ]
        });
        this.renderChart(this.chartData, this.options);        
    },
    watch: {
        chartData(data){  
                        
            this.chartData.datasets.forEach(element => {   
                var gradient = this.$refs.canvas
                .getContext("2d")
                .createLinearGradient(0, 0, 0, 0);    
                gradient.addColorStop(0, element.fondoColor+"40");
                gradient.addColorStop(0.8, element.fondoColor+"00");
                gradient.addColorStop(1, element.fondoColor+"05");
                element.backgroundColor=gradient;
                element.pointBackgroundColor=element.fondoColor+"ff";
                element.pointBorderColor=element.fondoColor+"ff",
                element.borderColor=[
                    element.fondoColor+"ff"
                ]
            });
            this.renderChart(this.chartData, this.options)
            
        },
        options: {
            handler(newOption, oldOption) {
                
                if(typeof this.options.scales.yAxes[0].ticks.min=="undefined" || typeof this.options.scales.yAxes[0].ticks.max=="undefined"){
                   // delete this.options.scales.yAxes[0].ticks.min;
                   // delete this.options.scales.yAxes[0].ticks.max
                }else{
                    this.options.scales.yAxes[0].ticks.min=parseInt(this.options.scales.yAxes[0].ticks.min);
                    this.options.scales.yAxes[0].ticks.max=parseInt(this.options.scales.yAxes[0].ticks.max);
                }
                
                this._data._chart.update();
                this._data._chart.destroy();
                this.renderChart(this.chartData, this.options)
            },
            deep: true
        }
    },methods: {
        
    },
}