<template>
    <div style="height:100%;padding-top:67px" class="pa-5">
        <v-row>
            <v-col cols="12" md="4">
                <v-breadcrumbs divider="/" :items="breadCrumsItems">
                        
                    </v-breadcrumbs>
            </v-col >
            <v-col cols="12" md="8">
                <v-text-field
                        name="search"
                        label="Buscar"
                        id="search"
                        v-model="search"
                        required                                
                        rounded
                        dense
                        filled
                        prepend-inner-icon="search"
                        color="primary"
                    >
                    </v-text-field>
            </v-col>
        </v-row>
        <v-row style="height:80%">
            <v-col>
                <v-card class="card-rounded" height="100%">
                    <v-card-title primary-title>
                       <v-spacer></v-spacer>
                       <v-btn color="primary" small outlined @click="dialog=true">
                           <v-icon small>fa-plus</v-icon>                           
                       </v-btn>
                    </v-card-title> 
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            height="100%"
                            class="elevation-0 "
                            :search="search"
                        >
                            <template v-slot:item.location="{ item }">
                                {{item.location?item.location.name:"No hay una locación asignado"}}
                            </template>
                            <template v-slot:item.devices="{ item }">
                                {{!!item.devices && item.devices.length>0?item.devices.length:'No hay dispositivos asignados'}}
                            </template>
                            <template v-slot:item.area="{ item }">
                                {{item.area}} m<sup>2</sup>
                            </template>
                            <template v-slot:item.options="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        fab text
                                        >
                                        <v-icon small>fa-ellipsis-v</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                         @click="editItem(item)"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Editar</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-pen</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item
                                         @click="editItemMap(item)"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Editar polygono</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-draw-polygon</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>    
                    </v-card-text>
                    
                </v-card>
                
            </v-col>
        </v-row>    
        <v-dialog
            v-model="dialog"
            max-width="400px"
            transition="dialog-transition"
            persistent
        >
            <v-form>
                <v-card class="card-rounded">
                    <v-card-title primary-title>
                        {{dialgTitle}}
                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-4" fab text small @click="closeDialog()">
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="editedItem.name"
                            name="name"
                            label="Nombre de la locación"
                            id="name"
                            required                                
                            rounded
                            dense
                            filled

                        ></v-text-field>
                        <!-- <v-text-field
                            v-model="editedItem.area"
                            name="area"
                            label="Área (m^2)"
                            id="area"
                            required                                
                            rounded
                            dense
                            filled
                            type="number"
                        ></v-text-field> -->
                        <!-- <v-text-field
                            v-model="editedItem.cultivation"
                            name="cultivation"
                            label="Cultivo"
                            id="cultivation"
                            required                                
                            rounded
                            dense
                            filled
                        ></v-text-field> -->
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded @click="save()">
                            <span class="px-5">Guardar</span>
                            
                        </v-btn>
                        <v-btn color="grey" rounded outlined @click="closeDialog()">
                            <span class="px-5">Cancelar</span>
                            
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog
            v-model="dialogMap"
            scrollable
            persistent
            max-width="800px"
            transition="dialog-transition"
        >
            <Map :location="editedItem" v-model="dialogMap"/>
        </v-dialog>
    </div>
</template>
<script>
import Location from '../../services/Locations'
import Map from '../../components/LocationPolygonMap'

export default {
    props:[],
    components:{Map},
    data:()=>({
        dialog:false,
        dialogMap:false, 
        breadCrumsItems:[
            {
                text: 'Inicio',
                disabled: false,
                href: '#/dashboard',
            },
            {
                text: 'Dispositivos',
                disabled: true
            }
        ],
        
        items:[

        ],
        search:"",
        editedItem:{
            name:"",
            area:0,
            cultivation:"",
            polygon:{}
        },
        defaultItem:{
            name:"",
            area:0,
            cultivation:"",
            polygon:{}
        },
        editedIndex:-1
    }),
    methods:{
        async loadDesigns(){
            this.items=await Location.get({includes:["status","devices","polygon"]});
        
        },
        async save(){
            try {
                if(!!this.editedItem.code){
                    let res=await Location.update(this.editedItem.code,this.editedItem);
                    this.items[this.editedIndex]=Object.assign(this.items[this.editedIndex],res);
                }else{
                    let res=await Location.save(this.editedItem);
                    this.items.push(res);
                }
                this.closeDialog();
            } catch (error) {
                console.log(error);
            }
            
        },
        editItem(item){
            this.selectItem(item);
            this.dialog=true;
        },
        selectItem(item){
            this.editedIndex=this.items.indexOf(item);
            this.editedItem=Object.assign({},item);
        },
        editItemMap(item){
            this.selectItem(item);
            this.dialogMap=true;
        },
        closeDialog(){
            this.editedItem=Object.assign({},this.defaultItem);
            this.editedIndex=-1;
            this.dialog=false;
        }
    },
    created(){
        this.loadDesigns();
    },
    computed:{
        dialgTitle(){
            return this.editedIndex==-1?"Agregar un locación":"Editar locación"
        },
        headers(){
            if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
                return [
                    { text: '', value: 'options'},
                    { text: 'Nombre', value: 'name',align: 'center'},
                    { text: 'Estado', value: 'status.text' },
                    { text: 'Area', value: 'area' },
                    // { text: 'Cultivo', value: 'cultivation'},
                    { text: 'Dispositivos', value: 'devices'},
                    
                ]
            }else{
                return [
                    
                    { text: 'Nombre', value: 'name',align: 'center'},
                    { text: 'Estado', value: 'status.text' },
                    { text: 'Area', value: 'area' },
                    // { text: 'Cultivo', value: 'cultivation'},
                    { text: 'Dispositivos', value: 'devices'},
                    { text: 'Opciones', value: 'options'},
                ]
            }
        }
    }

}
</script>