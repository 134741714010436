import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'md',
    },
    theme:{
        themes:{
          dark: {
            primary: "#171E3A",
            secondary: "#00B0F0",
            accent: "#303256",
            error: "#E71C26",
            warning: "#ff9800",
            info: "#00afd7",            
            success: "#8BCA3C"
          },
          light: {
            primary: "#171E3A",
            secondary: "#00B0F0",
            accent: "#303256",
            error: "#E71C26",
            warning: "#ff9800",
            info: "#00afd7",            
            success: "#8BCA3C"
          }
        }
      }
  })