<template>
    <div style="height:100%;padding-top:67px" class="pa-5">
        <Loading :active="loading2"/>
        <v-row>
            <v-col cols="12" lg="8">
                <v-breadcrumbs divider="/" :items="breadCrumsItems"> </v-breadcrumbs>
                <v-card>
                    <v-card-title primary-title>
                        Clientes
                        <v-spacer></v-spacer>
                        <v-btn large color="primary" @click="dialog=true">
                            <v-icon>fa-plus</v-icon>                            
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="4" lg="6" xl="4" v-for="(item,i) in clients" :key="i">
                                <v-card
                                    class=""
                                >    
                                                                
                                    <v-img
                                    height="150"
                                    src="@/assets/img/ptar.jpg"
                                    >
                                        <div style="background:#00C7B150;height:100%;width:100%">

                                        </div>
                                    </v-img>
                                    <v-card-title>
                                        {{item.customer.name}}
                                        <v-spacer></v-spacer>
                                        <v-menu offset-y rounded="lg" left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="secondary "
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"                                  
                                                    elevation="0"
                                                    fab
                                                    x-small
                                                >
                                                <v-icon x-small>fa-ellipsis-v</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list
                                                
                                            >
                                                <v-list-item
                                                    @click="editItem(item.customer,i);"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon x-small > fa-edit </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title > Editar </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="deleteItem(item.customer,i);"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon x-small > fa-trash </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title > Eliminar cliente </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="setAddUser(item.customer._id)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon x-small > fa-user </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title > Agregar usuario  </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="listLocationType(item.customer._id)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon small > house </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title > Tipos de locación </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="listDeviceType(item.customer._id)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon small > usb </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title > Tipos de dispositivos </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card-title>
                                    <v-card-text>                                    
                                    <div>
                                        {{item.customer.description}}
                                    </div>
                                    </v-card-text>
                                    <v-divider class="mx-4"></v-divider>
                                    <v-card-title>
                                        <v-chip
                                        class="ma-2"
                                        color="pink"
                                        label
                                        outlined
                                        >
                                            
                                            {{item.role}}
                                        </v-chip>                                        
                                    </v-card-title>
                                    <v-card-text>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="secondary" block :to="`/mylist/${item.customer._id}`">Ir a mis locaciones</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4" v-if="!$vuetify.breakpoint.mobile">
                <v-card>
                    <v-card-title primary-title>
                        <v-spacer></v-spacer>
                        
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-avatar
                                    :size="100"
                                    color="teal lighten-5"
                                >
                                    <v-icon large>fa-user</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6">
                                    {{user.first_name}}
                                    {{user.last_name}}
                                </div>
                                <div class="caption">
                                    <v-icon small>fa-envelope</v-icon>
                                    {{user.email}}
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <AddEditClient v-model="dialog" :client="editedItem" @add-item="addItem($event)" @update-item="updateItem($event)"/>
        <v-dialog
            v-model="clientDialog"
            scrollable  
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-form v-on:submit.prevent="save()">
                <v-card>
                    <v-card-title primary-title class="primary white--text" style="height:80px">
                        Agregar usuario a planta
                        <v-spacer></v-spacer>
                        <v-btn color="white" small icon @click="closeAddMemberDialog()">
                            <v-icon small> fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <Loading :active="loading"/>
                        <v-container grid-list-|xs>
                            <v-row>
                                <v-col cols="12">
                                    Dirección de correo electrónico
                                    <v-text-field
                                        name="email"
                                        v-model="userMail"
                                        filled
                                        rounded                                    
                                        dense
                                        prepend-inner-icon="search"
                                        @change="findUser()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" v-if="member._id">
                                    <!-- <v-divider></v-divider> -->
                                    <v-card rounded="lg">
                                        <v-card-text>
                                            <v-list three-line >
                                                <v-list-item
                                                >
                                                    <v-list-item-avatar size="80" color="grey lighten-2">
                                                        <v-img v-if="member.avatar"> {{member.avatar}} </v-img>
                                                        <v-icon v-else x-large>fa-user</v-icon>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content>
                                                        <v-list-item-title style="text-transform:capitalize;"> {{member.fullName}} </v-list-item-title>
                                                        <v-list-item-subtitle >{{member.email}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>                                            
                                        </v-card-text>
                                    </v-card>                                
                                </v-col>
                                <v-col>
                                    Permisos
                                    <v-select
                                        :items="['Administrador','Visor']"
                                        v-model="role"
                                        dense
                                        filled
                                        rounded
                                    ></v-select>
                                </v-col>
                            </v-row>             
                        </v-container>
                    </v-card-text>
                    <v-card-title primary-title>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="mx-2" :disabled="!member._id" type="submit">Aceptar</v-btn>
                        <v-btn color="secondary" outlined @click="closeAddMemberDialog()">Cancelar</v-btn>
                    </v-card-title>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog
            v-model="locationDialog"
            
            persistent :overlay="false"
            max-width="1000px"
            transition="dialog-transition"
        >
            <LocationType :customer="customerId" @close="locationDialog=false"></LocationType>
        </v-dialog>
        <v-dialog
            v-model="deviceTypeDialog"
            
            persistent :overlay="false"
            max-width="700px"
            transition="dialog-transition"
        >
            <DeviceType :customer="customerId" @close="deviceTypeDialog=false"></DeviceType>
        </v-dialog>
    </div>
</template>
<script>
import Clients from '@/services/Clients'
import Users from '@/services/UserService'
import Loading from '@/components/Loading'
import LocationType from './components/LocationType'
import DeviceType from './components/DeviceType'
import AddEditClient from './AddEditClient'
export default {
    data:()=>({
        loading:false,
        loading2:false,
        clientDialog:false,
        dialog:false,
        clients:[],
        user:{},
        member:{},
        editedItem:{

        },
        editedIndex:-1,
        userMail:"",
        client:"",
        role:"",
        locationDialog:false,
        deviceTypeDialog:false,
        customerId:"",

    }),
    components:{AddEditClient,Loading,LocationType,DeviceType},
    methods:{
        listDeviceType(e){
            this.customerId=e;
            this.deviceTypeDialog=true;
        },
        listLocationType(e){
            this.customerId=e;
            this.locationDialog=true;
        },
        closeAddMemberDialog(){
            this.clientDialog=false;
            this.member={};
            this.userMail=""
        },

        async findUser(){
            try {
                let res=await Users.findByEmail(this.userMail);
                this.member=res;
            } catch (error) {
                this.member={};
                console.log(error);
            }
        },
        async loadClients(){
            this.loading2=true;
            try {
                const res=await Clients.getMyClients();
                this.clients=res;
            } catch (error) {
                console.error(error);
            }finally{
                this.loading2=false;
            }
        },
        async save(){
            this.loading=true;
            try {
                let res=await Clients.addUserToClient(this.client,{user:this.member._id,role:this.role});
                this.closeAddMemberDialog();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        },
        editItem(item,index){
            this.editedIndex=index
            this.editedItem=item;
            this.dialog=true;
        },
        deleteItem(item,index){
            let self=this;
            this.$swal.fire({
                title: '¿Estás seguro de eliminar este registro?',
                showDenyButton: true,
                icon:"question",
                customClass:{
                    popup:"card-rounded-alert"
                },
                // showCancelButton: true,
                confirmButtonColor: `#F50057`,
                denyButtonColor: `#888888`,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
            }).then(async(result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {                    
                    self.loading2=true;
                    try {
                        await Clients.drop(item._id);
                        self.clients.splice(index,1);
                    } catch (error) {
                        console.log(error);
                    }finally{
                        self.loading2=false;
                    }
                } 
            })
        },
        setAddUser(client){
            this.clientDialog=true;
            this.client=client;

        },
        updateItem(event){
            this.clients[this.editedIndex]=Object.assign(this.clients[this.editedIndex],{client:event});
            this.closeDialog();
        },
        addItem(item){
            this.clients.push(item);
            this.closeDialog();
        },
        closeDialog(){
            this.dialog=false;
        }
    },
    mounted(){
        this.user=this.$session.get("user");
        this.loadClients();
    },
    computed:{
        breadCrumsItems(){
      return [
      {
          text: "Mapa",
          disabled: false,
          href: "/",
        }, 
        {
          text: "Locaciones",
          disabled: true,
        },
      ]
    }
    }
}
</script>