import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"users";
class User {
	static async get(headers) {		
		try {
			var { data } = await Connection.get(URL,headers);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async getAll() {		
		let url=URL+"/allUser"
		try {
			var { data } = await Connection.post(url);
			return data
		} catch ( error ) {
			return error
		}
	}
	static save(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"
			try {
				const res = await Connection.post(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static autoregister(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/autoregister"
			try {
				const res = await Connection.post(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static deleteUser(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/deleteUser"
			try {
				const res = await Connection.post(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static updateUser(data, id) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await Connection.put(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await Connection.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static findByEmail(email) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+email+"/find/byemail"
			try {
				const res = await Connection.get(url);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static async update ( id,userData ) {
		var url = (URL+"/"+id)
		try {
			var { data } = await Connection.put(url,userData);
			return data
		} catch ( error ) {
			return error
		}
    }
    static async settingsUpdate ( args ) {
		var url = ( URL + 'me/devices/' + args.device+"/settings")
		try {
			var { data } = await Connection.put(url,args.settings);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async tagUpdate ( args ) {
		var url = ( URL + 'me/devices/' + args.device)
		try {
			var { data } = await Connection.put(url,args);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async resetPassRequest( email ) {
		var url = ( URL + '/request_reset_password/'+email)
		try {
			var { data } = await Connection.post(url);
			return data
		} catch ( error ) {
			throw error;
		}
	}
	static async resetPass( token,data) {
		var url = ( URL + '/resetpassword/'+token)
		try {
			var { data } = await Connection.put(url,data);
			return data
		} catch ( error ) {
			return error
		}
	}
}

export default User;