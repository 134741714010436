import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"locationlayout";
class User {
	static async list() {
		var { data } = await Connection.get(URL);
		return data
	}
	static async  save(value) {
		const {data} = await Connection.post(URL,value);
		return data;
	}
	static async find(id,headers={}) {
		var url = (URL+"/"+id)
		const {data} = await Connection.get(url);
		return data;
    }
	static async findByLocation(id,headers={}) {
		var url = (URL+"/bylocation/"+id)
		const {data} = await Connection.get(url);
		return data;
    }
	static async update ( id,userData ) {
		var url = (URL+"/"+id)
		var { data } = await Connection.put(url,userData);
		return data
    }
	static async remove (id) {
		var url = (URL+"/"+id)
		var { data } = await Connection.delete(url);
			return data
    }
}

export default User;