import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rounded:false,
    user:{}
  },
  mutations: {
    setRounded(state, val) {
      state.rounded =val
    },
    setUser(state, val){
      state.user =val
    }
  },
  actions: {
  },
  modules: {
  }
})
