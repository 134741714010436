import { render, staticRenderFns } from "./MobileDeviceInfo.vue?vue&type=template&id=99135dfa&"
import script from "./MobileDeviceInfo.vue?vue&type=script&lang=js&"
export * from "./MobileDeviceInfo.vue?vue&type=script&lang=js&"
import style0 from "./MobileDeviceInfo.vue?vue&type=style&index=0&id=99135dfa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports