<template>
    <v-card class=""  :width="width"  rounded="lg">
        <v-card-text >
            <v-row>
                <!-- <v-col cols="4" >

                    <v-icon color="primary">fa-tachometer-alt</v-icon>
                </v-col> -->
                <v-col cols="12" class="py-0">
                    <div style="font-size:12px" class="text-truncate secondary--text text-center text-caption">
                        {{sensor.type.text}}
                    </div>
                    <div class="text-caption font-weight-black black--text primary--text text-center text-truncate">
                        <div style="font-size:10px" class="text-truncate secondary--text text-center">
                            {{data.values[sensor.pos]?data.values[sensor.pos].value_type.text:''}}:
                        </div>
                        {{!!data.values[sensor.pos]?parseFloat(data.values[sensor.pos].value).toFixed(3):''}} 
                        {{!!data.values[sensor.pos]?data.values[sensor.pos].value_type.unit:''}}
                    </div>
                    
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import Sensors from '@/services/SensorsService'
export default {
    props:["sensor","width"],

    data:()=>({
        editDialog:false,
        data:{
            
            values:[]
        },
        editedItem:{

        }
    }),
    watch:{
        sensor(){
           this.loadData(); 
        }
    },
    methods:{
        probando(){
            
        },
        selectEditItem(item){
            this.editDialog=true;
            this.editedItem=Object.assign({},item);
            
        },
        async updateItem(){
            try {
                let res=await Sensors.update(this.editedItem._id,this.editedItem);
                this.$emit('sensor-update',res);
                this.closeDialog();
            } catch (error) {
                console.log(error);
            }
        },
        closeDialog(){
            this.editDialog=false;
            this.editedItem={}
        },
        async loadData(){
            try {
                let res=await Sensors.getLastData(this.sensor._id);
                this.data=res;
            } catch (error) {
                return ;
            }
        },
        selectSensor(){
            if(this.sensor.type._id==7)return;
            this.addSelected();
            this.$emit('sensor-update',{...this.sensor,pos:this.sensor.pos});           
        },
        addSelected(){
            this.sensor.pos=this.sensor.pos<this.data.values.length-1?this.sensor.pos+1:0
        },
        showColor(e){
            if (typeof e === 'string') return e
            return e.hex;
        },
    },
    mounted(){
        this.loadData();
    }

}
</script>