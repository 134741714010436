<template>
    <div style="height:100%;padding-top:67px" class="pa-5">
        <v-row>
            <v-col cols="12" md="4">
                <v-breadcrumbs divider="/" :items="breadCrumsItems">
                        
                    </v-breadcrumbs>
            </v-col >
            <v-col cols="12" md="8">
                <v-text-field
                        name="search"
                        label="Buscar"
                        id="search"
                        v-model="search"
                        required                                
                        rounded
                        dense
                        filled
                        prepend-inner-icon="search"
                        color="primary"
                    >
                    </v-text-field>
            </v-col>
        </v-row>
        <v-row style="height:80%">
            <v-col>
                <v-card class="card-rounded" height="100%">
                    <v-card-title primary-title>
                       <v-spacer></v-spacer>
                       <v-btn color="primary" small @click="setDeviceDialog=true" >
                           <v-icon small>fa-plus</v-icon>                           
                       </v-btn>
                    </v-card-title> 
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            height="100%"
                            class="elevation-0 "
                            :search="search"
                        >
                            <template v-slot:item.location="{ item }">
                                {{item.location?item.location.name:"No hay una locación asignado"}}
                            </template>
                            <template v-slot:item.sensors="{ item }">
                                {{item.sensors.length}}
                            </template>
                            <template v-slot:item.status.text="{ item }">
                                <v-chip color="primary" dark>
                                    {{item.status.text}}
                                </v-chip>
                            </template>
                            <template v-slot:item.options="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        fab text
                                        >
                                        <v-icon small>fa-ellipsis-v</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                         @click="editDevice(item)"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Editar</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-pen</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item
                                         :to="`/myfarm/location/device/${item._id}`"
                                         v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Dashbard</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-chart-bar</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item
                                         @click="addDeviceLocation(item)"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Editar ubicación</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-map-marker-alt</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item
                                         :to="`/devices/${item.device_id}/sensors`"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Sensores</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-project-diagram</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item> 
                                        

                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>    
                    </v-card-text>
                    
                </v-card>
                
            </v-col>
        </v-row>    
        <v-dialog
            v-model="mapDialog"
            scrollable 
            persistent
            max-width="700px"
            transition="dialog-transition"
            class="rounded-xl"
        >
            <LocationMapEdit :device="editedItem" @close-map-dialog="closeEdited()" @update-device="updateDeviceData($event)"/>
        </v-dialog>
        <v-dialog
            v-model="dialogDeviceEdit"
            scrollable
            persistent
            max-width="400px"
            transition="dialog-transition"
            class="rounded-lg"
        >
            <v-form v-on:submit.prevent="save()">
                <v-card class="card-rounded">
                    <v-card-title primary-title>
                        Editar dispositivo
                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-4" fab text @click="closeEdited()">
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" >
                                <v-text-field
                                    v-model="editedItem.name"
                                    name="name"
                                    label="Nombre del dispositivo"
                                    id="id"
                                    required                                
                                    rounded
                                    dense
                                    filled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" >
                                <v-text-field
                                    v-model="editedItem.name"
                                    name="name"
                                    label="Nombre del dispositivo"
                                    id="id"
                                    required                                
                                    rounded
                                    dense
                                    filled
                                ></v-text-field>
                            </v-col>                  
                            
                        </v-row>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded type="submit">
                            <span class="px-5">Guardar</span>
                        </v-btn>
                        <v-btn color="grey darken-2" rounded outlined @click="closeEdited()">
                            <span class="px-5">Cancelar</span>    
                        </v-btn>
                    </v-card-actions>
                </v-card>        
            </v-form>
            
        </v-dialog>
        <v-dialog
            v-model="setDeviceDialog"
            scrollable
            persistent :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-form>
                <v-card>
                    <v-card-title primary-title class="primary white--text"> 
                            Agregar dispositivo
                        <v-spacer></v-spacer>
                        <v-btn color="white" icon>
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="deviceId"
                                    name="deviceCode"
                                    label="Código del dispositivo"
                                    hint="Ingresa el código del dispositivo"
                                    
                                    required                                
                                    rounded
                                    dense
                                    filled
                                    
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="addDeviceToLocation()">Agregar</v-btn>
                        <v-btn color="error" outlined @click="closeAddDeviceDialog()">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>
<script>
import Devices from '../../services/DevicesService'
import Locations from '../../services/Locations'
import LocationMapEdit from '../../components/LocationMapEdit'


export default {
    props:["location"],
    components:{LocationMapEdit},
    data:()=>({
        setDeviceDialog:false,
        mapDialog:false, 
        dialogDeviceEdit:false,
        deviceId:"",
        breadCrumsItems:[
            {
                text: 'Inicio',
                disabled: false,
                href: '#/dashboard',
            },
            {
                text: 'Dispositivos',
                disabled: true
            }
        ],
        items:[

        ],
        search:"",
        editedItem:{
            name:"",
            location:{

            },
            status_id:0,
            
        },
        defaultItem:{
            name:"",
            location:{

            },
            status_id:0
        },
        editedIndex:-1
        
    }),
    methods:{
        async loadDevices(){
            try {
                let res=await Devices.get(this.location);                 
                this.items=res;
            } catch (error) {
                console.log(error);
            }
            
           
        },
        addDeviceLocation(item){
            this.editedItem=item; 
            this.editedIndex=this.items.indexOf(item);
            this.mapDialog=true;
        },
        closeEdited(){
            this.editedItem=Object.assign({},this.defaultItem);
            this.editedIndex=-1;
            this.mapDialog=false
            this.dialogDeviceEdit=false
        },
        updateDeviceData(device){
            this.items[this.editedIndex]=Object.assign(this.items[this.editedIndex],device);
            this.closeEdited();
        },
        async save(){
            let res=await Devices.update(this.editedItem.device_id,this.editedItem);
            this.updateDeviceData(res)
            
        },
        editDevice(item){
            this.editedItem=Object.assign({},item); 
            this.editedIndex=this.items.indexOf(item);
            this.dialogDeviceEdit=true;
        },
        async addDeviceToLocation(){
            try {
                let device=await Locations.addDevice(this.location,{device:this.deviceId});
            } catch (error) {
                console.log(error);
            }
        },
        closeAddDeviceDialog(){
            this.setDeviceDialog=false;
        }
    },
    created(){
        this.loadDevices();
    },
    computed:{
        headers(){
            if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
                return [
                    { text: '', value: 'options'},
                    {
                        text: 'Id dispositivo',
                        align: 'center',                
                        value: '_id'
                    },
                // { text: 'Medición', value: 'mesure' },
                    { text: 'Nombre', value: 'name' },
                    { text: 'Estado', value: 'status.text' },
                    { text: 'Locación', value: 'location'}
                    
                ]
            }else{
                return [
                    {
                        text: 'Id dispositivo',
                        align: 'center',                
                        value: '_id'
                    },
                // { text: 'Medición', value: 'mesure' },
                    { text: 'Nombre', value: 'name' },
                    { text: 'Estado', value: 'status' },
                    { text: 'Locación', value: 'location'},
                    { text: 'Opciones', value: 'options'},
                ]
            }
        }
    }

}
</script>