<template>
    <div v-if="!!device" :class="`cloud-style2 card-device-info ${!!active?'move-left-card':'move-right-card'}`">
        <div style="width:100%;height:150px;">
            <v-card elevation="0" color="transparent"  >
                <v-card-title primary-title>
                    <div class="primary--text text-center" style="font-size:18px;width:100%">Información del dispositivo</div>
                    
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <div class="text-center primary--text">
                                {{!!device?device.device_id:''}}
                            </div>
                            <div class="text-center secondary--text">
                                <small>Dispositivo</small>                                
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="text-center text-truncate primary--text">
                                {{!!device?device.name:''}}
                            </div>
                            <div class="text-center secondary--text">
                                <small>Nombre</small>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-card-text>
            </v-card>
        </div>
        <v-divider></v-divider>
        <div style="width:100%;height:150px">
            <v-card elevation="0" color="transparent" >
                <v-card-title primary-title>
                    <div class="primary--text text-center" style="font-size:18px;width:100%">Locación asociado</div>                    
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <div class="text-center primary--text">
                                {{!!device.location?device.location.name:''}}
                            </div>
                            <div class="text-center secondary--text">
                                <small>Locación</small>                                
                            </div>
                        </v-col>
                        <!-- <v-col cols="6">
                            <div class="text-center text-truncate primary--text">
                               {{!!device.location?device.location.cultivation:''}}
                            </div>
                            <div class="text-center secondary--text">
                                <small>Cultivo</small>
                            </div>
                        </v-col> -->
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        
    </div>
</template>
<script>
export default {
    props:["active","device"],
    components:{},
    data:()=>({

    })
}
</script>
<style >
    .card-device-info{
        -webkit-transition: all .4s ease .2s; 
        -moz-transition: all .4s ease 2s; 
        -o-transition: all .4s ease .2s; 
        -ms-transition: all .4s ease .2s; 
        transition: all .4s ease .2s;
        position:absolute;
        top:80px;
        background: #fff;
        left:0;
        margin-left:-130px;
        width:300px;
        height: 60%;
        max-height:90%;
        overflow:hidden;
        border-radius: 20px 20px 20px 20px;
        -moz-border-radius: 20px 20px 20px 20px;
        -webkit-border-radius: 20px 20px 20px 20px;
        background: -moz-linear-gradient(top, #ffffff 0%, #ecfff7 74%, #ecfff7 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff), color-stop(74%, #ecfff7), color-stop(100%, #ecfff7));
        background: -webkit-linear-gradient(top, #ffffff 0%, #ecfff7 74%, #ecfff7 100%);
        background: -o-linear-gradient(top, #ffffff 0%, #ecfff7 74%, #ecfff7 100%);
        background: -ms-linear-gradient(top, #ffffff 0%, #ecfff7 74%, #ecfff7 100%);
        background: linear-gradient(to bottom,#ffffff 0%, #ecfff7 74%, #ecfff7 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ecfff7', GradientType=0 );
    }
    .move-left-card{
        transform: scale(1,1) translate(150px, 0);
    }
    .move-right-card{
        transform:scale(0, 0) translate(0, 0);
    }
    
</style>