import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"devices";
class User {
	static async get(location,populate) {		
		let url=URL+"/byfield/"+location
		try {
			var { data } = await Connection.get(url,{populate});
			return data
		} catch ( error ) {
			return error
		}
	}
	static async getAll(filter,populate) {		
		let url=URL
		try {
			var { data } = await Connection.get(url,{filter,populate});
			return data
		} catch ( error ) {
			return error
		}
	}
	static save(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL
			try {
				const res = await Connection.post(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await Connection.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static async update ( id,userData ) {
		var url = (URL+"/"+id)
		try {
			var { data } = await Connection.put(url,userData);
			return data
		} catch ( error ) {
			return error
		}
    }
    static async settingsUpdate ( args ) {
		var url = ( URL + 'me/devices/' + args.device+"/settings")
		try {
			var { data } = await Connection.put(url,args.settings);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async tagUpdate ( args ) {
		var url = ( URL + 'me/devices/' + args.device)
		try {
			var { data } = await Connection.put(url,args);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async resetPassRequest( args ) {
		var url = ( URL + 'resetpass')
		try {
			var { data } = await Connection.post(url,args);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async resetPass( args ) {
		var url = ( URL + 'me/credentials')
		try {
			var { data } = await Connection.post(url,args);
			return data
		} catch ( error ) {
			return error
		}
	}
}

export default User;