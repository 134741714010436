<template>
  <v-card style="min-height:480px;max-height: 480px;height: 100%;">
    <v-card-title primary-title>
      Mediciones <v-spacer></v-spacer> 
    </v-card-title>
    <v-card-text style="max-height: 400px; overflow-y: auto">
      <v-data-table
        :headers="headers"
        :items="items" 
        loading-text="Cargando... Espere por favor."
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.used="{ item }">
          {{ selectMeasurement(item.used)  }} 
        </template>
        <template v-slot:item.type.text="{ item }">
          <span class="text-uppercase">
            {{ item.type.text }}
          </span>
        </template>
      </v-data-table>
      <Loading :active="loading"></Loading>
    </v-card-text>
  </v-card>
</template>
  <script>
import Clients from "@/services/Clients";
import Loading from "@/components/Loading";
export default {
  props: {
    customer: { type: String },
    dateRange: { type: Array },
  },
  components: {Loading},
  data: () => ({
    loading: false,
    items: [],
    headers: [
      { text: "Ubicación", value: "type.text", align: "center" },
      { text: "Consumo", value: "used", align: "center" },
    ],
  }),
  watch: {
    dateRange:{
      deep:true,
      handler(v){
        this.loadData()
      }
    }
  },
  methods: {
    selectMeasurement(value) {
      return value > 10000
        ? new Intl.NumberFormat("es-MX", {
            currency: "MXN",
            maximumFractionDigits: 2,
          }).format(value / 1000) + " m3"
        : value + " Lts";
    },
    async loadData() {
      this.loading = true; 
      try {
        const res = await Clients.getGroupedData(this.customer,{start:new Date(this.dateRange[0]),end:new Date(this.dateRange[1])});  
        this.items = res;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {},
};
</script>
  