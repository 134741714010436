import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginIndex from '../views/Authorization/Index.vue'
import Auth from '../views/Authorization/Auth.vue'

import base64 from 'base-64';
import moment from 'moment';
import Register from '../views/Authorization/Register.vue'
import ClientsIndex from '../views/Clients/Index.vue'
import DashboardClient from '../views/Clients/DashboardClient.vue'
import Dashboard1 from '../views/Dashboard/Dashboard1.vue'
import DeviceTableList from '../views/Devices/DeviceTableList.vue'
import DevicesCardList from '../views/Devices/DevicesCardList.vue'
import DevicesMap from '../views/Devices/DevicesMap.vue'
import LocationTableList from '../views/Locations/LocationTableList.vue'
import SensorsTableList from '../views/Devices/SensorsTableList.vue'
import Aplications from '../views/Integrations/Aplications.vue'
import SendEmailAuthorization from '../views/Authorization/SendEmailAuthorization.vue'
import VerifyToken from '../views/Authorization/VerifyToken.vue'
import ChangePassword from '../views/Authorization/ChangePassword.vue'

Vue.use(VueRouter)
const session = Vue.prototype.$session;
  const routes = [
  // {
  //   path: '/payment',
  //   name: 'paynet',
  //   meta:{paynet:true},
  //   component: PaynetCard,
  //   children:[
  //     {
  //       path:'/payment/:paymentReference',
  //       name:'paynetCard',
  //       props:true,
  //       component:CardPaymentForm
  //     },
  //     {
  //       path:'/payment/:paymentReference/complete',
  //       name:'paynetCard',
  //       props:true,
  //       component:Complete
  //     }
      
  //   ]
  // },
  {
    path: '/login',
    name: 'Login',
    component: LoginIndex,
    children:[
      {
        path:'/login/auth',
        name:'Auth',
        component:Auth
      },
      
      {
        path:'/register',
        name:'Register',
        component:Register
      },
      {
        path:'/register/:token',
        name:'Register',
        props:true,
        component:() => import(/* webpackChunkName: "about" */ '@/views/Authorization/ActivateAccount.vue')
      },
      {
        path:'/sendCode',
        name:'SendCode',
        component:SendEmailAuthorization
      },
      {
        path:'/verifyToken',
        name:'VerifyToken',
        component:VerifyToken
      },
      {
        path:'/changePassword/:token',
        props:true,
        name:'ChangePassword',
        component:ChangePassword
      },
      
    ]
  },
  {
    path: '/',
    name: 'Home',
    meta:{auth:true},
    component: Home,
    children: [
      {
        path:'/users',
        name:"UserTableList",
        component:() => import(/* webpackChunkName: "about" */ '@/views/Users/UserTableList')
      },
      {
        path:'/allClients',
        name:"ClientsTableList",
        component:() => import(/* webpackChunkName: "about" */ '@/views/Clients/ClientsTableList')
      },
      {
        path:'/',
        name:"Dashboard1",
        component:Dashboard1
      },
      {
        path:'/Clients',
        name:"ClientsIndex",
        component:ClientsIndex
      }, 
      {
        path:'mylist/:customer',
        name:"DashboardClient",
        component:DashboardClient,
        props:true
      },
      
      {
        path:'mylist/location/:location',
        name:"DevicesCardList",
        component:DevicesCardList,
        props:true
      },
      {
        path:'mylist/location/:location/:device',
        name:"DevicesCardList",
        component:DevicesCardList,
        props:true
      }, 
      {
        path:'mylist/location/device/:device',
        name:"LocationDevice",
        component:Dashboard1,
        props:true
      },   
      {
        path:'dashboard/:deviceId',
        name:"DashboardDevice",
        component:Dashboard1,
        props:true
      },
      {
        path:'devices/list',
        name:"DevicesList",
        component:DeviceTableList
      },
      {
        path:'devices/:device/sensors',
        name:"SensorsTableList",
        component:SensorsTableList,
        props:true
      },
      {
        path:'devices/map/:deviceId',
        name:"DevicesMapById",
        props:true,
        component:DevicesMap
      },
      {
        path:'devices/map',
        name:"DevicesMap",
        props:true,
        component:DevicesMap
      },
      {
        path:'location/list',
        name:"LocationTableList",
        props:true,
        component:LocationTableList
      },
      {
        path:'developers/aplications',
        name:"DeveloperApp",
        props:true,
        component:Aplications
      },
      {
        path:'developers/api/devices',
        name:"DeveloperApiDevices",
        props:true,
        component:() => import(/* webpackChunkName: "about" */ '@/views/Integrations/DevicesDocs.vue')
      },
      {
        path:'developers/api/data',
        name:"DeveloperApiData",
        props:true,
        component:() => import(/* webpackChunkName: "about" */ '@/views/Integrations/DataDocs.vue')
      },
      
      {
        path:'devices/relaymodules/:device',
        name:"RelayModulesByDevice",
        props:true,
        component:() => import(/* webpackChunkName: "about" */ '@/views/RelaysModules/RelayModulesByDevice.vue')
      },
      {
        path:'sensor/data/:sensor',
        name:"UpdateMultiply",
        props:true,
        component:() => import(/* webpackChunkName: "about" */ '@/views/Sensors/components/UpdateMultiply.vue')
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // session.remove("rieggoToken");
  // localStorage.removeItem("rieggoToken");
	if(!session.exists("session") && localStorage.getItem("session")){
		let token=localStorage.getItem("session");
		let userData=localStorage.getItem("user");
		session.set("session",token);
		session.set("user",JSON.parse(userData));
    let verifyToken = session.get("session");
	}

  if(to.name === 'UserTableList' || to.name === 'ClientsTableList'){
    let verifyAdmin = session.get('user').role;
    if(!verifyAdmin){
      next('/')
    }
  }
	let token=session.exists("session") ;
  let auth=to.matched.some(record=> record.meta.auth);
  let paynet=to.matched.some(record=> record.meta.paynet);
  let verifyToken = session.get("session");

  if(verifyToken){
    let splitToken = verifyToken.split(".");
    const decodeToken = base64.decode(splitToken[1]);
    const dataSession = JSON.parse(decodeToken);
    if(moment().isAfter(dataSession.exp * 1000)){
      token = null;
      // this.$session.remove("session");
      localStorage.removeItem("session");
      window.location.href="#/login/auth";
    }
  }
  if(to.fullPath=="/paynet/"+to.params.paymentReference){
    next();
  } else{
    if (auth && !token) {
      next('/login/auth')
    }else if(!auth && token){
      next('/')
    }else  next()
  }
  
 
})
export default router
