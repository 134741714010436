import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"users/auth";
class LoginService {
	//Get Posts
	static login(data) {
		return new Promise(async (resolve, reject) => {
			try {
				const { rememberme } = data;
				const res = await Connection.post(URL,data);
				if(rememberme){
					let {user,token}=res.data;
					localStorage.setItem("session",token);
					localStorage.setItem("user",JSON.stringify(user));
				}
				resolve(res.data);
			} catch (error) {
				// console.log(error);
				reject(error);
			}
		})
	}
}

export default LoginService;