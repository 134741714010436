import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI + "clients";
class User {
  static async get(headers) {
    try {
      var { data } = await Connection.get(URL, headers);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async getMyClients(headers) {
    let url = URL + "/myClients";
    const res = await Connection.get(url, headers);
    return res.data;
  }
  static async getAllClients() {
    let url = URL + "/getAllClients";
    try {
      var { data } = await Connection.post(url);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async getMyClientsExpandData(headers) {
    let url = URL + "/all/locations";
    try {
      var { data } = await Connection.get(url, headers);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async addUserToClient(customer, data) {
    let url = URL + `/${customer}/setuser`;
    try {
      var { data } = await Connection.post(url, data);
      return data;
    } catch (error) {
      return error;
    }
  }
  static save(data) {
    return new Promise(async (resolve, reject) => {
      let url = URL;
      try {
        const res = await Connection.post(url, data);
        resolve(res.data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  }
  static find(id, headers = {}) {
    return new Promise(async (resolve, reject) => {
      let url = URL + "/" + id;
      try {
        const res = await Connection.get(url, headers);
        resolve(res.data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  }
  static async getGroupedData(id,date, headers = {}) { 
    var url = URL + "/groupedsensor/" + id+`/${date.start}/${date.end}`;
    var { data } = await Connection.get(url, headers);
    return data;
  }
  static async getGroupLocations(id,date, headers = {}) {
    var url = URL + "/grouplocations/" + id+`/${date.start}/${date.end}`;
    var { data } = await Connection.get(url, headers);
    return data;
  }
  static async update(id, userData) {
    var url = URL + "/" + id;
    try {
      var { data } = await Connection.put(url, userData);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async drop(id) {
    var url = URL + "/" + id;
    try {
      var { data } = await Connection.delete(url);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async deleteClient(id) {
    var url = URL + "/deleteClient/" + id;
    try {
      var { data } = await Connection.delete(url);
      return data;
    } catch (error) {
      return error;
    }
  }
}

export default User;
