<template>
    <div style="height:100%">
        <div style="height:100%;width:100%;position:absolute">
            <img src="@/assets/images/background-01.jpg" alt="" >
        </div>
        <div style="height:100%;width:100%;position:absolute;background:#171E3Add">               
        </div>
        <v-row align="center" justify="center" style="height:100%">
            <v-col cols="11" sm="8" md="6" lg="5">
                <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="submit()">
                    <v-card class="card-rounded pa-10" flat>
                        <v-card-title primary-title >
                            <div style="width:100%;" class="ma-10 text-center text-h5">
                                Actualizar contraseña
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field
                                label="Contraseña"
                                v-model="password"
                                :rules="passRules"
                                type="password"
                                required                                
                                rounded
                                dense
                                filled
                                prepend-inner-icon="lock"
                                color="primary"
                            ></v-text-field>
                            <v-text-field
                                label="Repetir Contraseña"
                                v-model="confirmPassword"
                                :rules="rePassRules"
                                type="password"
                                required                                
                                rounded
                                dense
                                filled
                                prepend-inner-icon="lock"
                                color="primary"
                            ></v-text-field>
                           
                            
                        </v-card-text>
                        <v-card-actions >
                            
                            <div class=" text-center" style="width:100%">
                                <v-btn
                                rounded
                                large
                                block
                                type="submit"
                                color="primary"
                                >
                                    <span class="ma-5">
                                        Aceptar
                                    </span>
                                    
                                </v-btn>
                            </div>
                            
                        </v-card-actions>

                    </v-card>                    
                    
                    
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import User from "../../services/UserService";
import RegisterTokens from "../../services/RegisterTokens";
export default {
    props:{
        token:{
            type:String,

        }
    },
    data: () => ({
        isLogin:false,
        error:"",
        valid: true,
        password: "",
        confirmPassword: "",

        passRules: [v => !!v || "El campo es obligatorio"],
    }),
    
    computed:{
        rePassRules(){
            let self=this;
            return [
                v => !!v || "El campo es obligatorio",
                v=> self.password==self.confirmPassword || 'Las contraseñas no coinciden'
            ]
        }
    },
    methods: {
        async loadToken(){
            try {                
                let res=await RegisterTokens.find(this.token);
                if(!res)this.$router.push('/');
            } catch (error) {
                this.$router.push('/');
            }
        },
        async submit() {
            if(!this.$refs.form.validate())return;
            try {
                await User.resetPass(this.token,{password:this.password});
                this.$router.push('/');
            } catch (error) {
                console.log(error);
            }    
        },
        clear() {
            this.$refs.form.reset();
        }
    },
    created(){
        this.loadToken();
    }
};
</script>