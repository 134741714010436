<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="primary "
          fab
          x-small
          v-bind="attrs"
          v-on="on"
          elevation="0"
        >
          <v-icon x-small>
            fa-filter
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Filtro de datos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="filter(0)" :disabled="selected == 0">
          <v-list-item-icon>
            <v-icon small>fa-calendar-day</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Hoy
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="filter(7)" :disabled="selected == 7">
          <v-list-item-icon>
            <v-icon small>fa-calendar-week</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Ultimos 7 Dias
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="filter(30)" :disabled="selected == 30">
          <v-list-item-icon>
            <v-icon small>fa-calendar-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Ultimos 30 Dias
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="selectRange()">
          <v-list-item-icon>
            <v-icon small>fa-calendar-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Filtrar por rango de fecha
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="650px"
      transition="dialog-transition"
    >
      <v-form>
        <v-card rounded="lg">
          <v-container grid-list-xs class="pa-0 ma-0">
            <v-row class="pa-0 ma-0 primary" align="center" justify="center">
              <v-col cols="12" sm="6" md="6" class="pa-0 ma-0">
                <div class="primary" style="height:100%">
                  <v-container grid-list-xs>
                    <v-row>
                      <v-col class="text-center">
                        <div class="text-center text-overline white--text">
                          {{
                            !!dateRange[0]
                              ? $moment(dateRange[0]).format("dddd")
                              : ""
                          }}
                        </div>
                        <div class="text-center text-h2 white--text">
                          {{
                            !!dateRange[0]
                              ? $moment(dateRange[0]).format("DD")
                              : ""
                          }}
                        </div>
                        <div class="text-center text-overline white--text">
                          {{
                            !!dateRange[0]
                              ? $moment(dateRange[0]).format("MMMM")
                              : ""
                          }}
                        </div>
                        <div class="text-center text-overline white--text">
                          {{
                            !!dateRange[0]
                              ? $moment(dateRange[0]).format("YYYY")
                              : ""
                          }}
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          $moment(dateRange[1]).diff(
                            $moment(dateRange[0]),
                            'days'
                          ) >= 1
                        "
                      >
                        <div class="text-center text-overline white--text">
                          {{
                            !!dateRange[1]
                              ? $moment(dateRange[1]).format("dddd")
                              : ""
                          }}
                        </div>
                        <div class="text-center text-h2 white--text">
                          {{
                            !!dateRange[1]
                              ? $moment(dateRange[1]).format("DD")
                              : ""
                          }}
                        </div>
                        <div class="text-center text-overline white--text">
                          {{
                            !!dateRange[1]
                              ? $moment(dateRange[0]).format("MMMM")
                              : ""
                          }}
                        </div>
                        <div class="text-center text-overline white--text">
                          {{
                            !!dateRange[1]
                              ? $moment(dateRange[1]).format("YYYY")
                              : ""
                          }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div class="white--text text-center">
                          Hora inicio
                        </div>
                        <v-text-field
                          dark
                          name="name"
                          type="time"
                          v-model="startTime"
                          id="id"
                          rounded
                          outlined
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <div class="white--text text-center">
                          Hora fin
                        </div>

                        <v-text-field
                          dark
                          name="name"
                          type="time"
                          v-model="endTime"
                          id="id"
                          rounded
                          outlined
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="pa-0 ma-0 text-center">
                <div class="white">
                  <v-container grid-list-xs>
                    <v-row>
                      <v-col>
                        <v-date-picker
                          v-model="dateRange"
                          range
                          no-title
                          locale="es-419"
                          @change="checkDates($event)"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn color="primary" @click="updateDate()">
                        Aceptar
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined @click="dialog = false">
                        Cancelar
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
export default {
    props:["value"],
    data:()=>({
        endTime:"23:59",
        startTime:"00:00",
        dialog:false,
        dateRange:[],
        selected:0
    }),
    watch:{
        value(v){
            // this.dateRange=this.value;
            this.$router.replace({query:{dstart:v[0],dend:v[1],s:this.selected}});
        },
        // dateRange(v){
            
        // }
    },
    methods:{
        checkDates(e){
            if(e.length>1 && this.$moment(e[1]).isBefore(this.$moment(e[0]))){
                this.dateRange=e.reverse();
            }else if(e.length==1){
                this.dateRange[e[0],e[0]]
            }
        },
        filter(days){
            this.dateRange=[this.$moment().subtract(days,"d").format("YYYY-MM-DD ")+"00:00",this.$moment().format("YYYY-MM-DD ")+"23:59"]
            this.selected=days;
            this.startTime="00:00"
            this.endTime="23:59"
            this.$emit("input",this.dateRange);
        },
        selectRange(){
            this.dialog=true;
        },
        updateDate(){

            this.dialog=false;
            // console.log(this.$moment(this.dateRange[0]).format('YYYY-MM-DD').diff(this.$moment(this.dateRange[1]).format('YYYY-MM-DD'), 'days')+1)
            // console.log(this.$moment(this.dateRange[0]).diff(this.$moment(this.dateRange[1]), 'days'))
            // this.selected = await this.$moment(this.dateRange[0]).diff(this.$moment(this.dateRange[1]), 'days')-3
            this.selected = -1;
            this.$emit('input', [this.dateRange[0]+" "+this.startTime,this.dateRange[1]+" "+this.endTime])

            // this.dateRange = [this.$moment(this.dateRange[0]).subtract(1, 'day').format('YYYY-MM-DD'), this.$moment(this.dateRange[1]).format('YYYY-MM-DD')+" "+this.endTime]
            // console.log(this.dateRange)
        }
    },
    created(){
        this.dateRange=this.value;
        this.selected=this.$route.query.s;
    },
    computed: {
      dateRangeText () {
        return this.dateRange.join(' ~ ')
      },
    },
}
</script>
<style>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
</style>
