import axios from "axios";
const URL = process.env.VUE_APP_SERVER_URI+"login";

class Axios {
	//Get Posts
	static async get(url,headers={}) {
		headers["authorization"]=this.getToken();
		 const res = await axios.get(url,{headers});	
		 return res;
    }
    static async post(url,data={},headers={}) {
		headers=this.getHeaders(headers);
        headers["authorization"]=this.getToken();
		const res = await axios.post(url,data,{headers});
		return res;
    }
    static put(url,data={},headers={}) {
		headers=this.getHeaders(headers);
        headers["authorization"]=this.getToken();
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.put(url,data,{headers});
				resolve(res);
			} catch (error) {
				//console.log(error);
				reject(error);
			}
		})
    }
    static delete(url,data={},headers={}) {
        headers["authorization"]=this.getToken();
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.delete(url,{headers});
				resolve(res);
			} catch (error) {
				//console.log(error);
				reject(error);
			}
		})
    }
    static getToken(){
        var sesionKey =window.sessionStorage.getItem("sessionKey")
        if(sesionKey && JSON.parse(window.sessionStorage.getItem(sesionKey)))
            return JSON.parse(window.sessionStorage.getItem(sesionKey)).session;
        else if(localStorage.getItem("session"))
            return localStorage.getItem("session");
        return null;
	}
	static getHeaders(headers){
		let filter=headers.filter?JSON.stringify(headers.filter):"{}";
		let populate=headers.populate?headers.populate:false;
		let request={
			filter,
			populate
		}
		if(!!headers.attributes)request["attributes"]=JSON.stringify(headers.attributes);
		if(!!headers.group)request["group"]=headers.group;
		if(!!headers.limit)request["limit"]=headers.limit;
		return request;
	}
}

export default Axios;