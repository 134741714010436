<template>
  <div style="height: 100%; padding-top: 67px" class="pa-5">
    <Loading :active="loading" />
    <v-row>
      <v-col cols="12"  >
        <v-card rounded="lg">
          <v-card-title primary-title>
            Mis locaciones
            <v-spacer></v-spacer> 
            <v-btn large color="primary" @click="openLocationDialog()">
              <v-icon>fa-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4" v-for="(item, i) in locations" :key="i">
                <v-card class="" rounded="lg" height="350px">
                  <v-img
                    height="140"
                    :src="item.image ? item.image : '/crops/ptar.jpg'"
                  >
                    <div
                      style="background: #00c7b150; height: 100%; width: 100%"
                    >
                      <div style="position: absolute; top: 5px; right: 10px">
                        <v-btn small fab @click="editItem(item, i)"
                          ><v-icon color="secondary" small
                            >fa-pen</v-icon
                          ></v-btn
                        >
                      </div>
                      <div style="position: absolute; top: 50px; right: 10px">
                        <v-btn small fab @click="deleteItem(item, i)"
                          ><v-icon color="error" small>fa-trash</v-icon></v-btn
                        >
                      </div>
                      <div style="position: absolute; top: 95px; right: 10px">
                        <v-btn small fab :to="`/mylist/location/${item._id}`"
                          ><v-icon color="purple" small
                            >fa-chart-line</v-icon
                          ></v-btn
                        >
                      </div>
                    </div>
                  </v-img>

                  <v-card-title>{{ item.name }}</v-card-title>

                  <v-card-text>
                    <!-- <v-row
                                        align="center"
                                        class="mx-0"
                                    >
                                        <v-rating
                                        :value="4.5"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="14"
                                        ></v-rating>

                                        <div class="grey--text ml-4">
                                        4.5 (413)
                                        </div>
                                    </v-row> -->

                    <!-- <div class="my-4 subtitle-1">
                                        $ • Italian, Cafe
                                    </div> -->

                    <div
                      v-if="!item.description || item.description.length < 55"
                    >
                      {{ item.description }}
                    </div>
                    <div v-else>
                      {{ item.description.substring(0, 55) + "..." }}
                      <v-btn
                        color="primary"
                        x-small
                        @click="modalDescription = !modalDescription"
                        text
                        >Ver mas</v-btn
                      >
                      <v-dialog
                        v-model="modalDescription"
                        scrollable
                        persistent
                        :overlay="false"
                        max-width="500px"
                        transition="dialog-transition"
                      >
                        <v-card rounded="lg">
                          <v-card-title
                            primary-title
                            class="primary white--text"
                          >
                            Descripción
                            <v-spacer></v-spacer>
                            <v-btn
                              color="white"
                              small
                              icon
                              @click="modalDescription = !modalDescription"
                            >
                              <v-icon small>fa-times</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <v-textarea
                                  rounded
                                  dense
                                  :value="item.description"
                                  filled
                                  auto-grow
                                  disabled
                                  color="primary"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </div>
                    <div class="text-caption">
                      <v-row>
                        <v-col v-if="item.devices.length > 0">
                          <Carroucel :items="item.devices" />
                          <!-- <div class="text-caption">Cultivo: {{ item.crop }}</div> -->
                        </v-col>
                        <v-col cols="12" v-else-if="item.devices.length < 1">
                          <div class="text-caption error--text text--lighten-2">
                            Sin Dispositivos
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <!-- <v-card-title>Tonight's availability</v-card-title> -->

                  <v-card-text>
                    <!-- <v-chip-group
                                        v-model="selection"
                                        active-class="deep-purple accent-4 white--text"
                                        column
                                    >
                                        <v-chip>5:30PM</v-chip>

                                        <v-chip>7:30PM</v-chip>

                                        <v-chip>8:00PM</v-chip>

                                        <v-chip>9:00PM</v-chip>
                                    </v-chip-group> -->
                  </v-card-text>
                  <!-- 
                                    <v-card-actions>
                                    <v-btn
                                        color="deep-purple lighten-2"
                                        text
                                        @click="reserve"
                                    >
                                        Reserve
                                    </v-btn>
                                    </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" v-else>
        <div class="row">
          <div class="col-12">
            <LocationChart
              :customer="customer"
              @tableOption="table = $event"
            ></LocationChart>
          </div>
          <div class="col-12">
            <LocationDataInfo
              :customer="customer"
              @tableOption="table = $event"
            ></LocationDataInfo>
          </div>
        </div>
      </v-col> -->
    </v-row>
    <EditLocation
      :active="addLocationDialog"
      :location="editedItem"
      :customer="customer"
      @close="closeDialog"
      @update="updateLocation"
      @save="saveLocation"
    ></EditLocation>
  </div>
</template>
<script>
import Clients from "@/services/Clients";
import Loading from "@/components/Loading";
import Locations from "@/services/Locations";
import Carroucel from "@/components/Carroucel";
import LocationChart from "./LocationChart.vue";
import LocationDataInfo from "./LocationDataInfo.vue";
import EditLocation from "@/views/Locations/components/EditLocation";
export default {
  props: ["customer"],
  components: {
    Loading,
    Carroucel,
    EditLocation,
    LocationDataInfo,
    LocationChart,
  },
  data: () => ({
    carroucelColor: "#69F0AE",
    modalDescription: false,
    model: 0,
    loading: false,
    loading2: false,
    addLocationDialog: false,
    locations: [],
    user: {}, 
    customerData: {},
    editedItem: { name: "" },
    editLocationName: [],
    editedIndex: -1,
    cropList: [
      { crop: "Aguacate", img: "/crops/rtp_fondo_teams_rieggo_aguacate.png" },
      { crop: "Fresa", img: "/crops/rtp_fondo_teams_rieggo_berries1.png" },
      { crop: "Brocoli", img: "/crops/rtp_fondo_teams_rieggo_brocoli.png" },
      { crop: "Limon", img: "/crops/rtp_fondo_teams_rieggo_citricos.png" },
      { crop: "Pimiento", img: "/crops/rtp_fondo_teams_rieggo_morron.png" },
      { crop: "Jitomate", img: "/crops/tomatos.jpg" },
    ],
    locationTypes: [],
  }),
  methods: {
    changeCrop(e) {
      let image = this.cropList.find((item) => item.crop == e);
      this.editedItem.image = image.img;
    },
    async findClient() {
      try {
        let result = await Clients.find(this.customer);
        this.customerData = result;
        console.log(result);
      } catch (error) {
        console.log("find client", error);
      }
    },
    async loadLocations() {
      this.loading = true;
      try {
        let locations = await Locations.getMyLocations(this.customer);
        this.locations = locations;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    editItem(item, i) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = i;
      this.addLocationDialog = true;
    },
    async deleteItem(item, i) {
      let self = this;
      this.$swal
        .fire({
          title: "¿Estás seguro de eliminar este registro?",
          showDenyButton: true,
          icon: "question",
          customClass: {
            popup: "card-rounded-alert",
          },
          // showCancelButton: true,
          confirmButtonColor: `#F50057`,
          denyButtonColor: `#888888`,
          confirmButtonText: `Eliminar`,
          denyButtonText: `Cancelar`,
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            self.loading = true;
            try {
              await Locations.drop(item._id, { status: "Eliminado" });
              self.locations.splice(i, 1);
            } catch (error) {
              console.log(error);
            } finally {
              self.loading = false;
            }
          }
        });
    },
    async openLocationDialog() {
      this.addLocationDialog = true;
    },
    closeDialog() {
      this.editedItem = { name: "" };
      this.addLocationDialog = false;
      this.editedIndex = -1;
    },
    saveLocation(e) {
      this.locations.push(e);
      this.closeDialog();
    },
    updateLocation(e) {
      this.locations[this.editedIndex] = Object.assign(
        this.locations[this.editedIndex],
        e
      );
      this.closeDialog();
    },
  },
  created() {
    this.user = this.$session.get("user");
    this.findClient();
    this.loadLocations();
  },
  computed: {},
};
</script>
