<template>
    <div>
        <v-row>
            <!-- <v-col cols="6" class="text-center">
                <v-avatar
                    size="50"
                    color="teal lighten-4"
                >
                    <v-icon color="teal" v-if="sensor.type._id==7">fa-faucet</v-icon>
                </v-avatar>
            </v-col> -->
            <v-col cols="6" class="py-0 text-center">
                <div style="font-size:20px" class="text-truncate secondary--text ">
                    {{sensor.type.text}}
                </div>
                <div class="text-subtitle-1 font-weight-black black--text primary--text text-truncate" v-if="!!data && !!data.values && data.values.length>0">
                    <div style="font-size:10px" class="text-truncate secondary--text">

                        {{!!data && data.values[0]?data.values[0].value_type.text:''}}:
                    </div>
                    {{!!data && !!data.values[0]?data.values[0].value:''}} 
                    {{!!data && !!data.values[0]?data.values[0].value_type.unit:''}}
                </div>
                <div v-else>
                    sin datos
                </div>
                
            </v-col>
            <v-col cols="6" class="py-0 text-center">
                <div style="font-size:20px" class="text-truncate secondary--text ">
                    {{sensor.type.text}}
                </div>
                <div class="text-subtitle-1 font-weight-black black--text primary--text text-truncate" v-if="!!data && !!data.values && data.values.length>0">
                    <div style="font-size:10px" class="text-truncate secondary--text">
                        {{!!data && data.values[1]?data.values[1].value_type.text:''}}:
                    </div>
                    {{new Intl.NumberFormat('en-US').format(getData())}} 
                    {{!!data && !!data.values[1]?data.values[1].value_type.unit:''}}
                </div>
                <div v-else>
                    sin datos
                </div>
                
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Sensors from '@/services/SensorsService'
export default {
    props:["sensor","width","device"],

    data:()=>({
        editDialog:false,
        data:{
            
            values:[]
        },
        data2:[],
        editedItem:{

        }
    }),
    watch:{
        sensor(){
           this.loadData(); 
           this.getLastTwoData();
        }
    },
    methods:{
        adjustReset(v){
            if(v<0) return this.adjustReset(v+16777215);
            else return v;
        },
        getData(){
            if(this.data2.length>1){
                return !!this.data2[1] && !!this.data2[0]?(this.adjustReset(this.data2[1].values.find(item=>item.value_type._id==6).value-this.data2[0].values.find(item=>item.value_type._id==6).value)):0;
            }else{
                return 0;
            }
        },
        selectEditItem(item){
            this.editDialog=true;
            this.editedItem=Object.assign({},item);
            
        },
        async updateItem(){
            try {
                let res=await Sensors.update(this.editedItem._id,this.editedItem);
                this.$emit('sensor-update',res);
                this.closeDialog();
            } catch (error) {
                console.log(error);
            }
        },
        closeDialog(){
            this.editDialog=false;
            this.editedItem={}
        },
        async loadData(){
            try {
                let res=await Sensors.getLastData(this.sensor._id);
                this.data=res;
            } catch (error) {
                return ;
            }
        },
        async getLastTwoData(){
            try {
                let res=await Sensors.getLastTwoData(this.sensor._id);
                this.data2=res;
            } catch (error) {
                return ;
            }
        },
        selectSensor(){
            if(this.sensor.type._id==7)return;
            this.addSelected();
            this.$emit('sensor-update',{...this.sensor,pos:this.sensor.pos});           
        },
        addSelected(){
            this.sensor.pos=this.sensor.pos<this.data.values.length-1?this.sensor.pos+1:0
        },
        showColor(e){
            if (typeof e === 'string') return e
            return e.hex;
        },
    },
    mounted(){
        this.getLastTwoData();
        this.loadData();
    }

}
</script>