<template>
  <v-dialog
    :value="active"
    scrollable
    persistent
    :overlay="false"
    max-width="700px"
    transition="dialog-transition"
  >
    <v-form>
      <v-card rounded="lg">
        <v-card-title primary-title class="primary white--text">
          {{ titleDialog }}
          <v-spacer></v-spacer>
          <v-btn color="white" small icon @click="closeDialog()">
            <v-icon small>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-row>
              <v-col cols="12">
                <v-select
                  color="primary--text"
                  v-model="editedItem.locationType"
                  :items="locationTypes"
                  item-text="text"
                  filled
                  label="Tipo de locación"
                  return-object
                  @change="editLocationName[1] = $event.format"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Determinante"
                  v-model="editLocationName[0]"
                  dense
                  name="fieldDet"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Formato"
                  v-model="editLocationName[1]"
                  dense
                  name="fieldFormat"
                  color="primary"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Nombre de la locación"
                  v-model="editLocationName[2]"
                  dense
                  name="fieldName"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Descripción"
                  v-model="editedItem.description"
                  dense
                  filled
                  name="descriptionName"
                  color="primary"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <Loading :active="loading2" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()"> Guardar </v-btn>
          <v-btn color="error" outlined @click="closeDialog()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import Loading from "@/components/Loading";
import Locations from "@/services/Locations";
import LocationType from "@/services/LocationType";
export default {
  props: ["location", "active", "customer"],
  components: {
    Loading,
  },
  data: () => ({
    loading2: false,
    editedItem: {
      name:""
    },
    editLocationName: [],
    editedIndex: -1,
    locationTypes: [],
  }),
  methods: {
    async loadLocationType() {
      if(!this.customer)return;
      try {
        let res = await LocationType.findByCustomer(this.customer);
        this.locationTypes = res;
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      this.loading2 = true;
      try {
        this.editedItem.name =
          this.editLocationName[0] +
          "-" +
          this.editLocationName[1] +
          "-" +
          this.editLocationName[2];
        if (this.editedItem._id) {
          const location = await Locations.update(
            this.editedItem._id,
            this.editedItem
          );
          this.$emit("update", this.editedItem);
        } else {
          const location = await Locations.save({
            ...this.editedItem,
            customer: this.customer,
          });
          this.$emit("save", location);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading2 = false;
      }
    },
    editItem(item, i) {
      this.editedItem = Object.assign({}, item);
      this.editLocationName = this.editedItem.name.split("-");
      if (this.editLocationName.length == 1) {
        this.editLocationName[2] = this.editLocationName[0];
        this.editLocationName[0] = "";
        if (this.editedItem.locationType)
          this.editLocationName[1] = this.editedItem.locationType.format;
      }
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  watch: {
    location(v) {
      this.loadLocationType();
      if (v && v._id) this.editItem(v);
    },
  },
  mounted() {
    this.loadLocationType();
    this.editItem(this.location);
  },
  computed: {
    titleDialog() {
      return this.editedIndex == -1 ? "Agregar locación" : "Editar locación";
    },
  },
};
</script>
