<template>
  <div style="height: 100%; padding-top: 67px" class="pa-5">
    <Loading :active="loading" />
    <v-row justify="center" align="center">
      <v-col cols="12" sm="10" md="8" lg="8">
        <v-card min-height="80" color="grey lighten-5">
          <v-card-text>
            <div class="text-h6" style="text-transform: capitalize;">
              Tus aplicaciones
            </div>
            <div class="text-subtitle-1">
              Cada aplicación cuenta con las credenciales necesarias para poder
              acceder a la API.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="10" md="8" lg="8"> </v-col>
      <v-col cols="12" sm="10" md="8" lg="8">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              height="200px"
              @click="application.dialog = !application.dialog"
            >
              <v-card-text style="height:100%">
                <v-row align="center" justify="center" style="height:100%">
                  <v-col class="text-center" cols="12">
                    <v-avatar size="60" color="primary">
                      <v-icon>fa-plus</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    Nueva aplicación
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="(item, index) in application.list"
            :key="index + 'apps'"
          >
            <v-card height="200px" @click="openToken(item)">
              <v-card-title primary-title>
                <v-spacer></v-spacer>
                <v-menu transition="slide-x-transition" bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" icon v-bind="attrs" v-on="on">
                      <v-icon small>fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item, index)">
                      <v-list-item-icon>
                        <v-icon x-small> fa-pen </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteToken(item._id, index)">
                      <v-list-item-icon>
                        <v-icon x-small> fa-trash </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text class="ma-0">
                <v-row align="center" justify="center" style="height:100%">
                  <v-col cols="12" class="text-center">
                    <div class="text-h5">
                      {{ item.name }}
                    </div>
                    <div class="text-truncate">
                      {{ item.description }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="application.dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-form
        lazy-validation
        @submit.prevent="saveApplication()"
        ref="newAppForm"
      >
        <v-card>
          <v-card-title
            primary-title
            class="primary white--text"
            style="height:100px"
          >
            {{ titleNewAppp }}
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="closeApplicationDialog()">
              <v-icon small>fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-xs>
              <v-text-field
                name="appiName"
                label="Nombre de la aplicación"
                filled
                rounded
                id="id"
                v-model="application.editedItem.name"
              ></v-text-field>
              <v-textarea
                name="appiName"
                label="Descripción"
                filled
                rounded
                id="id"
                v-model="application.editedItem.description"
              ></v-textarea>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">Aceptar</v-btn>
            <v-btn color="secondary" outlined @click="closeApplicationDialog()"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="application.infoDialog"
      scrollable
      persistent
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-form
        lazy-validation
        @submit.prevent="saveApplication()"
        ref="newAppForm"
      >
        <v-card>
          <v-card-title
            primary-title
            class="primary white--text"
            style="height:100px"
          >
            Credenciales
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="closeApplicationDialog()">
              <v-icon small>fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-xs>
              <v-row>
                <v-col>
                  <div class="text-overline">
                    Las credenciales son unicas e identifican una integración
                    dentro de tu cuenta.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title primary-title>
                      Access token
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey"
                        small
                        @click="myFunction()"
                        text
                      >
                        <v-icon small>fa-copy</v-icon>
                      </v-btn>
                      <v-btn
                        color="grey"
                        small
                        @click="
                          application.see =
                            application.see == 'password' ? 'text' : 'password'
                        "
                        text
                      >
                        <v-icon small>{{
                          application.see == "password"
                            ? "fa-eye"
                            : "fa-eye-slash"
                        }}</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                        v-show="false"
                        id="token"
                        type="text"
                        name=""
                        :value="application.editedItem.token"
                      ></v-text-field>
                      <v-text-field
                        :type="application.see"
                        name=""
                        :value="application.editedItem.token"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" outlined @click="closeApplicationDialog()"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import Applications from "@/services/Applications";
export default {
  props: [],
  components: {
    Loading,
  },
  data: () => ({
    loading: false,

    application: {
      see: "password",
      infoDialog: false,
      dialog: false,
      editedItem: {},
      editedIndex: -1,
      list: [],
    },
  }),
  methods: {
    editItem(item, index) {
      this.application.editedItem = Object.assign({}, item);
      this.application.dialog = true;
      this.application.editedIndex = index;
    },
    openToken(e) {
      this.application.infoDialog = true;
      this.application.editedItem = Object.assign({}, e);
    },
    closeApplicationDialog() {
      this.application.infoDialog = false;
      this.application.dialog = false;
      this.application.editedItem = {};
      this.application.editedIndex = -1;
      this.$refs.newAppForm.resetValidation();
    },
    async saveApplication() {
      this.loading = true;
      if (this.application.editedIndex == -1) {
        try {
          let res = await Applications.save(this.application.editedItem);
          this.application.list.push(res);
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      } else {
        try {
          await Applications.update(
            this.application.editedItem._id,
            this.application.editedItem
          );
          this.application.list[this.application.editedIndex] = Object.assign(
            this.application.list[this.application.editedIndex],
            this.application.editedItem
          );
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
      this.closeApplicationDialog();
    },
    async loadApps() {
      this.loading = true;
      try {
        let res = await Applications.get();
        this.application.list = res;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    myFunction() {
      /* Get the text field */
      var copyText = document.getElementById("token");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(
        0,
        this.application.editedItem.token.length - 1
      ); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      this.$swal.fire({
        toast: "bottom",
        position: "bottom",
        icon: "success",
        title: "Copiado",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    deleteToken(id, i) {
      let self = this;
      this.$swal
        .fire({
          title: "¿Estás seguro de eliminar esta integración?",
          showDenyButton: true,
          icon: "question",
          //   customClass: {
          //     popup: "card-rounded-alert",
          //   },
          // showCancelButton: true,
          confirmButtonColor: `#00C7B1`,
          denyButtonColor: `#888888`,
          confirmButtonText: `Eliminar`,
          denyButtonText: `Cancelar`,
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            self.loading = true;
            try {
              await Applications.del(id);
              self.application.list.splice(i, 1);
            } catch (error) {
              console.log(error);
            } finally {
              self.loading = false;
            }
          }
        });
    },
  },
  created() {
    let self=this;
    this.loadApps();
    document.addEventListener("copy", function(e) {
      e.preventDefault();
      e.clipboardData.setData("text/plain", self.application.editedItem.token);
    });
  },
  computed: {
    titleNewAppp() {
      return this.application.editedIndex == -1
        ? "Nueva aplicación"
        : "Editar aplicación";
    },
  },
};
</script>
