import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"data";
class User {
	static async get(headers) {		
		try {
			var { data } = await Connection.get(URL,headers);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async getAlarm(sensor,alarm){
		try {
			var { data } = await Connection.get(URL+'/findbyerrors/'+sensor+'/'+alarm);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async getAlarmByArray(sensor,args){
		try {
			var { data } = await Connection.post(URL+'/findbyerrors/'+sensor,args);
			return data
		} catch ( error ) {
			return error
		}
	}
    
}

export default User;