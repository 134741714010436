<template>
  <v-card class="cloud-style2" :width="width" rounded="lg">
    <v-card-text>
      <div style="position: absolute; top: 2px; right: 2px">
        <v-btn
          :color="`${sensor.selected ? 'secondary' : 'grey'}`"
          dark
          elevation="0"
          icon
          outlined
          x-small
          @click="
            $emit('sensor-update', { ...sensor, selected: !sensor.selected })
          "
        >
          <v-icon x-small>done</v-icon>
        </v-btn>
        <!-- {{sensor.type}} -->
        <v-btn
          color="primary "
          @click="selectSensor()"
          elevation="0"
          icon
          outlined
          x-small
          :disabled="!sensor.selected"
        >
          <v-icon x-small>published_with_changes</v-icon>
        </v-btn>
        <!--<v-btn
                    color="warning "
                    dark                                  
                    elevation="0"
                    icon
                    outlined
                    x-small
                    
                >
                    <v-icon x-small>edit</v-icon>
                </v-btn> -->
        <v-menu bottom origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              elevation="0"
              icon
              outlined
              x-small
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="selectEditItem(sensor)" v-if="$session.get('user').role==='Administrador'">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openEditMultiDialog(sensor)" v-if="$session.get('user').role==='Administrador'">
              <v-list-item-title>Editar multiplicador</v-list-item-title>
            </v-list-item>
            <v-list-item @click="alarmSensor(sensor)">
              <v-list-item-title>Alarmas</v-list-item-title>
            </v-list-item>
            <!-- Habilitar solo en caso de querer corregir el multiplicador  -->
            <!-- <v-list-item :to="`/sensor/data/${sensor._id}`">
              <v-list-item-title>Abrir Sensor </v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
      <v-row style="margin-top: 6px">
        <!-- <v-col cols="4" >

                    <v-icon color="primary">fa-tachometer-alt</v-icon>
                </v-col> -->

        <v-col cols="12" class="py-0">
          <!-- <div style="font-size:12px" class="text-truncate secondary--text text-center" height="100%">
                        {{sensor.name}}
                        <br>
                    </div> -->
          <div
            style="font-size: 12px"
            class="text-truncate secondary--text text-center"
          >
            {{ sensor.name }}
            <br />
          </div>
          <div
            style="font-size: 12px"
            class="text-truncate secondary--text text-center"
          >
            {{ sensor.type.text }} ({{ sensor.number }})
          </div>

          <!-- <div class="text-h6 font-weight-black black--text primary--text text-center" v-if="!!device && (device.device_id=='43E056' || device.device_id=='43D565')">
                        <span style="font-size:10px" class="text-truncate secondary--text text-center">
                            {{!!data.values[sensor.pos]?data.values[sensor.pos].value_type.text:''}}:
                        </span>
                        
                        {{!!data.values[sensor.pos]?data.values[sensor.pos].value*10:''}} 
                        {{!!data.values[sensor.pos]?data.values[sensor.pos].value_type.unit:''}}
                    </div> -->
          <div
            class="text-h6 font-weight-black black--text primary--text text-center"
            v-if="!!data.values[sensor.pos]"
          >
            <v-row class="pa-0 ma-0">
              <v-col
                cols="5"
                style="font-size: 10px"
                class="pa-0 text-truncate secondary--text text-right"
              >
                {{
                  !!data.values[sensor.pos]
                    ? data.values[sensor.pos].value_type.text
                    : ""
                }}:
              </v-col>
              <v-col cols="7" class="pa-0">
                {{
                  !!data.values[sensor.pos]
                    ? parseFloat(data.values[sensor.pos].value).toFixed(
                        data.values[sensor.pos].value === 0 ||
                          data.values[sensor.pos].value_type._id == 6
                          ? 0
                          : 3
                      )
                    : ""
                }}
                {{
                  !!data.values[sensor.pos]
                    ? data.values[sensor.pos].value_type.unit
                    : ""
                }}
              </v-col>
            </v-row>
          </div>
          <div
            class="text-h6 font-weight-black black--text error--text text-center"
            v-else
          >
            Sin datos
          </div>
        </v-col>
        <v-col cols="12" class="py-0" v-if="!!data.values[sensor.pos]">
          <div class="text-caption secondary--text text-center">
            {{
              !!data.values[sensor.pos]
                ? $moment(data.values[sensor.pos].created_at).format(
                    "dddd DD [de] MMM YYYY HH:mm"
                  )
                : ""
            }}
          </div>
        </v-col>
        <v-col cols="12" class="py-0" v-else>
          <div class="text-caption secondary--text text-center">
            <br />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      :style="`position:relative;background:${sensor.color};width:100%;height:3px;`"
      v-if="sensor.selected"
    ></div>
    <ListAlarms :dialog="alarmDialog" :sensor="editedItem._id" @close="alarmDialog=false"></ListAlarms>
    <v-dialog
      v-model="editDialog"
      scrollable
      persistent
      :overlay="false"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title
          primary-title
          class="primary white--text"
          style="height: 60px"
        >
          Editar sensor
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="closeDialog()">
            <v-icon small>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-text-field
              name="sensorName"
              label="Nombre del sensor"
              filled
              dense
              rounded
              v-model="editedItem.name"
            ></v-text-field> 
            <v-divider></v-divider>
            Selecciona un color
            <v-color-picker
              class="ma-2"
              :value="editedItem.color"
              @input="editedItem.color = showColor($event)"
            ></v-color-picker>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateItem()"> Aceptar </v-btn>
          <v-btn color="secondary" outlined @click="closeDialog()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialogMulti"
      scrollable
      persistent
      :overlay="false"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title
          primary-title
          class="primary white--text"
          style="height: 60px"
        >
          Editar multiplicador
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="closeDialog()">
            <v-icon small>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs> 
            <v-text-field
              name="sensorDivide"
              label="Multiplicar por"
              filled
              dense
              rounded
              v-model="editedItem.multiplier"
            ></v-text-field> 
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateItem()"> Aceptar </v-btn>
          <v-btn color="secondary" outlined @click="closeDialog()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Sensors from "@/services/SensorsService";
import ListAlarms from "./ListAlarms";
export default {
  props: ["sensor", "width", "restore", "device"],
    components:{ListAlarms},
  data: () => ({
    editDialog: false,
    editDialogMulti: false,
    alarmDialog: false,
    data: {
      values: [],
    },
    editedItem: {},
  }),
  watch: {
    sensor() {
      this.loadData();
    },
    restore() {
      this.loadData();
    },
  },
  methods: {
    probando() {},
    selectEditItem(item) {
      this.editDialog = true;
      this.editedItem = Object.assign({}, item);
    },
    openEditMultiDialog(item) {
      this.editDialogMulti = true;
      this.editedItem = Object.assign({}, item);
    },
    alarmSensor(item) {
      this.alarmDialog = true;
      this.editedItem = Object.assign({}, item);
    },
    async updateItem() {
      try {
        let res = await Sensors.update(this.editedItem._id, this.editedItem);
        this.$emit("sensor-update", this.editedItem);
        this.closeDialog();
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog() {
      this.editDialogMulti=false;
      this.editDialog = false;
      this.editedItem = {};
    },
    async loadData() { 
      try {
        let res = await Sensors.getLastData(this.sensor._id);
        res.values = res.values.sort(function (a, b) {
          if (a.value_type._idv > b.value_type._id) {
            return 1;
          }
          if (a.value_type._id < b.value_type._id) {
            return -1;
          }
          return 0;
        });
        this.data = res;
      } catch (error) {
        return;
      }
    },
    selectSensor() {
      if (this.sensor.type._id == 7) return;
      this.addSelected();
      this.$emit("sensor-update", { ...this.sensor, pos: this.sensor.pos });
    },
    addSelected() {
      this.sensor.pos =
        this.sensor.pos < this.data.values.length - 1 ? this.sensor.pos + 1 : 0;
    },
    showColor(e) {
      if (typeof e === "string") return e;
      return e.hex;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
