<template>
    <div class="slider-container">
        <v-btn color="#424242" @click="scrollLeft()" fab text v-if="!isMobile()" small>
            <v-icon small>
                fa-chevron-left
            </v-icon>
        </v-btn>
        <div class="slider-card-container" >
            
            <div class="slider-card-container-scroll" ref="scroll" @wheel="scroll($event)" style="position:relative">
                <div style="max-height:0px;display: flex;position:absolute;top:0px;">
                    <span class="slider-item" v-for="(item, index) in sensors" :key="index" >
                        
                        <SensorMiniCard :device="device" :restore="restore" :sensor="item" width="280" @sensor-update="$emit('sensor-update',{sensor:$event,index})"/>
                        
                    </span>
                </div>
                
                
            </div>                        
        </div>
        <v-btn color="#424242" @click="scrollRight()" fab text v-if="!isMobile()" small>
            <v-icon color small>
                fa-chevron-right
            </v-icon>
        </v-btn>
    </div>
    
</template>
<script>

import SensorMiniCard from './SensorMiniCard1.vue';
export default {
    props:["sensors","all","labels","updated","device", "restore"],
    components:{SensorMiniCard},
    data:()=>({
        active:true,
        cardWidth:400,
        
    }),
    methods:{
        scrollRight(){
            let cardSpace=this.cardWidth+10;
            let scroll=this.$refs.scroll;
            let rightDesp=(Math.trunc(scroll.clientWidth/cardSpace)*cardSpace);
            scroll.scrollLeft=rightDesp+scroll.scrollLeft;
            
        },
        scrollLeft(){
            let cardSpace=this.cardWidth+10;
            let scroll=this.$refs.scroll;
            let rightDesp=(Math.trunc(scroll.clientWidth/cardSpace)*cardSpace);
            scroll.scrollLeft=scroll.scrollLeft-rightDesp;
            
        },
        scroll(e){
            let cardSpace=this.cardWidth+10;
            let scroll=this.$refs.scroll;
            let rightDesp=(Math.trunc(scroll.clientWidth/cardSpace)*cardSpace);
            scroll.scrollLeft=e.wheelDeltaY<0?scroll.scrollLeft-rightDesp:scroll.scrollLeft+rightDesp;
            e.preventDefault();
            
        },
        isMobile(){
            return (
                (navigator.userAgent.match(/Android/i)) ||
                (navigator.userAgent.match(/webOS/i)) ||
                (navigator.userAgent.match(/iPhone/i)) ||
                (navigator.userAgent.match(/iPod/i)) ||
                (navigator.userAgent.match(/iPad/i)) ||
                (navigator.userAgent.match(/BlackBerry/i))
            );
        
        },
        updateSensor(sensor,index){
            this.sensors[index]=Object.assign(this.sensors[index],sensor);
            this.$emit('sensors-update',this.sensors)
        },
        getChartData(sensor){
            if(sensor.codeSensor==4){
                switch (sensor.flujo) {
                    case 'acumulado':
                        return{labels:this.labels,datasets:sensor.aggregate}
                        break;
                    case 'sumaAcumulado':
                        return{labels:this.labels,datasets:sensor.sumAggregate}
                        break;
                    case 'instantaneo':
                        return{labels:this.labels,datasets:sensor.flow}
                        break;
                    default:
                        return{labels:this.labels,datasets:[]}
                        break;
                }
            }
            return{labels:this.labels,datasets:sensor.datas}
        }
    },
    created(){

    },
    computed:{
    }

}

</script>
<style >
    .btn-slider{
        display: flex;
        flex-direction: column;
        width:20px
    }
    .slider-container{
        display: flex;
        width: 100%;
        padding: 5px;         
        align-items: center;
        background-color:#00000000;
        border-radius: 20px 20px 0px 0px;
        -moz-border-radius: 20px 20px 0px 0px;
        -webkit-border-radius: 20px 20px 0px 0px;
    }
    .slider-card-container-scroll{
        align-items: center;        
        overflow-x: scroll;
        display: flex;
        height: 300px;
        scroll-behavior: smooth;
        -webkit-transition: all 700ms ease-out;
        -moz-transition:all 700ms ease-out;
        transition: all 700ms ease-out;
    }
    .slider-card-container{
        width: 100%;
        overflow-x: hidden;
        overflow-y:hidden;
        height: 200px;
        background-color: #00000000;
        padding: 10px;
        scrollbar-width: 1px;
        -webkit-transition: all 700ms ease-out;
        -moz-transition:all 700ms ease-out;
        transition: all 700ms ease-out;
        border-radius: 5px 5px 5px 5px;
        -moz-border-radius: 5px 5px 5px 5px;
        -webkit-border-radius: 5px 5px 5px 5px;
        
    }
    .slider-item{        
        border-radius: 16px;
        background-color: #17141d00;
        display: flex;
        flex-direction: column;
        margin-top:5px;
        margin-left:5px;        
        margin-bottom: 10px;
        padding: 0rem;
        -webkit-transition: all 700ms ease-out;
        -moz-transition:all 700ms ease-out;
        transition: all 700ms ease-out;
    }
</style>

