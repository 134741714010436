import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI + "locations";
class User {
  static async getMyLocations(farm) {
    let url = URL + "/byclient/" + farm;
    try {
      var { data } = await Connection.get(url);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async addDevice(location, args) {
    let url = URL + "/" + location + "/adddevice";
    try {
      var { data } = await Connection.post(url, args);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async getGroupedData(id, date, headers = {}) {
    var url =
      URL +
      "/groupedsensor/" +
      id +
      "/" +
      new Date(date[0]) +
      "/" +
      new Date(date[1]);
    var { data } = await Connection.get(url, headers);
    return data;
  }
  static save(data) {
    return new Promise(async (resolve, reject) => {
      let url = URL;
      try {
        const res = await Connection.post(url, data);
        resolve(res.data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  }
  static find(id, headers = {}) {
    return new Promise(async (resolve, reject) => {
      let url = URL + "/" + id;
      try {
        const res = await Connection.get(url, headers);
        resolve(res.data);
      } catch (error) {
        reject(error.response.data);
      }
    });
  }
  static async update(id, userData) {
    var url = URL + "/" + id;
    try {
      var { data } = await Connection.put(url, userData);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async drop(id) {
    var url = URL + "/" + id;
    try {
      var { data } = await Connection.delete(url);
      return data;
    } catch (error) {
      return error;
    }
  }
}

export default User;
