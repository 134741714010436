<template>
    <div class="circle-container" >
        <div class="circle s" :style="active?sensorStyleRotate(index):''" v-for="(item, index) in sensors" :key="index">
            
            <v-btn color="white" x-large fab @click="$emit('onsensor-select',index)">
                <span style="position: absolute; top:25px;font-size:12px; color:#00C7B1;height:40px;width:40px;padding:3px;padding-top:5px" class="text-center"> {{item.datas.length>0?item.datas[item.datas.length-1].values[0].type.unit:''}} </span>
                <span :style="`font-size:25px;color:`+item.color">{{item.datas.length>0?item.datas[item.datas.length-1].values[0].value:'ND'}}</span><strong style="font-size:8px;">{{item.datas.length>0?item.datas[item.datas.length-1].values[0].unit:''}}</strong>                 
            </v-btn>
            
        </div>
    </div>
</template>
<script>
export default {
    props:["active","sensors"],
    components:{},    
    data:()=>({

    }),
    watch:{
        active(){
        }
    },
    methods:{
        sensorStyle(){
            return `
                -webkit-transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg) ;
                -moz-transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg) ;
                -ms-transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg) ;
                -o-transform: scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg);
                transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg); 
            `
        },
        sensorStyleRotate(i){
            const deg=(180+(180/(this.sensors.length-1)*i));
            return `
                -webkit-transform:rotate(${(deg)}deg) translate(12em) rotate(-${(deg)}deg) ;
                -moz-transform:scale(1, 1) rotate(${(deg)}deg) translate(12em) rotate(-${(deg)}deg) ;
                -ms-transform:scale(1, 1) rotate(${(deg)}deg) translate(12em) rotate(-${(deg)}deg) ;
                -o-transform: scale(1, 1) rotate(${(deg)}deg) translate(12em) rotate(-${(deg)}deg);
                transform:scale(1, 1) rotate(${(deg)}deg) translate(12em)  rotate(-${(deg)}deg); 
            `
        }
    }
}
</script>
<style>
    .circle-container {
        position: absolute;
        /* border: dashed 1px; */
        border-radius: 50%;
        width:0px;
        height:0px;
        /* margin: 1.75em auto 0; */
        top:50%;
        left:50%;     
    }
    .circle{
        position: absolute;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        top:50%;
        left:50%;
        margin-left:-30px;
        margin-top:-30px;
        background:#ffffff00;
        -webkit-transition: all .3s ease .3s; 
        -moz-transition: all .3s ease 3s; 
        -o-transition: all .3s ease .3s; 
        -ms-transition: all .3s ease .3s; 
        transition: all .3s ease .3s;
        -webkit-transform:scale(0, 0);
        -moz-transform:scale(0, 0);
        -ms-transform:scale(0, 0);
        -o-transform:scale(0, 0);
        transform:scale(0, 0); 
    }
    .deg0 {
        
        -webkit-transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg) ;
        -moz-transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg) ;
        -ms-transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg) ;
        -o-transform: scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg);
        transform:scale(1, 1) rotate(-90deg) translate(8em) rotate(90deg); 
        
    }
    .deg1 {
        -webkit-transform:scale(1, 1) rotate(50deg) translate(8em) rotate(-25deg);
        -moz-transform:scale(1, 1) rotate(25deg) translate(8em) rotate(-25deg);
        -ms-transform:scale(1, 1) rotate(25deg) translate(8em) rotate(-25deg);
        -o-transform:scale(1, 1) rotate(25deg) translate(8em) rotate(-25deg);
        transform:scale(1, 1) rotate(25deg) translate(8em) rotate(-25deg);
    }
    .deg2 {
        -webkit-transform:scale(1, 1) rotate(60deg) translate(8em) rotate(-60deg);
        -moz-transform:scale(1, 1) rotate(60deg) translate(8em) rotate(-60deg);
        -ms-transform:scale(1, 1) rotate(60deg) translate(8em) rotate(-60deg);
        -o-transform:scale(1, 1) rotate(60deg) translate(8em) rotate(-60deg);
        transform:scale(1, 1) rotate(60deg) translate(8em) rotate(-60deg);
    }

    .s {
        
        background: #5dcce7;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-animation: r5 1s 0s ease-out infinite;
        -moz-animation: r5 1s 0s ease-out infinite;
        animation: r5 1s 0s ease-out infinite;
    }

    @-webkit-keyframes r5 {
    0% {
        box-shadow: 0 0 8px 6px rgba(93,204,231, .5), 0 0 0px 0px transparent, 0 0 0px 0px rgba(93,204,231, .7);
    }
    10% {
        transform:scale(1,1);
        box-shadow: 0 0 8px 6px rgba(93,204,231, .5), 0 0 12px 10px transparent, 0 0 12px 14px rgba(93,204,231, .7);
    }
    100% {
        box-shadow: 0 0 8px 6px rgba(26, 140, 255, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(26, 140, 255, 0);
    }
    }
    @-moz-keyframes r5 {
    0% {
        box-shadow: 0 0 8px 6px rgb(93,204,231), 0 0 0px 0px transparent, 0 0 0px 0px rgb(93,204,231);
    }
    10% {
        box-shadow: 0 0 8px 6px rgb(93,204,231), 0 0 12px 10px transparent, 0 0 12px 14px rgb(93,204,231);
    }
    100% {
        box-shadow: 0 0 8px 6px rgba(26, 140, 255, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(26, 140, 255, 0);
    }
    }
    @keyframes r5 {
    0% {
        box-shadow: 0 0 3px 2px rgb(93,204,231), 0 0 0px 0px transparent, 0 0 0px 0px rgb(93,204,231);
    }
    10% {
        box-shadow: 0 0 3px 2px rgb(93,204,231), 0 0 5px 2px transparent, 0 0 5px 2px rgb(93,204,231);
    }
    100% {
        box-shadow: 0 0 3px 2px rgba(26, 140, 255, 0), 0 0 0px 10px transparent, 0 0 0px 20px rgba(26, 140, 255, 0);
    }
    }
</style>