<template>
  <v-card height="400px" rounded="lg" class="cloud-style2">
    <div style="height: 400px; width: 100%; position: relative; overflow: auto">
      <GmapMap
        :center="
          !!editedItem.latLng
            ? editedItem.latLng
            : { lat: 19.4251213, lng: -99.2032852 }
        "
        :zoom="zoom"
        @zoom_changed="zoom = $event"
        :map-type-id="mapType"
        v-bind:options="mapStyle"
        style="width: 100%; height: 100%"
      >
        <GmapMarker
          :position="
            editedItem.latLng
              ? editedItem.latLng
              : google && new google.maps.LatLng(19.4251213, -99.2032852)
          "
          :icon="{
            ...icon,
            anchor: !!google ? new google.maps.Point(200, 500) : null,
          }"
        />
      </GmapMap>
    </div>
    <div
      style="position: absolute; top: 0; width: 100%"
      class="pa-2 ma-2 primary--text"
    >
      <div class="white pa-2" style="position: absolute; border-radius: 10px">
        <span class="text-caption secondary--text">Locación: </span
        >{{ location.name }} <br />
        <!-- <span class="text-caption secondary--text">Cultivo: </span
        >{{ location.crop }} <br /> -->
        <!-- <span class="text-caption secondary--text">Superficie: </span
        >{{ location.area }} m²<br /> -->
        <span class="text-caption secondary--text">Dispositivos: </span
        >{{ location.devices ? location.devices.length : 0 }} <br />
      </div>

      <div style="position: absolute; right: 20px; top: 5px">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              fab
              x-small
              v-bind="attrs"
              v-on="on"
              elevation="0"
            >
              <v-icon small> fa-angle-down </v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <v-divider></v-divider> -->
            <!-- <v-list-item
                        >
                            
                            <v-list-item-icon>
                                <v-icon small>fa-pen</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Editar información
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
            <v-list-item @click="dialog = true">
              <v-list-item-icon>
                <v-avatar size="30" color="primary">
                  <v-icon small color="white">location_on</v-icon>
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Editar Ubicación </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="fieldEdit()">
              <v-list-item-icon>
                <v-avatar size="30" color="primary">
                  <v-icon small color="white">fa-pen</v-icon>
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Editar Locación </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div style="position: absolute; bottom: 20px; right: 10px">
      <div>
        <v-btn
          x-small
          color="primary"
          elevation="0"
          class="mb-1"
          dark
          fab
          @click="zoomPlus()"
        >
          <v-icon color="" small>fa-plus</v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          x-small
          color="primary"
          elevation="0"
          class="mb-1"
          dark
          fab
          @click="zoom--"
        >
          <v-icon color="" small>fa-minus</v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          x-small
          color="primary"
          elevation="0"
          class="mb-1"
          dark
          fab
          @click="setFullScreen()"
        >
          <v-icon color="" small>fa-compress</v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      scrollable
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <LocationMapEdit
        :location="location"
        @close-map-dialog="dialog = false"
        @update-location="updateLocation($event)"
      />
    </v-dialog>
    <v-dialog
      v-model="fullscreen"
      scrollable
      fullscreen
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div style="heigh: 100%; width: 100%">
        <GmapMap
          :center="editedItem.latLng"
          :zoom="zoom"
          @zoom_changed="zoom = $event"
          :map-type-id="mapType"
          v-bind:options="mapStyle"
          style="width: 100%; height: 100%"
        >
          <GmapMarker
            :position="
              editedItem.latLng
                ? editedItem.latLng
                : google && new google.maps.LatLng(19.4251213, -99.2032852)
            "
            :icon="{
              ...icon,
              anchor: !!google ? new google.maps.Point(200, 500) : null,
            }"
          />
        </GmapMap>
        <div style="position: absolute; bottom: 20px; right: 10px">
          <div>
            <v-btn
              x-small
              color="primary"
              elevation="0"
              class="mb-1"
              dark
              fab
              @click="zoomPlus()"
            >
              <v-icon color="" small>fa-plus</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn
              x-small
              color="primary"
              elevation="0"
              class="mb-1"
              dark
              fab
              @click="zoom--"
            >
              <v-icon color="" small>fa-minus</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn
              x-small
              color="primary"
              elevation="0"
              class="mb-1"
              dark
              fab
              @click="setFullScreen()"
            >
              <v-icon color="" small>fa-compress</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

    <EditLocation
      :active="addLocationDialog"
      :location="editedItem"
      :customer="location.customer"
      @close="closeDialog"
      @update="updateLocation"
    >
    </EditLocation>
  </v-card>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import LocationMapEdit from "../../components/LocationMapEdit";
import Locations from "../../services/Locations";
import EditLocation from "@/views/Locations/components/EditLocation";
// import GmapCluster from "vue2-google-maps/dist/components/cluster";
export default {
  props: ["location"],
  watch: {
    location(v) {
      this.editedItem = Object.assign({}, v);
    },
  },
  components: { LocationMapEdit, EditLocation },
  data: () => ({
    cropList: [
      { crop: "Aguacate", img: "/crops/rtp_fondo_teams_rieggo_aguacate.png" },
      { crop: "Fresa", img: "/crops/rtp_fondo_teams_rieggo_berries1.png" },
      { crop: "Brocoli", img: "/crops/rtp_fondo_teams_rieggo_brocoli.png" },
      { crop: "Limon", img: "/crops/rtp_fondo_teams_rieggo_citricos.png" },
      { crop: "Pimiento", img: "/crops/rtp_fondo_teams_rieggo_morron.png" },
      { crop: "Jitomate", img: "/crops/tomatos.jpg" },
    ],
    addLocationDialog: false,
    fullscreen: false,
    center: {
      lat: 23.3143515,
      lng: -103.3223592,
    },
    zoom: 10,
    polygonOptions: {
      strokeColor: "#00C7B1",
      fillColor: "#00C7B1",
      fillOpacity: 0.8,
      strokeOpacity: 1,
      strokeWeight: 2,
    },
    mapType: "roadmap", //roadmap,satellite,hybrid,terrain
    icon: {
      path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
      fillColor: "#E71C26",
      fillOpacity: 0.9,

      // strokeWeight: 1,
      scale: 0.04,
    },
    // icon:{
    //     path: "M150.94 192h33.73c11.01 0 18.61-10.83 14.86-21.18-4.93-13.58-7.55-27.98-7.55-42.82s2.62-29.24 7.55-42.82C203.29 74.83 195.68 64 184.67 64h-33.73c-7.01 0-13.46 4.49-15.41 11.23C130.64 92.21 128 109.88 128 128c0 18.12 2.64 35.79 7.54 52.76 1.94 6.74 8.39 11.24 15.4 11.24zM89.92 23.34C95.56 12.72 87.97 0 75.96 0H40.63c-6.27 0-12.14 3.59-14.74 9.31C9.4 45.54 0 85.65 0 128c0 24.75 3.12 68.33 26.69 118.86 2.62 5.63 8.42 9.14 14.61 9.14h34.84c12.02 0 19.61-12.74 13.95-23.37-49.78-93.32-16.71-178.15-.17-209.29zM614.06 9.29C611.46 3.58 605.6 0 599.33 0h-35.42c-11.98 0-19.66 12.66-14.02 23.25 18.27 34.29 48.42 119.42.28 209.23-5.72 10.68 1.8 23.52 13.91 23.52h35.23c6.27 0 12.13-3.58 14.73-9.29C630.57 210.48 640 170.36 640 128s-9.42-82.48-25.94-118.71zM489.06 64h-33.73c-11.01 0-18.61 10.83-14.86 21.18 4.93 13.58 7.55 27.98 7.55 42.82s-2.62 29.24-7.55 42.82c-3.76 10.35 3.85 21.18 14.86 21.18h33.73c7.02 0 13.46-4.49 15.41-11.24 4.9-16.97 7.53-34.64 7.53-52.76 0-18.12-2.64-35.79-7.54-52.76-1.94-6.75-8.39-11.24-15.4-11.24zm-116.3 100.12c7.05-10.29 11.2-22.71 11.2-36.12 0-35.35-28.63-64-63.96-64-35.32 0-63.96 28.65-63.96 64 0 13.41 4.15 25.83 11.2 36.12l-130.5 313.41c-3.4 8.15.46 17.52 8.61 20.92l29.51 12.31c8.15 3.4 17.52-.46 20.91-8.61L244.96 384h150.07l49.2 118.15c3.4 8.16 12.76 12.01 20.91 8.61l29.51-12.31c8.15-3.4 12-12.77 8.61-20.92l-130.5-313.41zM271.62 320L320 203.81 368.38 320h-96.76z",
    //     fillColor: '#E71C26',
    //     fillOpacity: .9,

    //     scale: .03
    // },
    dialog: false,
    editedItem: {},
  }),
  methods: {
    updateLocation(e) {
      this.editedItem = Object.assign(this.editedItem, e);
      this.dialog = false;
      this.closeDialog();
    },
    changeCrop(e) {
      let image = this.cropList.find((item) => item.crop == e);
      this.editedItem.image = image.img;
    },
    fieldEdit() {
      this.editedItem = Object.assign(this.location);
      this.addLocationDialog = true;
    },
    closeDialog() {
      // this.editedItem = Object.assign({});
      this.addLocationDialog = false;
    },
    zoomPlus() {
      this.zoom = this.zoom + 1;
    },
    setFullScreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        this.getFullscreen(document.documentElement);
      } else {
        this.exitFullscreen();
      }
    },
    getFullscreen(element) {
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullscreen)
        element.webkitRequestFullscreen();
      else if (element.msRequestFullscreen) element.msRequestFullscreen();
    },
    exitFullscreen() {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    },
  },
  created() {
    this.editedItem = Object.assign({}, this.location);
    addEventListener("fullscreenchange", function (event) {
      if (!event.srcElement.classList[0]) this.fullscreen = false;
    });
  },
  computed: {
    google: gmapApi,
    mapStyle() {
      let opt = {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
      // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
      return this.$vuetify.theme.dark
        ? Object.assign(darkStyle, opt)
        : Object.assign(opt);
    },

    pathsComputed() {
      return this.paths;
    },
  },
};
</script>
