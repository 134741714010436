<template>
  <div>
    <v-overlay :absolute="absolute" :value="active">
      <v-progress-circular
        v-if="progress"
        :color="`${color ? color : 'primary'}`"
        :value="progress"
        :rotate="360"
        :size="dataSize + 50"
        :width="15"
      >
        |{{ progress }}%
      </v-progress-circular>
      <v-progress-circular
        v-else
        :color="`${color ? color : 'primary'}`"
        indeterminate
        :size="dataSize"
        :width="5"
      >
      </v-progress-circular>
      <br />
      {{ progress ? "Enviando" : "" }}
    </v-overlay>
  </div>
</template>
<script>
export default {
  props: {
    active: { type: Boolean, default: false },
    progress: { type: Number },
    color: { type: String, default: "primary" },
    size: { type: Number, default: 50 },
    absolute: { type: Boolean, default: true },

  },
  data: () => ({
    dataSize: 0,
  }),
  created() {
    this.dataSize = this.size;
  },
};
</script>
