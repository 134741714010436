<template>
  <div style="height: 205px">
    <div style="position: absolute; right: 13px; top: 13px">
      <RangeScale
        :options="optObject"
        unit="l/s"
        @update-options="updateRange($event)"
      />
    </div>

    <LineChart :chartData="chartData" :options="options" />
    <Loading :active="loading" />
  </div>
</template>
<script>
import Sensors from "@/services/SensorsService";
import LineChart from "@/components/chartJs/Line2";
import Loading from "@/components/Loading";
import RangeScale from "@/components/RangeScale";
import _ from "lodash";
import moment from "moment";
export default {
  props: ["sensors", "dateRange", "updateData", "restore", "device"],
  components: { LineChart, Loading, RangeScale },
  data: () => ({
    rangeDialog: true,
    loading: false,
    rangeEditedItem: {
      min: 0,
      max: 50,
    },
    chartData: {
      labels: [],
      datasets: [],
    },
    optObject: {
      spanGaps: true,
      maintainAspectRatio: false,
      responsive: true,
      chartArea: {
        background: "#ffffff",
      },
      legend: {
        position: "top",
        display: false,
        labels: {
          // fontColor:"#ffffff"
        },
      },
      title: {
        position: "bottom",
        display: true,
        text: "",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: false,
      },

      scales: {
        xAxes: [
          {
            // type: 'time',
            // time: {
            //     displayFormats: {
            //         "minute": 'MMM DD HH:mm',
            //     },
            // },
            fontColor: "#fff",
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Tiempo",
              // fontColor:"white"
            },
            gridLines: {
              color: "rgba(171,171,171,0.5)",
              lineWidth: 0.1,
              display: false,
            },

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
              callback: function (value, index, values) {
                return moment(value).format("dddd DD [de] MMM ");
              },
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              // labelString: 'Valor',
              fontSize: 16,
              fontStyle: "bold",
              // fontColor:"white"
            },
            ticks: {
              maxTicksLimit: 100,
              // maxRotation: 0,
              // minRotation: 0,
              display: true,
              beginAtZero: true,
              steps: 5, //5
              // stepSize: 0.1,
              // stepValue: 100,
            },
            plotBands: [
              {
                color: "orange", // Color value
                from: 0, // Start of the plot band
                to: 0, // End of the plot band
              },
            ],
            gridLines: {
              color: "rgba(171,171,171,0.5)",
              lineWidth: 0.5,
              display: false,
            },
          },
        ],
      },
    },
  }),
  watch: {
    dateRange() {
      this.loadData();
    },
    sensors(v) {
      this.loadData();
    },
    "sensor.pos": {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    updateData() {
      this.loadData();
    },
    restore() {
      this.loadData();
    },
  },
  methods: {
    updateRange(e) {
      this.optObject = Object.assign({}, e);
    },
    async loadData() {
      this.loading = true;
      try {
        let labels = [];
        let datasets = [];

        for (let index = 0; index < this.sensors.length; index++) {
          const sensor = this.sensors[index];
          if (sensor.selected) {
            let res = await Sensors.getDataByDateRange(
              sensor._id,
              new Date(this.dateRange[0]),
              new Date(this.dateRange[1])
            );
            datasets.push({ ...sensor, data: res });
            this.sensors[index]["data"] = res;
          }
        }
        this.setCharData(datasets);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    setCharData(data, labels) {
      var self = this;

      var obj = Object.assign({
        labels: [],
        datasets: [],
      });
      var dataset = {
        // one line graph
        label: [],
        spanGaps: false,
        fondoColor: "",
        backgroundColor: [
          //    "#f38b4a",
        ],
        // hoverBackgroundColor: [
        //
        // ],

        borderWidth: 2,
        hidden: false,
        fill: true,
        lineTension: 0.5,

        //borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "solid",
        borderDash: [],
        borderDashOffset: 0.4,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        //pointBackgroundColor: "#000000",
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        // pointHoverBackgroundColor: "rgba(236,25,65,1)",
        pointHoverBorderColor: "rgba(75,192,192,1)",
        pointHoverBorderWidth: 0,
        pointRadius: 3,
        pointHitRadius: 0,
        data: [],
      };

      try {
        for (let i = 0; i < data.length; i++) {
          const sensor = data[i];
          let d = [];
          let unit = "";
          let label = "";
          for (let index = 0; index < sensor.data.length; index++) {
            let sensorData = sensor.data[index];
            label = sensorData.values[sensor.pos].value_type.text;
            unit = sensorData.values[sensor.pos].value_type.unit;
            if (i == 0) {
              obj.labels.push(
                this.$moment(sensorData.created_at).format("YYYY-MM-DD HH:mm")
              );
            }
            if (sensor.selected) {
              if (
                sensorData.values[sensor.pos].value_type._id == 3 &&
                index > 1 &&
                sensorData.values[sensor.pos].value < 4.5
              ) {
                sensorData.values[sensor.pos].value = 5.0;
              }
              d.push(sensorData.values[sensor.pos].value);
            }
          }
          obj.datasets.push({
            ...dataset,
            data: d,
            unit,
            label,
            fondoColor: sensor.color,
          });
        }
        this.chartData = obj;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    options() {
      let self = this;
      let callbacks = {
        title: function (context) {
          return (
            moment(context[0].label).format(
              "dddd DD [de] MMM [de] YYYY HH:mm "
            ) + "hrs"
          );
        },
        label: function (context) {
          return (
            self.chartData.datasets[context.datasetIndex].label +
            " " +
            context.value +
            " " +
            self.chartData.datasets[context.datasetIndex].unit
          );
        },
      };
      return Object.assign(this.optObject, {
        tooltips: { ...this.optObject.tooltips, callbacks },
      });
    },
  },
};
</script>
