<template>
    <div style="height:100%;padding-top:67px" class="pa-5">
        <v-row>
            <v-col cols="12" md="4">
                <v-breadcrumbs divider="/" :items="breadCrumsItems">
                        
                    </v-breadcrumbs>
            </v-col >
            <v-col cols="12" md="8">
                <v-text-field
                        name="search"
                        label="Buscar"
                        id="search"
                        v-model="search"
                        required                                
                        rounded
                        dense
                        filled
                        prepend-inner-icon="search"
                        color="primary"
                    >
                    </v-text-field>
            </v-col>
        </v-row>
        <v-row style="height:80%">
            <v-col>
                <v-card class="card-rounded" height="100%">
                    <v-card-title primary-title>
                       <v-spacer></v-spacer>
                       <!-- <v-btn color="primary" small outlined >
                           <v-icon small>fa-plus</v-icon>                           
                       </v-btn> -->
                    </v-card-title> 
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            class="elevation-0 "
                            :search="search"
                        >
                            <template v-slot:item.color="{item}">
                                <div>
                                    <v-chip :color="item.color" dark>{{item.color}}</v-chip>
                                </div>
                            </template>
                            <template v-slot:item.options="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        fab text
                                        >
                                        <v-icon small>fa-ellipsis-v</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                         @click="editDevice(item)"
                                        >

                                            <v-list-item-content>
                                                <v-list-item-title>Editar</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon small>fa-pen</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>                             
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>    
                    </v-card-text>
                    
                </v-card>
                
            </v-col>
        </v-row>    
        <SensorEdit :dialog="dialogSensorEdit" :sensor="editedItem" @on-close="dialogSensorEdit=$event" @update-sensor="updateSensorData($event)"/>
        
    </div>
</template>
<script>
import Sensor from '../../services/SensorsService'
import SensorEdit from '../../components/SensorEdit'


export default {
    props:["device"],
    components:{SensorEdit},
    data:()=>({
        mapDialog:false, 
        dialogSensorEdit:false,
        breadCrumsItems:[
            {
                text: 'Home',
                disabled: false,
                href: '#/dashboard',
            },
            {
                text: 'Dispositivos',
                disabled: false,
                href: '#/devices/list',
            },
            {
                text: 'Sensores',
                disabled: true
            }
        ],
        headers:[
            // {
            //     text: 'Id dispositivo',
            //     align: 'center',                
            //     value: 'device_id'
            // },
        // { text: 'Medición', value: 'mesure' },
            { text: 'Nombre', value: 'name' },
            { text: 'Estado', value: 'status.text' },
            { text: 'Color', value: 'color'},
            { text: 'opciones', value: 'options'},
        ],
        items:[

        ],
        search:"",
        editedItem:{
            name:"",
            location:{

            },
            status_id:0,
            
        },
        defaultItem:{
            name:"",
            location:{

            },
            status_id:0
        },
        editedIndex:-1
        
    }),
    methods:{
        async loadSensors(){
            this.items=await Sensor.get({filter:{device_id:this.device},includes:["status","device"]}); 
           
        },
        addDeviceLocation(item){
            this.editedItem=item; 
            this.editedIndex=this.items.indexOf(item);
            this.mapDialog=true;
        },
        closeEdited(){
            this.editedItem=Object.assign({},this.defaultItem);
            this.editedIndex=-1;
            this.mapDialog=false
            this.dialogSensorEdit=false
        },
        updateSensorData(sensor){
            this.items[this.editedIndex]=Object.assign(this.items[this.editedIndex],sensor);
            this.closeEdited();
        },
        editDevice(item){
            this.editedItem=Object.assign({},item); 
            this.editedIndex=this.items.indexOf(item);
            this.dialogSensorEdit=true;
        }
    },
    created(){
        this.loadSensors();
    }  

}
</script>