<template>
  <div class="primary" style="position:relative;width:100%;height:100%">
    
    <v-app-bar
      app
      :color="`${appBarElevation?'transparent':'white'}`"
      dark
      :elevation="appBarElevation?0:1"
      class="ma-0 pa-0"
    >
      <div class="d-flex align-center">
        <v-btn
          @click.stop="menuActive = !menuActive"
          :class="`${!menuActive?'primary':'white'} `"
          small
          fab
        >
          <v-icon  v-if="!menuActive" small>
            fa-stream
          </v-icon>
          <v-icon v-else color="primary" small>
            fa-times
          </v-icon>
        </v-btn>
        
      </div>
      <img src="@/assets/logo.png" alt="" height="50px">
      <!-- <span class="ml-5 mr-2 px primary--text text-h5"> Rotoplas </span>       -->
      <v-spacer></v-spacer>
      <v-menu
        transition="slide-x-transition"
        bottom
        right
        class="menu-rounded"       
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn            
            :color="menuActive?'white':'primary'"
            text
            v-bind="attrs"
            v-on="on"
            fab 
          >
            <v-icon  x-large>account_circle</v-icon>
          </v-btn>
        </template>
        <v-card class="card-rounded">
          <v-card-title primary-title>
            
            <span >
              <v-avatar
                size="50"
                color="grey lighten-3"
              >
                <v-icon color="primary">fa-user</v-icon>
                
              </v-avatar>
              <span class="ma-5 font-weight-bold" > {{user.first_name}} {{user.last_name}} </span>
              
            </span>
            
          </v-card-title>
          <v-card-text>
            <v-list elevation="0" color="primary--text">
              <v-list-item
                @click="logOut()"
                
              >
                <v-list-item-avatar>
                  <v-icon small>fa-power-off</v-icon>
                  
                </v-list-item-avatar>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        
      </v-menu>

      
    </v-app-bar>
    
      <div style="position:relative;width:100%;height:100%;overflow-x:hidden" class=" ma-0 pa-0" >
        <div style="position:absolute;width:100%;height:100%;" >
          <v-navigation-drawer
            class="primary "
            dark
            v-model="menuActive"
            
            width="300"
          >
            
            <v-list class="mt-10">
              
            </v-list>
            <!-- <v-list class="mt-10">
              <v-list-item               
               v-for="(item,key) in menuItems"
               :key="key"
               :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon small>{{item.icon}}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> {{item.text}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list> -->
            <v-list class="mt-10" >
              <template
                v-for="(item,key) in menuItems"
              >
                <v-list-group
                  no-action                  
                  v-if="!!item.list"
                  :key="key"
                  v-model="item.active"
                  color="white--text"                 
                >
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon small>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title > <span class="white--text">{{item.text}}</span> </v-list-item-title>
                    </v-list-item-content>
                    
                  </template>
                  <template
                      v-for="(secondList, i) in item.list"
                  >
                    <v-list-group
                      :value="true"
                      no-action
                      :key="i+'secondlist'"
                      sub-group
                      v-if="secondList.list"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title > <span class="white--text">{{secondList.text}}</span> </v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="(thirdlist, j) in secondList.list"
                        :key="j+'thirdlist'"
                        :to="thirdlist.link"

                      >
                        <v-list-item-content>
                          <v-list-item-title > <span class="white--text">{{thirdlist.text}}</span> </v-list-item-title>
                        </v-list-item-content>
                        <!-- <v-list-item-title v-text="thirdlist.text"></v-list-item-title> -->

                        <v-list-item-icon>
                          <v-icon v-text="thirdlist.icon"></v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-group>
                    <v-list-item
                      :to="secondList.link"
                      color="white--text"
                      v-else
                      :key="i+'secondlist'"
                    >
                      <v-list-item-icon v-if="secondList.icon">
                        <v-icon small>{{secondList.icon}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title> {{secondList.text}} </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                    
                </v-list-group>
                <v-list-item               
                  :key="key"
                  :to="item.link"
                  v-else
                >
                  <v-list-item-icon>
                    <v-icon small>{{item.icon}}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title> {{item.text}} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="key+'div'"></v-divider>
              </template>
              
            </v-list>

          </v-navigation-drawer>
        </div>
        <div :style="`height:${windowHeight}:width:100%`" @click="menuActive=false">

          <v-card  :class="`${menuActive?'card-resize':'pt-10'}`"  style="overflow-y:auto;background-color:#F2F8F9;" :height="windowHeight" width="100%" >
            <v-overlay
              style="border-radius: 20px 20px 20px 20px;-moz-border-radius: 20px 20px 20px 20px;-webkit-border-radius: 20px 20px 20px 20px;" 
              :value="menuActive" 
              color="grey lighten-4"
              @click="menuActive=false"
            ></v-overlay>            
                <router-view @click="menuActive=false"></router-view>            
          </v-card>
        </div>
      </div>
        
  </div>
    
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data:()=>({
    appBarElevation:false,
    user:{},
    menuActive:false,
    menuItems:[
      {text:"Inicio",link:"/",icon:"fa-home"},
      {text:"Clientes",link:"/clients",icon:"fa-tractor"},
      {
        text:"Integraciones",
        icon:"fa-toolbox",
        active:false,
        list:[
          {text:"Aplicaciones",link:"/developers/aplications"},
          {
            text:"Lista API",
            icon:"fa-toolbox",
            active:false,
            list:[
              {text:"Dispositivos",link:"/developers/api/devices"},
              {text:"Data",link:"/developers/api/data"},
            ]
          },
        ]
      },
      // {text:"Dispositivos",link:"/devices/list",icon:"fa-network-wired"},
      // {text:"Mapa",link:"/devices/map",icon:"fa-map-marked-alt"},
      // {text:"Campos",link:"/location/list",icon:"fa-seedling"}
    ],
    windowHeight:window.innerHeight
  }),
  watch:{
    menuActive(val){
      if(!val)
        setTimeout(() => {
          this.$store.commit('setRounded', val)
          this.appBarElevation=val
        }, 500);
      else{
        setTimeout(() => {
          this.appBarElevation=val
          this.$store.commit('setRounded', val)
        }, 300);
      }
    }
  },
  methods:{
    logOut(){
      this.$session.remove("session");
      localStorage.removeItem("session");
      window.location.href="#/login/auth";
    }
  },
  created(){
    window.onresize=function(){     
      self.windowHeight= window.innerHeight;
    }
  },
  mounted(){
    let self=this;
    this.user=this.$session.get("user");
    this.$store.commit('setUser', this.user)
    window.onresize=function(){     
      self.windowHeight= window.innerHeight;
    }
  },
  updated(){
    
  }
}
</script>
<style>
 
</style>
