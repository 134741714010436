<template>
    <div  :class="`mobile-card-device-info ${!!active?(activeLarge?'second-top-mobile-card':'first-top-mobile-card'):''}`" >
        <div style="width:100%" class="text-center">
            <span>
                <v-icon @click="activeLarge=!activeLarge">
                    fa-grip-lines
                </v-icon>
            </span>
        </div>
        <div style="">
            <v-row>
                <v-col class="text-center">
                    <div style="font-size:18px;color:#111111">Dispositivo:</div> 
                    <span style="color:#999999">{{!!device?device.name:''}}</span>           
                    
                </v-col>
                <v-col class="text-center">
                    <div style="font-size:18px;color:#111111">Campo:</div>            
                    <span style="color:#999999">{{!!device?device.name:''}}</span>
                </v-col>
            </v-row>
            
        </div>
        <div style="overflow-y:auto;height:70%">
            
            <div>
                <v-divider></v-divider>
                <v-row align="center" justify="center">
                    <v-col class="text-center">
                        <v-btn color="primary" small rounded :outlined="days!=0" @click="$emit('select-days',0)"> Hoy</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="primary" small rounded :outlined="days!=1" @click="$emit('select-days',1)"> Ayer</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="primary" small rounded :outlined="days!=7" @click="$emit('select-days',7)"> 7 días</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="primary" small rounded :outlined="days!=15" @click="$emit('select-days',15)"> 15 días</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="primary" small rounded :outlined="days!=30" @click="$emit('select-days',30)"> 30 días</v-btn>
                    </v-col>
                </v-row>
                <MobileSliderCardSensors :sensors="device.sensors" @sensors-update="update($event)"/>
            </div>
            <div >
                
                <MobileSensorsLinearChart :sensors="device.sensors"/>
                
            </div>
            <v-divider></v-divider>
            <div class="my-2">
                
                <v-card class="mx-2 card-rounded cloud-style2" color=" ">
                    <v-card-text>
                        <MobileTimeCard :values="weather"/>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        
    </div>
</template>
<script>
import MobileSliderCardSensors from './MobileSliderCardSensors.vue'
import MobileSensorsLinearChart from "./MobileSensorsLinearChart";
import MobileTimeCard from "./MobileTimeCard";
export default {
    props:["active","device","weather","days"],
    components:{MobileSliderCardSensors,MobileSensorsLinearChart,MobileTimeCard},
    data:()=>({
        activeLarge:false
    }),
    methods:{
        update(sensors){
            this.device.sensors=[];
            this.device.sensors=Object.assign(this.device.sensors,sensors);
        }
    },
    mounted(){
        
    }
}
</script>
<style>
    .mobile-card-device-info{
        -webkit-transition: all .4s ease .2s; 
        -moz-transition: all .4s ease 2s; 
        -o-transition: all .4s ease .2s; 
        -ms-transition: all .4s ease .2s; 
        transition: all .4s ease .2s;
        position:absolute;
        top:100%;
        background: #fff;
        left:0;
        width:100%;
        height:100%;
        padding: 5px;
        overflow:hidden;
        border-radius: 20px 20px 20px 20px;
        -moz-border-radius: 20px 20px 20px 20px;
        -webkit-border-radius: 20px 20px 20px 20px;
    }
    .first-top-mobile-card{
        margin-top: -100px;
    }
    .second-top-mobile-card{
        top: 35px;
    }
    .cloud-style2.v-sheet.v-card:not(.v-sheet--outlined){
        
        background: -moz-linear-gradient(top, #ffffff 0%, #eef8f4 74%, #eef8f4 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff), color-stop(74%, #eef8f4), color-stop(100%, #eef8f4));
        background: -webkit-linear-gradient(top, #ffffff 0%, #eef8f4 74%, #eef8f4 100%);
        background: -o-linear-gradient(top, #ffffff 0%, #eef8f4 74%, #eef8f4 100%);
        background: -ms-linear-gradient(top, #ffffff 0%, #eef8f4 74%, #eef8f4 100%);
        background: linear-gradient(to bottom,#ffffff 0%, #eef8f4 74%, #eef8f4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eef8f4', GradientType=0 );
    }
    .cloud-style3.v-sheet.v-card:not(.v-sheet--outlined){
        
        background: -moz-linear-gradient(top,#ffffff90 0%, #ecfff790 74%, #ecfff790 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff90), color-stop(74%, #ecfff790), color-stop(100%, #ecfff790));
        background: -webkit-linear-gradient(top, #ffffff90 0%, #ecfff790 74%, #ecfff790 100%);
        background: -o-linear-gradient(top,#ffffff90 0%, #ecfff790 74%, #ecfff790 100%);
        background: -ms-linear-gradient(top, #ffffff90 0%, #ecfff790 74%, #ecfff790 100%);
        background: linear-gradient(to bottom,#ffffff90 0%, #ecfff790 74%, #ecfff790 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff90', endColorstr='#ecfff790', GradientType=0 );
    }
    
</style>