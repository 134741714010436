<template>
    <div  style="height:100%">
        <Loading :active="loading"/>
        <LocationsMap :locations="fields" v-if="showMap" @map="showMap=$event"/>
        <v-container grid-list-xs style="margin-top:25px" v-else>
            <v-row>
                <v-breadcrumbs divider="/" :items="breadCrumsItems">                        
                        </v-breadcrumbs>
               <v-spacer></v-spacer>
               <v-btn color="white" @click="showMap=true"> <v-icon>map</v-icon> </v-btn>        
            </v-row>
            <v-row >                
                
                <v-col cols="12">
                    <v-divider></v-divider>
                    <v-row class="pt-0">
                        <v-col cols="12" class="pt-0">
                            <v-row>
                                <v-col cols="12" sm="6" md="3" >
                                    <infoMiniCard icon="fa-industry" text="Clientes" :count="clients.length" color="pink" border="true"/>                                    
                                </v-col>                                
                                <v-col cols="12" sm="6" md="3" >
                                    <infoMiniCard icon="fas fa-water" text="Locaciones" :count="fields.length" color="teal" border="true"/>
                                </v-col>
                                <v-col cols="12" sm="6" md="3"  >  
                                    <infoMiniCard icon="fa-broadcast-tower" text="Dispositivos" :count="devices.length" color="blue" border="true"/>  
                                </v-col>
                                <v-col cols="12" sm="6" md="3" >
                                    <infoMiniCard icon="fa-network-wired" text="Sensores" :count="sensors.length" color="deep-orange"/>  
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                
                                <v-col cols="12" md="6" lg="4" xl="3" v-for="location in fields" :key="location._id+'locaciones'">
                        
                                    <v-card height="300px" class="cloud-style2" >
                                        <v-img
                                            height="150"
                                            :src="location.image?location.image:'/crops/ptar.jpg'"
                                        >
                                            <div style="background:#00206050;height:100%;width:100%" class="white--text">
                                                <v-card-title primary-title>
                                                    <v-card rounded="lg" outlined :to="`/mylist/location/${location._id}`">
                                                        <v-card-text>
                                                            <v-icon color="primary">fa-chart-area</v-icon>
                                                        </v-card-text>            
                                                    </v-card>
                                                    <div class="mx-5 text-h5">
                                                        {{location.name}}  
                                                        <div class="text-caption">
                                                            {{location.customer.name}}
                                                        </div>
                                                    </div>
                                                </v-card-title>
                                            </div>
                                        </v-img>
                                        
                                        <v-divider></v-divider>
                                        <v-card-text style="height:180px;overflow-y:auto" >
                                            <Carroucel :items="location.devices"/>
                                            <template v-for="device in location.devices" v-if="false">
                                                <v-card :key="device._id" elevation="0" color="transparent" v-if="device.sensors.length==1">
                                                    <v-card-text >
                                                        Dispositivo: {{device.device_id}}
                                                        <div v-for="sensor in device.sensors" :key="sensor._id">
                                                            <SensorCard :sensor="sensor" :device="device"/>
                                                        </div>
                                                        
                                                    </v-card-text>                                    
                                                </v-card>
                                                <v-card :key="device._id" elevation="0" color="transparent" v-else>
                                                    <v-card-text >
                                                        Dispositivo: {{device.device_id}}
                                                        <v-row>
                                                            <SliderCardSensors :sensors="device.sensors"/>
                                                        </v-row>
                                                    </v-card-text>  
                                                </v-card>
                                                <v-divider :key="device._id+'device'"></v-divider>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                     

                </v-col>
            </v-row> 
        </v-container>
               
        
    </div>
</template>
<script>
import Loading from "../../components/Loading";
import infoMiniCard from "@/components/infoMiniCard";
import LocationsMap from "@/components/LocationsMap";
import Clients from '@/services/Clients'
import SliderCardSensors from '../Sensors/SliderCardSensors2';
import SensorCard from '../Sensors/SensorCard';
import Carroucel from '@/components/Carroucel';
export default {
    props:["device"],
    components:{Loading,LocationsMap,infoMiniCard,SliderCardSensors,SensorCard,Carroucel},
    data:()=>({
        loading:false,
        breadCrumsItems:[
            {
                text: 'Inicio',
                disabled: true,
                href: '#/dashboard',
            }
        ],
        sensors:[],
        devices:[],
        clients:[],
        fields:[],
        showMap:true
        
    }),
    methods:{
        async loadAllData(){
            this.loading=true
            try {
                let clients=await Clients.getMyClients();
                this.clients=clients; 
                let res=await Clients.getMyClientsExpandData(); 
                this.fields=res;
                let sensors=[];
                let devices=[];
                res.map(item=>{
                    item.devices.map((device)=>{                    
                        device.sensors.map((sensor,i)=>{
                            sensors.push({...sensor,pos:(!!device.device_type && device.device_type=='FlowV2'?0:(sensor.type==7?1:0)),selected:i==0?true:false});
                            device.sensors[i]={...sensor,pos:(!!device.device_type && device.device_type=='FlowV2'?0:(sensor.type==7?1:0)),selected:i==0?true:false}
                        });
                        devices.push(device);
                    })                
                });
                this.devices=devices;
                this.sensors=sensors;
                
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        }
    },
    created(){
        this.loadAllData();
    }  
}
</script>
<style >
    .cloud-style{
        
        background: -moz-linear-gradient(top, #476461 0%, rgb(41, 41, 78)rgb(41, 41, 78) 74%, rgb(41, 41, 78) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #476461), color-stop(74%, rgb(41, 41, 78)), color-stop(100%, rgb(41, 41, 78)));
        background: -webkit-linear-gradient(top, #476461 0%, rgb(41, 41, 78) 74%, rgb(41, 41, 78) 100%);
        background: -o-linear-gradient(top, #476461 0%, rgb(41, 41, 78) 74%, rgb(41, 41, 78) 100%);
        background: -ms-linear-gradient(top, #476461 0%, rgb(41, 41, 78) 74%, rgb(41, 41, 78) 100%);
        background: linear-gradient(to bottom,#476461 0%, rgb(41, 41, 78) 74%, rgb(41, 41, 78) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#476461', endColorstr='#090994', GradientType=0 );
    }
</style>