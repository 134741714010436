import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"sensors";
class User {
	static async get(headers) {		
		try {
			var { data } = await Connection.get(URL,headers);
			return data
		} catch ( error ) {
			return error
		}
	}
	static save(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL
			try {
				const res = await Connection.post(url,data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await Connection.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static getLastData(id) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id+"/data/last"
			try {
				const res = await Connection.get(url);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static getLastTwoData(id) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id+"/data/two/last"
			try {
				const res = await Connection.get(url);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static getLastValuesByType(id,type) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id+"/"+type+"/values/last"
			try {
				const res = await Connection.get(url);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static getDataByDateRange(id,from,to,points) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id+"/data/bydaterange/"+from+"/"+to+"/"+(!!points?points:"")
			try {
				const res = await Connection.get(url);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static getValuesByTypeAndDateRange(id,type,from,to) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id+"/"+type+"/values/bydaterange/"+from+"/"+to
			try {
				const res = await Connection.get(url);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static async update( id,userData ) {
		var url = (URL+"/"+id)
		try {
			var { data } = await Connection.put(url,userData);
			return data
		} catch ( error ) {
			return error
		}
    }
	static async updateMultiply( id,from,to ) {
		var url = (URL+"/"+id+"/"+from+"/"+to+"/multiply")
		try {
			var { data } = await Connection.put(url,{});
			return data
		} catch ( error ) {
			return error
		}
    }
    
}

export default User;