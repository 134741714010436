<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :overlay="false"
      max-width="1500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Alarmas 
          <v-spacer></v-spacer>
          <v-col cols="12">

            <v-select
              v-model="selectedItems"
              :items="options"
              item-value="id"
              item-text="value"
              :menu-props="{ maxHeight: '300' }"
              label="Selecciona los tipos de alarma"
              multiple
              persistent-hint
              solo
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-data-table :headers="headers" :items="items">
            <template v-slot:item.type="{ item }">
              <v-chip
                v-for="label in getAlarmLabel(item.alarm_status)"
                class="ma-2"
                :key="label"
                label
                color="primary"
              >
                {{ label }}
              </v-chip>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ $moment(item.created_at).format("ddd DD-MMM YYYY HH:mm") }}
            </template>
            <template v-slot:item.values="{ item }">
              <div v-if="item.values[0]">
                {{ parseFloat(item.values[0].value).toFixed(2) }}{{ item.values[0].value_type.unit }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')">Cerrar</v-btn>
        </v-card-actions>
        <Loading :active="loading" :absolute="false"></Loading>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Data from "@/services/Data";
import Loading from "@/components/Loading";
export default {
  props: { dialog: { type: Boolean, default: true }, sensor: { type: String } },
  components: {
    Loading,
  },
  data: () => ({
    items: [],
    selectedItems: [],
    loading: false,
    options: [
      { id: "tamper_alarm", value: "Manipulación" },
      { id: "leakage_alarm", value: "Fuga" },
      { id: "burst_alarm", value: "Estallido" },
      { id: "freezing_alarm", value: "Congelación" },
      { id: "meter_battery_alarm", value: "Batería" },
      { id: "empty_pipe_alarm", value: "Tubería vacía" },
      { id: "reverse_flow_alarm", value: "Flujo inverso" },
      { id: "over_range_alarm", value: "Rango excedido" },
      { id: "temperature_alarm", value: "Temperatura" },
      { id: "ee_error", value: "EE error" },
      { id: "transducer_in_error", value: "Error en transductor de entrada" },
      { id: "transducer_out_error", value: "Error en transductor de salida" },
    ],
    headers: [
      { text: "Tipo", value: "type" },
      { text: "Fecha", value: "created_at" },
      { text: "value", value: "values" },
    ],
  }),
  watch: {
    dialog() {
      this.selectedItems = [this.options[5].id];
      this.loadAlarms();
    },
    selectedItems() {
      this.items = [];
      this.loadAlarms();
    },
  },
  computed: {
    likesAllFruit() {
      return this.selectedItems.length === this.options.length;
    },
    likesSomeFruit() {
      return this.selectedItems.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    async loadAlarms() { 
      try {
        if (this.selectedItems.length <= 0) return;
        this.loading = true;
        const res = await Data.getAlarmByArray(this.sensor, {
          status: this.selectedItems,
        });
        this.items = res; 
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.options.slice().map((v) => v.id);
        }
      });
    },
    getAlarmLabel(array) {
      return array.map((v) => {
        const selected = this.options.find((d) => v == d.id);
        return selected.value;
      });
    },
  },
  mounted() {
    // this.loadAlarms();
  },
};
</script>
