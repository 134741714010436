import Vue from 'vue'
import App from './App.vue'
import './session'
import './registerServiceWorker'
import './components/maps';
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './assets/fonts/rotoplas/style.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from "moment";
import GmapCluster from 'vue2-google-maps/src/components/cluster' 
 
Vue.use(VueSweetalert2);
Vue.config.productionTip = false

moment.locale('es')




Vue.prototype.$moment=moment;
Vue.component('GmapCluster', GmapCluster) 

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
