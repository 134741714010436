import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"registertokens";
class User {
	static asyn
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await Connection.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	
	static async update( id,userData ) {
		var url = (URL+"/"+id)
		try {
			var { data } = await Connection.put(url,userData);
			return data
		} catch ( error ) {
			return error
		}
    }
    
}

export default User;