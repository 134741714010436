<template>
    <div>
        <div >
            <v-btn color="primary" elevation="0" x-small fab dark @click="rangeDialog=!rangeDialog">
                <v-icon x-small>fa-expand-alt</v-icon>
            </v-btn>
        </div>
        <v-dialog
           v-model="rangeDialog"
           scrollable
           :overlay="false"
           max-width="400px"
           transition="dialog-transition"
       >   
            <v-form v-on:submit.prevent="updateOptions()" lazy-validation ref="rangeForm">
                <v-card>
                    <Loading :active="loading"/>
                    <v-card-title primary-title class="primary white--text" style="height:80px">
                        Ajustar rango
                        <v-spacer></v-spacer>
                        <v-btn color="white" xmall icon @click="rangeDialog=false">
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs>
                                <v-checkbox
                                    v-model="rangeEditedItem.active"
                                    :label="`Rango ${rangeEditedItem.active?'automatico':'personalizado'}`"
                                ></v-checkbox>
                                <v-text-field
                                    name="chartmax_Y_value"
                                    v-model="rangeEditedItem.max"
                                    label="Valor máximo"
                                    rounded
                                    dense
                                    filled
                                    :disabled="!rangeEditedItem.active"
                                    prepend-inner-icon="fa-long-arrow-alt-up"
                                    :suffix="unit"
                                    :rules="[(v)=>parseFloat(v)===0 || !!v || 'Este campo es obligatorio', (v)=>v>=0 || 'No se aceptan números negativos',(v)=>v>rangeEditedItem.min || 'Ingrese un numero mayor al valor minimo']"
                                ></v-text-field> 
                                <v-text-field
                                    name="chartmmin_Y_value"
                                    v-model="rangeEditedItem.min"
                                    label="Valor mínimo"
                                    rounded
                                    dense
                                    filled
                                    :disabled="!rangeEditedItem.active"
                                    prepend-inner-icon="fa-long-arrow-alt-down"
                                    :suffix="unit"
                                    :rules="[(v)=>parseFloat(v)===0 || 
                                             !!v || 'Este campo es obligatorio',
                                             (v)=>v<rangeEditedItem.max || 'Ingrese un numero menor al valor maximo']"
                                ></v-text-field>
                        </v-container>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click="updateOptions()">Aceptar</v-btn>
                        <v-btn color="secondary" outlined @click="rangeDialog=false">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
           
       </v-dialog>
    </div>
</template>
<script>
import Loading from '@/components/Loading'

export default {
    props:["options","unit"],
    components:{Loading},
    data:()=>({
        rangeDialog:false,
        rangeEditedItem:{
            min:0,
            max:0,
            active:false
        },
        loading:false
    }),
    methods:{
        closeModal(){
            this.rangeDialog=false;
            this.loading=false;
            this.$refs.rangeForm.resetValidation()
        },
        updateOptions(){
            if(!this.$refs.rangeForm.validate() && this.rangeEditedItem.active){
                return;
            };
            this.loading=true;
            setTimeout(() => {
                if(!this.rangeEditedItem.active){
                    this.loading=true;
                    delete this.options.scales.yAxes[0].ticks.min;
                    delete this.options.scales.yAxes[0].ticks.max;
                    this.$emit("update-options",this.options);
                }else{
                    
                    
                    let options=Object.assign({},this.options);
                    options.scales.yAxes[0].ticks=Object.assign(options.scales.yAxes[0].ticks,this.rangeEditedItem);                
                    this.$emit("update-options",options);             
                }
                this.closeModal();
            }, 1000);
            
        }
    },
    mounted(){
        if(!!this.options && !!this.options.scales.yAxes[0].ticks.max){
            this.rangeEditedItem.active=true;
            this.rangeEditedItem.max=this.options.scales.yAxes[0].ticks.max;
            this.rangeEditedItem.min=this.options.scales.yAxes[0].ticks.min;
        }
    }
}
</script>