<template>
    <v-dialog
        :value="value"
        @input="$emit('input',$event)"
        :persistent="type=='confirm'"        
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
    >
        <v-card >
            <v-card-title primary-title :class="color[type]" class="white--text">
                {{title}}
                <v-spacer ></v-spacer>
                <v-btn color="grey darken-1" fab text small>
                    <v-icon class="white--text">close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-xs class="text-h6">
                    
                    {{ text }}
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer ></v-spacer>
                <v-btn color="warning" v-if="type=='confirm'" @click="cancel()">Cancelar</v-btn>
                <v-btn color="primary" @click="accept()">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    value:{type:Boolean,default:false},
    type:{
        type:String,
        default:'alert'
    },
    title:{type:String,default:''},    
    text:{type:String,default:''},
  },
  components: {

  },
  data: () => ({ 
    color:{
        confirm:'primary',
        error:'error',
        alert:'amber lighten-3',
        success:'success',
        warning:'warning'
    }
  }),
  watch: { 

  },
  methods: { 
    cancel(){
        this.$emit('cancel',false);
        this.close()
    },
    accept(){
        this.$emit('accept',false);
        this.close()
    },
    close(){

        this.$emit('input',false)
    }
  },
  mounted() {
     
  },
  computed: {},
};
</script>
