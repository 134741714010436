import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins


export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
   
    mounted() {   
        this.$refs.canvas.parentNode.style.height = '235px'; 
        this.chartData.datasets.forEach(element => {   
            var gradient = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);    
            gradient.addColorStop(0,  element.fondoColor.substring(0,7)+"90");
            gradient.addColorStop(0.5,  element.fondoColor.substring(0,7)+"10");
            gradient.addColorStop(1, element.fondoColor.substring(0,7)+"01");
            element.backgroundColor=gradient;
            element.pointBackgroundColor= element.fondoColor.substring(0,7)+"60";
            element.pointBorderColor=element.fondoColor.substring(0,7)+"20",
            element.borderColor=[
                element.fondoColor.substring(0,7)+"50"
            ]
        });
        this.renderChart(this.chartData, this.options);        
    },
    watch: {
        
        chartData(data){
            this.$emit("start"); 
            this.chartData.datasets.forEach(element => {   
                var gradient = this.$refs.canvas
                .getContext("2d")
                .createLinearGradient(0, 0, 0, 450);    
                gradient.addColorStop(0, element.fondoColor.substring(0,7)+"90");
                gradient.addColorStop(0.5, element.fondoColor.substring(0,7)+"10");
                gradient.addColorStop(1,  element.fondoColor.substring(0,7)+"01");
                element.backgroundColor=gradient;
                element.pointBackgroundColor= element.fondoColor.substring(0,7)+"60";
                element.pointBorderColor= element.fondoColor.substring(0,7)+"20",
                element.borderColor=[
                    element.fondoColor.substring(0,7)+"ff"
                ]
            });
            this.renderChart(this.chartData, this.options)
            this.$emit("success");
        },
        options: {
            handler(newOption, oldOption) {
                
                if(typeof this.options.scales.yAxes[0].ticks.min=="undefined" || typeof this.options.scales.yAxes[0].ticks.max=="undefined"){
                   // delete this.options.scales.yAxes[0].ticks.min;
                   // delete this.options.scales.yAxes[0].ticks.max
                }else{
                    this.options.scales.yAxes[0].ticks.min=parseInt(this.options.scales.yAxes[0].ticks.min);
                    this.options.scales.yAxes[0].ticks.max=parseInt(this.options.scales.yAxes[0].ticks.max);
                }
                
                this._data._chart.update();
                this._data._chart.destroy();
                this.renderChart(this.chartData, this.options)
            },
            deep: true
        }
    },methods: {
        
    },
}