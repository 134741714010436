<template>
    <div class="mobile-slider-container">
        
        
        <div class="mobile-slider-card-container" >
            
            <div class="mobile-slider-card-container-scroll"  style="position:relative">
                <div style="max-height:0px;display: flex;position:absolute;top:0px;">
                    <span class="mobile-slider-item" v-for="(item, index) in sensors" :key="index" >
                        
                        <MobileSensorMiniCard :sensor="item" @sensor-update="updateSensor($event,index)"/>
                        
                    </span>
                </div>
                
                
            </div>                        
        </div>
    </div>
    
</template>
<script>

import MobileSensorMiniCard from './MobileSensorMiniCard.vue';
export default {
    props:["sensors","all","labels","updated","device"],
    components:{MobileSensorMiniCard},
    data:()=>({
        active:true,
        cardWidth:400,
        
    }),
    methods:{
        
        updateSensor(sensor,index){
            this.sensors[index]=sensor;
            this.$emit('sensors-update',this.sensors)
        },
        getChartData(sensor){
            if(sensor.codeSensor==4){
                switch (sensor.flujo) {
                    case 'acumulado':
                        return{labels:this.labels,datasets:sensor.aggregate}
                        break;
                    case 'sumaAcumulado':
                        return{labels:this.labels,datasets:sensor.sumAggregate}
                        break;
                    case 'instantaneo':
                        return{labels:this.labels,datasets:sensor.flow}
                        break;
                    default:
                        return{labels:this.labels,datasets:[]}
                        break;
                }
            }
            return{labels:this.labels,datasets:sensor.datas}
        }
    },
    created(){

    },
    computed:{
    }

}

</script>
<style >
   
    .mobile-slider-container{
        display: flex;
        width: 100%;
        padding: 5px;
        
        align-items: center;
        background-color:#00000000;
        border-radius: 5px 5px 0px 0px;
        -moz-border-radius: 5px 5px 0px 0px;
        -webkit-border-radius: 5px 5px 0px 0px;


    }
    .mobile-slider-card-container-scroll{
        align-items: center;
        
        overflow-x: scroll;
        display: flex;
        height: 300px;
        scroll-behavior: smooth;
        -webkit-transition: all 700ms ease-out;
        -moz-transition:all 700ms ease-out;
        transition: all 700ms ease-out;
    }
    .mobile-slider-card-container{
        width: 100%;
        overflow-x: hidden;
        overflow-y:hidden;
        height: 120px;
        padding: 5px;
        scrollbar-width: 1px;
        -webkit-transition: all 700ms ease-out;
        -moz-transition:all 700ms ease-out;
        transition: all 700ms ease-out;
        border-radius: 5px 5px 5px 5px;
        -moz-border-radius: 5px 5px 5px 5px;
        -webkit-border-radius: 5px 5px 5px 5px;
        
    }
    .mobile-slider-item{
        
        border-radius: 16px;
        background-color: #17141d00;
        display: flex;
        flex-direction: column;
        margin-top:5px;
        margin-left:5px;
        
        margin-bottom: 10px;
        padding: 0rem;
        -webkit-transition: all 700ms ease-out;
        -moz-transition:all 700ms ease-out;
        transition: all 700ms ease-out;
    }
</style>