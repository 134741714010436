import Connection from "./Connection.js";
const URL = process.env.VUE_APP_SERVER_URI+"openweather";
class Weather {
	static async getOneCallLocation(location) {		
        let url=URL+`/onecall/location/${location.lat}/${location.lng}`
		try {
			var { data } = await Connection.get(url);
			return data
		} catch ( error ) {
			return error
		}
	}
	
}

export default Weather;