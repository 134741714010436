<template>
    <div >
        <Loading :active="loading"/>
        <v-btn x-small elevation="0" fab dark color="info" @click="createXls" class="mx-2">  <v-icon color="">fa-file-excel</v-icon>
        </v-btn>        
    </div>
</template>
<script>
import Loading from '@/components/Loading';
import XLSX from 'xlsx'
import { saveAs } from 'file-saver';
import moment from 'moment'
moment.locale("es");
var wb = XLSX.utils.book_new();
export default {
    props:["sensors","device"],
    components:{Loading},
    data:()=>({
        loading:false,
        arrayOptions:{
            "id":{hidden:true},
            "street":{text:"Calle"},
            "cp":{text:"CP"},
            "int":{text:"Int"},
            "ext":{text:"Ext"},
            "reference":{text:"Referencias"},
            "suburb":{text:"Colonia"},
            "township":{text:"Alcaldía"},
            "city":{text:"Ciudad"},
            "zipCodeId":{hidden:true},
        }
    }),
    watch:{
        
    },
    methods:{          
        s2ab(s){            
            var buf= new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (let i=0;i<s.length;i++) view[i]=s.charCodeAt(i) & 0xff;
            return buf;
        },
        createXls(){
            let self=this
            let device_id = this.device.device_id;
            try {
                wb.Props = {
                    Title: "SheetJS Tutorial",
                    Subject: "Test",
                    Author: "Red Stapler",
                    CreatedDate: new Date(2017,12,19)
                };
                wb.SheetNames=[];
                for(let i=0;i<this.sensors.length;i++){
                    let sensor=this.sensors[i];
                    if(sensor.selected){
                        wb.SheetNames.push(sensor.name);
                        var wsData = [["Fecha","Tipo de sensor","Tipo de medición","Valor"]];
                        for (let j = 0; j < sensor.data.length; j++) {
                            const data = sensor.data[j];
                            for(let k=0;k<data.values.length;k++){
                                let value=data.values[k]
                                let values=[this.$moment(data.created_at).format("YYYY/MM/DD HH:mm"),sensor.type.text];
                                values.push(value.value_type.text);
                                values.push(value.value);
                                wsData.push(values);
                            }
                            
                        }
                        var ws = XLSX.utils.aoa_to_sheet(wsData);
                        
                        wb.Sheets[sensor.name] = ws;
                    }
                }
                
                var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
                saveAs(new Blob([self.s2ab(wbout)],{type:"application/actect-stream"}),"bitacora-de-rieggo-"+this.device.name+"-"+this.$moment().format("DD-MMMM-YYYY HH-mm")+".xlsx");                
            } catch (error) {
                console.log(error);
            }
        },        
    },
    created(){
        
        // this.loadEvents();
       
    },
    computed:{
        dateRangeText () {
            return this.dateRange.join(' ~ ')
        },
        
    }
}


</script>