<template>
  <v-card>
    <v-card-actions class="primary white--text">
      Tipos de dispositivos
      <v-spacer></v-spacer>
      <v-btn
        color="grey darken-3 "
        @click="$emit('close')"
        fab
        text
        rounded
        small
      >
        <v-icon class="white--text">close</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-container grid-list-xs>
        <div class="row">
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="addDialog = true">
            <v-icon>add</v-icon>
          </v-btn>
        </div>
        <v-data-table :headers="headers" :items="items">
          <template v-slot:item.options="{ item }">
            <v-btn color="warning" @click="editItem(item)" fab text x-small
              ><v-icon>edit</v-icon></v-btn
            ><v-btn color="error" @click="deleteItem(item)" fab text x-small
              ><v-icon>delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
    <v-dialog
      v-model="addDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <form @submit.prevent="save" ref="typeForm">
          <v-card-title primary-title>
            Agregar un tipo de dispositivo
            <v-spacer></v-spacer>
            <v-btn
              color="grey darken-3"
              text
              fab
              small
              @click="closeTypeDialog()"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-xs>
              <v-text-field
                name="device_type"
                label="Tipo "
                v-model="editedItem.text"
                rounded
                dense
                filled
                :rules="[(v) => !!v || 'El campo es obligatorio']"
              ></v-text-field>
            </v-container>
            <Loading :active="loading2" :absolute="true"></Loading>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="closeTypeDialog"
              >Cancelar</v-btn
            >
            <v-btn color="primary" type="submit">Aceptar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <Loading :active="loading" :absolute="true"></Loading>
    <Alert
      v-model="alertObj.active"
      :title="alertObj.title"
      :text="alertObj.text"
      :type="alertObj.type"
      @accept="onDelete()"
    ></Alert>
  </v-card>
</template>
<script>
import DeviceType from "@/services/DeviceType";
import Loading from "@/components/Loading";
import Alert from "@/components/Alert";
export default {
  props: {
    customer: { type: String },
  },
  components: { Loading, Alert },
  data: () => ({
    headers: [
      { text: "Id", value: "_id", align: "center" },
      { text: "Nombre", value: "text", align: "center" },
      { text: "...", value: "options", align: "center" },
    ],
    items: [],
    editedItem: {},
    editedIndex: -1,
    addDialog: false,
    loading: false,
    loading2: false,
    alertObj: {
      active: false,
      title: "¿Estás seguro?",
      text: "El dispositivo se eliminará de manera permanente",
      type: "confirm",
    },
  }),
  watch: {
    customer(v) {
      this.loadDeviceType();
    },
  },
  methods: {
    deleteItem(e) {
      this.editedItem = Object.assign({}, e);
      this.editedIndex = this.items.indexOf(e);
      this.alertObj.active = true;
    },
    editItem(e) {
      this.editedItem = Object.assign({}, e);
      this.editedIndex = this.items.indexOf(e);
      this.addDialog = true;
    },
    closeTypeDialog() {
      this.editedItem = {};
      this.editedIndex = -1;
      this.addDialog = false;
    },
    afterDelete() {
      this.editedItem = Object.assign({});
      this.editedIndex = -1;
    },
    async onDelete() {
      try {
        await DeviceType.remove(this.editedItem._id);
        this.items.splice(this.editedIndex,1)
        this.afterDelete();
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      // if(!this.$refs.typeForm.validate()) return;
      this.loading = true;
      try {
        if (this.editedIndex == -1) {
          let res = await DeviceType.save({
            ...this.editedItem,
            customer: this.customer,
          });
          this.items.push(res);
        } else {
          let res = await DeviceType.update(
            this.editedItem._id,
            this.editedItem
          );
          this.items[this.editedIndex] = Object.assign(
            this.items[this.editedIndex],
            this.editedItem
          );
        }
        this.closeTypeDialog();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async loadDeviceType() {
      this.loading2 = true;
      try {
        let res = await DeviceType.findByCustomer(this.customer);
        this.items = res;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading2 = false;
      }
    },
  },
  mounted() {
    this.loadDeviceType();
  },
  computed: {},
};
</script>
