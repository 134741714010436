<template>
    <div style="height:60px;margin-top:-20px;margin-left:-35px;margin-right:20px" >
        <MiniLineChart :chartData="datacollection" :options="options" />
    </div>
</template>
<script>

import MiniLineChart from './chartJs/MiniLine.js'
export default {
    props:['data',"color"],
    components:{MiniLineChart},
    data:()=>({
        
        datacollection : {
            labels: [],
            datasets: []
        },
        options: {
            spanGaps: true,
            maintainAspectRatio: false,
            responsive: true,
            chartArea: {
                background: '#ffffff'
            },
            legend: {
                position: 'top',
                display:false,
                labels:{
                // fontColor:"#ffffff"
                }
            },
            title: {
                position: 'bottom',
                display: true,
                text:''
            },
            tooltips: {
                mode: 'index',
                intersect: true,
            },
            hover: {
                mode: 'nearest',
                intersect: true
            },
            
            scales: {
                
                xAxes: [{
                    
                    // type: 'time',
                    // time: {
                    //     minUnit:"minute",
                    //     unit: 'hour',
                        
                    //     displayFormats: {
                    //         "minute": 'MMM D hh:mm',
                    //         "hour": 'MMM D HH:mm '
                    //     },
                        
                    // },
                    fontColor:"#fff",
                    display: false,
                    scaleLabel: {
                        display: true,
                        labelString: 'Tiempo',
                        // fontColor:"white"
                    },
                    gridLines: {
                    color: 'rgba(171,171,171,0.5)',
                    lineWidth: 0.1,
                    display:false
                    },
                    
                    ticks: {
                    beginAtZero:true,
                    maxTicksLimit: 10
                    
                    
                    },
                }],
                yAxes: [{
                    display: false,
                    scaleLabel: {
                        display: true,
                        // labelString: 'Valor',
                        fontSize:16,
                        fontStyle:"bold"
                        // fontColor:"white"
                    },                
                    ticks: {
                        display:true,
                        beginAtZero: true,
                        stepSize: 10,
                        stepValue: 10,
                        callback: function(value, index, values) {
                        
                        
                            return value;
                        }
                    },
                    plotBands: [{
                    color: 'orange', // Color value
                    from: 0, // Start of the plot band
                    to: 0 // End of the plot band
                    }],
                    gridLines: {
                    color: 'rgba(171,171,171,0.5)',
                    lineWidth: 0.5,
                    display:true
                    }
                }]
            }
        }
    }),
    watch:{
        data(){           
           this.datacollection=this.convertirDatos()
        }
    },
    methods:{
        convertirDatos(){
            var self=this;              
            
            var obj=Object.assign(
                {
                    labels: [],
                    datasets: []
                }
            );
            var dataset={ // one line graph
                label: [],
                
                fondoColor:this.color,
                backgroundColor: [
                
                ],
                
                borderWidth: 1,
                hidden:false,
                //fill: false,
                lineTension: 0.2,
                
                //borderColor: "rgba(75,192,192,1)",
                borderCapStyle: 'solid',
                borderDash: [],
                borderDashOffset: 0.2,
                borderJoinStyle: 'miter',
                pointBorderColor: "rgba(75,192,192,1)",
                //pointBackgroundColor: "#000000",
                pointBorderWidth:0,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 3,
                pointRadius: 0 ,
                pointHitRadius: 20,
                data: [],
                
                
            };
            this.data.forEach(element => {
                obj.labels.push(element.createdAt);
                dataset.data.push(element.values[0].value);
            });
            obj.datasets.push(dataset);
            // let length=this.data.labels.length>=12?this.data.labels.length-12:0;
            // let labels=this.data.labels.slice(length);
            // obj.labels=[];
            
            // if(typeof labels!='undefined'){
            //     obj.labels=labels;
            //     obj.datasets.push({...dataset,data:this.data.datasets.slice(length)});
            // }       
            return obj;
            
        },
    },
    created(){
        this.datacollection=this.convertirDatos();
    }

}
</script>