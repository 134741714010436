<template>
    <v-dialog
        v-model="value"
        scrollable  
        persistent :overlay="false"
        max-width="500px"
        transition="dialog-transition"
    >
        <v-form ref="form" lazy-validation v-on:submit.prevent="save()">
            <v-card>
                <v-card-title primary-title class="primary white--text" >
                    {{cardTitle}}
                    <v-spacer></v-spacer>
                    <v-btn color="white" small icon @click="$emit('input',false)">
                        <v-icon small>
                            fa-times
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <Loading :active="loading"/>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                name="clientName"
                                label="Nombre"
                                v-model="editedItem.name"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                name="clientDescription"
                                label="Descripción"
                                v-model="editedItem.description"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                name="clientPhoneNumber"
                                label="Teléfono"
                                v-model="editedItem.phone_number"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit">
                        Aceptar
                    </v-btn>
                    <v-btn color="secondary" outlined>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>        
    </v-dialog>
</template>
<script>
import Farms from '@/services/Clients'
import Loading from '@/components/Loading'
export default {
    props:{
        value:{
            type:Boolean,
            default:false
        },
        client:{
            type:Object,
            default:function(){
                return {};
            }
        }
    },
    components:{Loading},
    data:()=>({
        loading:false,
        editedItem:{

        }
    }),
    watch:{
        client(){
            this.editedItem=Object.assign({},this.client);
        }
    },
    methods:{
        async save(){
            this.loading=true;
            try {
                if(this.editedItem._id){
                    let res=await Farms.update(this.editedItem._id,this.editedItem);
                    this.$emit("update-item",this.editedItem);
                }else{
                    let res=await Farms.save(this.editedItem);
                    this.$emit("add-item",res);
                }
                
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        }
    },
    mounted(){
        this.editedItem=Object.assign({},this.client);
    },
    computed:{
        cardTitle(){
            return this.editedItem._id?"Editar planta":"Nueva planta"
        }
    }
}
</script>