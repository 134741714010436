<template>
    <v-card rounded="lg" >
        <v-card-text class="ma-0 pa-0">
            <v-row class="ma-0 pa-0"  justify="center" align="center">
                <v-col cols="4" class="text-center">
                    <v-card :color="`${color} lighten-4`" rounded="lg" flat>
                        <v-card-text>
                            <v-icon :color="color" small>{{icon}}</v-icon>

                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="8" >
                    <div class="text-center">
                        {{text}}
                    </div>
                    <div class="text-h4 text-center font-weight-regular" :class="`${color}--text text--darken-4`">
                        {{count}}
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props:["icon","text","count","color","border"],
    components:{

    },
    data:()=>({

    }),
    methods:{

    },
    created(){

    }
}
</script>