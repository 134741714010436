<template>
    <div style="height:100%">
        <v-row align="center" justify="center" style="height:100%">
            <v-col cols="11" sm="8" md="6" lg="5" xl="4">
                <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="submit()">
                    <v-card class="card-rounded pa-10" >
                        <v-card-title primary-title >
                            <div style="width:100%;font-size:35px" class="ma-10 text-center">
                                Verificar Token
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field
                                label="Codigo"
                                v-model="code"
                                required                                
                                rounded
                                dense
                                filled
                                prepend-inner-icon="lock"
                                color="primary"
                            >
                            </v-text-field>
                        </v-card-text>
                        <v-card-actions >
                            
                            <div class=" text-center" style="width:100%">
                                <v-btn
                                rounded
                                large
                                block
                                type="submit"
                                color="primary"
                                >
                                    <span class="ma-5">
                                        Verificar
                                    </span>
                                    
                                </v-btn>
                            </div>
                            
                        </v-card-actions>

                    </v-card>                    
                    
                    
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import User from "../../services/LoginService";
import Loading from "../../components/Loading";
export default {    
    components:{Loading},
    data: () => ({
        isLogin:false,
        error:"",
        valid: true,
        code: "",    
    }),

    methods: {
        async submit() {
            // this.$session.set("rieggoToken","dasdas12a1sd2a1sd");
            // this.$session.set("user",{name:"Usuario Prueba"});
            // location.href="#/";
            location.href="/changePassword";

        },
        clear() {
            this.$refs.form.reset();
        }
    }
};
</script>