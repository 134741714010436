<template>
    <div>
        {{sensor.name}}
        <div class="font-weight-black  text-right text-caption" >
            1000 lts
        </div>
        
        <v-progress-linear
        color="primary accent-4"
        rounded
        height="15"
        :value="`${(flow/1000)*100}`"
        >
            <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
            </template>
        </v-progress-linear>
        <div class="font-weight-black text-caption">
            {{flow}} lts
        </div>
        
    </div>
</template>
<script>
import Sensors from '@/services/SensorsService'
export default {
    props:["sensor","dataType"],
    data:()=>({
        flow:0
    }),
    methods:{
        async loadData(){
            try {
                let res=await Sensors.getValuesByTypeAndDateRange(this.sensor._id,this.dataType,this.$moment(this.$moment().format("YYYY-MM-DD ")+"00:00"),this.$moment(this.$moment().format("YYYY-MM-DD ")+"23:59"));
                for (let index = 0; index < res.length; index++) {
                    const element = res[index];
                    this.flow+=element.value;
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>