<template>
    <div style="height:100%">
        <div style="height:100%;width:100%;position:absolute">
            <img src="@/assets/images/background-01.jpg" alt="" >
        </div>
        <div style="height:100%;width:100%;position:absolute;background:#171E3Add">               
        </div>
        <v-row align="center" justify="center" style="height:100%">
            <v-col cols="11" sm="8" md="6" lg="5" xl="4">
                <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="submit()">
                    <v-card class="card-rounded " flat>
                        <v-card-title primary-title >
                            <div style="width:100%;font-size:25px" class="ma-10 text-center">
                                Recuperar mi Contraseña.
                                <div class="text-h6 mt-5" v-if="!message">
                                    Para solicitar un cambio de contraseña, ingrese su email
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text v-if="!message">
                            <Loading size="20" :active="isLogin"/>
                            <v-text-field
                                label="Correo electrónico"
                                v-model="email"
                                :rules="emailRules"
                                required                                
                                rounded
                                filled
                                dense
                                prepend-inner-icon="email"
                                color="primary"
                            ></v-text-field>
                            <div style="width:100%" class="text-center error--text">{{error}}</div>
                            <v-btn color="secondary" text to="/">Ir a la pantalla de Inicio de sesión</v-btn>
                        </v-card-text>
                        <v-card-text v-if="message">
                            <!-- <Loading size="20" :active="isLogin"/> -->
                            <div class="text-h6 text-center">
                                {{message}}
                            </div>

                        </v-card-text>
                        <v-card-actions v-if="!message">
                            <div class=" text-center" style="width:100%">
                                <v-btn
                                rounded
                                large
                                block
                                type="submit"
                                color="primary"
                                >
                                    <span class="ma-5">
                                        Enviar solicitud
                                    </span>
                                    
                                </v-btn>
                            </div>                            
                        </v-card-actions>
                        <v-card-actions v-if="message">
                                <v-btn
                                rounded
                                large
                                block
                                to="/"
                                color="primary"
                                >
                                    <span class="ma-5">
                                        Volver a la pantalla de inicio.
                                    </span>
                                    
                                </v-btn>                     
                        </v-card-actions>

                    </v-card>                    
                    
                    
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import User from "../../services/UserService";
export default {    
    data: () => ({
        message:null,
        isLogin:false,
        error:"",
        valid: true,
        password: "",
        passRules: [v => !!v || "El campo es obligatorio"],
        email: "",
        emailRules: [v => !!v || "El correo electrónico es obligatorio"],        
        rememberme: false
    }),

    methods: {
        async submit() {
            this.isLogin=true;
            try {
               if(!this.$refs.form.validate())throw {error:'Form validate'}
                let res= await User.resetPassRequest(this.email);
                if(!!res) this.message="Se ha enviado un correo con la información necesaria para restablecer su contraseña"
            } catch (error) {
                console.log("eroror",error.toJSON(),error.message);
                this.error="El correo electrónico que ingreso no esta registrado, corrija e intentelo de nuevo."     
            }finally{
                this.isLogin=false;
            }

        },
        clear() {
            this.$refs.form.reset();
        }
    }
};
</script>