<template>
    <v-card rounded="lg" height="700px">    
        <v-card-title primary-title class="primary white--text" >
            Editar poligono de la locación
            <v-spacer></v-spacer>
            <v-btn color="white" dark icon small @click="$emit('input',!value)">
                <v-icon small >fa-times</v-icon>
            </v-btn>
            <v-col cols="12">
                <gmap-place-input  @place_changed="searchAddress" class="v-input white--text v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed">
                </gmap-place-input>
            </v-col>  
        </v-card-title>
        <v-card-text class="ma-0 pa-5">
            <GmapMap
                :center="center"
                :zoom="zoom"
                :map-type-id="mapType"
                v-bind:options="mapStyle"
                style="width: 100%; height: 100%"
                @click="setPolygonPosition"                            

            >                   
                <!-- <gmap-polygon ref="polygon" :options="polygonOptions" :editable="true" @dblclick="deleteNodes($event)" @paths_changed="updatePath($event)" :paths="paths"></gmap-polygon>
                 -->
                
            </GmapMap>       
        </v-card-text>
        <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="save()">
                Aceptar
            </v-btn>
            <v-btn color="secondary" outlined @click="$emit('input',!value)">
                Cancelar
            </v-btn>
        </v-card-actions>
    </v-card>
    
</template>
<script>
import {gmapApi} from 'vue2-google-maps'
import darkStyle from './maps/darkStyle.json'
import rieggoStyle from './maps/rieggoStyle.json'
import Locations from '../services/Locations'
export default {
    props:["location","value"],
    components:{},
    data:()=>({
        mapType:"roadmap",//roadmap,satellite,hybrid,terrain
        center:{
            lat:22.8531071,
            lng:-104.9059136
        },
        zoom:4,
        polygonOptions:{
            strokeColor: '#00C7B1',
            fillColor: "#00C7B1",
            fillOpacity: 0.80,
            strokeOpacity:1,
            strokeWeight: 2,
            draggable: true,
        },
        icon:{
            path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
            fillColor: '#E71C26',
            fillOpacity: .9,
            
            strokeWeight: 1,
            scale: .08
        },
        address:[],
        paths:[],
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        }
    }),
    watch:{
        
    },
    methods:{
        searchAddress(e){
            if(e.geometry)
                this.center=e.geometry.location;
                this.zoom=16
        },
        setPolygonPosition(mapData){
            let self=this;
            if (mapData.latLng) {
                self.paths.push({
                    lat: mapData.latLng.lat(),
                    lng: mapData.latLng.lng()
                });
            }
        },
        deleteNodes(e){
            if(e.vertex)
                this.paths.splice(e.vertex,1);
        },
        updatePath(e){            
             for (let i = 0; i < e.getLength(); i++) {
                for (let j = 0; j < e.getAt(i).getLength(); j++) {
                    if(!!this.paths[j]){                        
                        this.paths[j]=Object.assign(this.paths[j],{lat:e.getAt(i).getAt(j).lat() ,lng:e.getAt(i).getAt(j).lng()});
                    }else{
                        this.paths.push({lat:e.getAt(i).getAt(j).lat() ,lng:e.getAt(i).getAt(j).lng()});
                    }                    
                }                
            }            
        },
        async save(){
            try {
                await Locations.update(this.location._id,{polygon_nodes:this.paths});
                this.$emit("input",false);
                this.$emit("update-polygon",this.paths)
            } catch (error) {                
                console.log(error);
            }
        },
        getCenter(){
            if(this.paths.length<=0) return;
            var bounds = new this.google.maps.LatLngBounds();
            for (let i = 0; i < this.paths.length; i++) {
                bounds.extend(this.paths[i]);
            }
            this.center=bounds.getCenter();
        }
    },
    created(){
        if(!!this.location && this.location.polygon_nodes && this.location.polygon_nodes.length>0) this.paths=this.location.polygon_nodes;
        this.paths=this.paths.sort(function (a, b) {
            if (a.position > b.position) {
                return 1;
            }
            if (a.position < b.position) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    },
    mounted(){
        this.getCenter();

    },
    computed:{
        google: gmapApi,
        mapStyle(){
            let opt={
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }
            // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
            return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign(opt)
        },
        pathsComputed(){
            return this.paths;
        }
    }
}
</script>