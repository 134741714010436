<template>
  <v-card height="700px">
    <v-card-title primary-title class="">
      Ubicación de la locación
      <v-spacer></v-spacer>
      <v-btn color="white" fab small text @click="$emit('close-map-dialog')">
        <v-icon small>fa-times</v-icon>
      </v-btn>
      <v-col cols="12">
        <!-- <v-text-field v-model="searchedPoints.search" type="" error-count="" placeholder="" 
                    @change="searchAddress()"
                    label="Buscar " class="white--text" append-icon="search" dense filled color="white"> -->
        <gmap-place-input
          @place_changed="searchAddress"
          class="v-input white--text v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed"
        >
        </gmap-place-input>
        <!-- </v-text-field> -->
      </v-col>
    </v-card-title>
    <v-card-text class="ma-0 pa-5">
      <GmapMap
        :center="center"
        :zoom="zoom"
        :map-type-id="mapType"
        v-bind:options="mapStyle"
        style="width: 100%; height: 100%"
        @click="setPosition($event)"
      >
        <GmapMarker
          v-if="!!editedItem.latLng && !!editedItem.latLng.lat"
          :position="editedItem.latLng"
          :clickable="true"
          :draggable="true"
          :icon="{ ...icon, anchor: new google.maps.Point(200, 500) }"
          :title="`${editedItem.name}`"
          @click="openWindow()"
          @dragend="setPosition($event)"
        />
        <GmapMarker
          v-if="!!google && searchedPoints.isActive"
          :position="searchedPoints.latLng"
          :icon="searchedPoints.icon"
        />
        <!-- <gmap-polygon @click="setPosition($event,latLng)"  :options="polygonOptions" :paths="!!latLng?latLng.polygon_nodes:[]"></gmap-polygon> -->
        <!-- <gmap-info-window 
                        @closeclick="window_open=false" 
                        :opened="window_open" 
                        :position="location.latLng"
                        
                    >
                        
                        <template>
                            <div style="width:200px" >
                                <v-card elevation="0">
                                    <v-card-text>
                                        {{address.length>0?address[1].formatted_address:""}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" rounded >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                                
                                
                            </div>
                        </template>
                    </gmap-info-window> -->
      </GmapMap>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="save()"> Guardar </v-btn>
      <v-btn color="secondary" outlined @click="$emit('close-map-dialog')">
        Cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import darkStyle from "./maps/darkStyle.json";
import rieggoStyle from "./maps/rieggoStyle.json";
import Locations from "../services/Locations";
import Location from "../services/Locations";
export default {
  props: ["location"],
  components: {},
  data: () => ({
    window_open: false,
    mapType: "roadmap",
    center: {
      lat: 23.3143515,
      lng: -103.3223592,
    },
    zoom: 4,
    polygonOptions: {
      strokeColor: "#00C7B1",
      fillColor: "#00C7B1",
      fillOpacity: 0.8,
      strokeOpacity: 0.8,
      strokeWeight: 2,
    },
    icon: {
      path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
      fillColor: "#E71C26",
      fillOpacity: 0.9,

      strokeWeight: 1,
      scale: 0.08,
    },
    address: [],
    editedItem: {},
    searchedPoints: {
      isActive: false,
      markers: [],
      points: [],
      search: "",
      icon: {
        url: process.env.VUE_APP_URL + "pin_drop_black_24dp.svg",
      },
      latLng: {},
    },
  }),
  watch: {
    location() {
      this.editedItem = Object.assign({}, this.location);
    },
  },
  methods: {
    searchAddress(e) {
      if (e) {
        this.searchedPoints.latLng = e.geometry.latLng;
        this.searchedPoints.isActive = true;
      }
      this.center = e.geometry.latLng;
      this.zoom = 10;
      //    let self=this;
      //    let center=this.center
      //     const defaultBounds = {
      //         north: center.lat + 10,
      //         south: center.lat - 10,
      //         east: center.lng + 10,
      //         west: center.lng - 10,
      //     };
      //     var request = {
      //         bounds:defaultBounds,
      //         query: this.searchedPoints.search,
      //     };

      //     var service = new google.maps.places.PlacesService(this.actualMap);
      //     service.textSearch(request, function(results, status) {
      //         if (status === google.maps.places.PlacesServiceStatus.OK) {
      //             console.log(results);
      //             self.searchedPoints.isActive=true;
      //             self.searchedPoints.points=results;
      //         }
      //     });
    },
    setPosition(mapData) {
      let self = this; 
      if (mapData.latLng) {
        this.editedItem.latLng = {
          lat: mapData.latLng.lat(),
          lng: mapData.latLng.lng(),
        }; 
      }
      // this.editedItem.latLng_code=latLng.code;
      // this.editedItem.latLng=latLng;
      let geo = new this.google.maps.Geocoder();
      geo.geocode(
        { latLng: this.editedItem.latLng },
        async function (res, est) {
          if (est === "OK") self.setAddress(res);
        }
      );
    },
    setAddress(address) {
      this.address = address;
      this.window_open = true;
    },
    openWindow() {
      let self = this;
      let geo = new this.google.maps.Geocoder();
      geo.geocode(
        { latLng: this.editedItem.latLng },
        async function (res, est) {
          if (est === "OK") self.setAddress(res);
        }
      );
    },
    async save() {
      try {
        this.address = [];
        this.window_open = false;
        await Locations.update(this.editedItem._id, this.editedItem);
        this.$emit("update-location", this.editedItem);
      } catch (error) {
        console.log(error);
      }
    },
    getCenter(paths) {
      if (paths.length <= 0) {
        return {
          lat: 23.3143515,
          lng: -103.3223592,
        };
      }
      var bounds = new this.google.maps.LatLngBounds();
      for (let i = 0; i < paths.length; i++) {
        bounds.extend(paths[i]);
      }
      return bounds.getCenter();
    },
  },
  created() {
    this.center =
      !!this.location.latLng && !!this.location.latLng.lat
        ? this.location.latLng
        : { lat: 23.3143515, lng: -103.3223592 };
    this.editedItem = !!this.location ? Object.assign({}, this.location) : {};
  },
  computed: {
    google: gmapApi,
    mapStyle() {
      let opt = {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
      // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
      return this.$vuetify.theme.dark
        ? Object.assign(darkStyle, opt)
        : Object.assign(
            // rieggoStyle,
            opt
          );
    },
  },
};
</script>