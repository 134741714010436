<template>
    <v-card  >
        <v-card-title style="height:100px"  class="indigo lighten-1 white--text">
        
        Detalles de sonda
        <v-spacer></v-spacer>
        <v-btn icon  @click="$emit('input', false);editInfo=false">
            <v-icon>mdi-close</v-icon>
        </v-btn>  
        </v-card-title>
        
        
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card-text>
                            <b style="font-size:20px">Información </b>
                            <v-divider></v-divider>
                            <v-container grid-list-xs>
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Sonda:</b> </v-col>
                                    <v-col cols="12" md="8">{{sensor.name}}</v-col>
                                </v-row>                        
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Ultima Calibración:</b> </v-col>
                                    <v-col cols="12" md="8">{{sensor.last_calibration}}</v-col>
                                </v-row>
                                
                                
                            </v-container>
                        </v-card-text>
                    </v-card>
                    
                </v-col>     
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card-title primary-title>
                            <b style="font-size:20px">Configuración </b>
                            <v-spacer></v-spacer>
                            <v-btn v-if="!editInfo"  text :color="!$vuetify.theme.dark?'primary':'white'" @click="editInfo=true;editedSensor=Object.assign({},sensor)"><v-icon small>fas fa-edit</v-icon>Editar</v-btn>
                        </v-card-title>
                        <v-card-text>
                            
                            
                            <v-divider></v-divider>
                            <v-container grid-list-xs v-if="!editInfo">
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Color:</b> </v-col>
                                    <v-col cols="6" md="4"> 
                                        <div :style="`width:50px;height:20px;background-color:${sensor.color}` "></div>                             
                                    </v-col>
                                    <v-col cols="6" md="4"> 
                                        {{sensor.color}}  
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Unidades:</b> </v-col>
                                    <v-col cols="12" md="8">{{sensor.unit}}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Alias:</b> </v-col>
                                    <v-col cols="12" md="8">{{sensor.alias}}</v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4"> <b>Descripción:</b> </v-col>
                                    <v-col cols="12" md="8">{{sensor.details}}</v-col>
                                </v-row>
                            </v-container>
                            <v-container grid-list-xs v-if="editInfo">
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Color:</b> </v-col>
                                    <v-col cols="6" md="4"> 
                                        <v-dialog
                                            v-model="colorDialog"
                                            width="350"
                                        >
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small :style="`width:20px;height:20px;background-color:${editedSensor.color}` "></v-btn>
                                            </template>
                                    
                                            <v-card>
                                            <v-card-title
                                                
                                            >
                                                
                                            </v-card-title>
                                    
                                            <v-card-text>
                                                <v-color-picker v-model="editedSensor.color"  show-swatches></v-color-picker>
                                            </v-card-text>
                                    
                                            <v-divider></v-divider>
                                    
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                color="primary"
                                                text
                                                @click="colorDialog = false"
                                                >
                                                Aceptar
                                                </v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        
                                        
                                        
                                    </v-col>
                                    <v-col cols="6" md="4"> 
                                        {{editedSensor.color}}  
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4"> <b>Alias:</b> </v-col>
                                    <v-col cols="12" md="8">
                                        <v-text-field
                                            solo
                                            v-model="editedSensor.alias"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4"> <b>Descripción:</b> </v-col>
                                    <v-col cols="12" md="8">
                                        <v-textarea
                                            solo
                                            v-model="editedSensor.details"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-spacer></v-spacer>
                                <v-btn @click="actualizar()" color="success">Guardar</v-btn>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </v-card-text>

    </v-card>
    
    
</template>

<script>
    import moment from 'moment';
    import Device from '../services/DevicesService';
  export default {
    components: {
     
    },
    props:[
       "sensor","device","value"
    ],
    data: () => ({
        colorDialog:false,
        editInfo:false,
        editedSensor:{

        }
      }),
    methods:{
        
        async actualizar(){
            
            try {
                let res=await Device.updateSonda(this.$session.get("token"),this.device,this.editedSensor);
                this.$emit("sensor-update",this.editedSensor);
                this.editInfo=false      
                          
            } catch (error) {
                console.error(error);               
            }
        }    
    },
    beforeCreate(){

    },
    created(){  
        this.editedSensor=Object.assign({},this.sensor);    
               
    },
    computed: {  
        
    },
    mounted(){         
        
    },
    updated(){

    }
  }
</script>