<template>
  <v-card height="400px">
    <div v-if="!!layoutPlane._id">
      <div style="height: 350px;overflow:auto">
        <div id="svgImage" :style="`zoom:${imageZoom}%`">
          <div
            v-for="(child, index) in layoutPlane.childrens"
            :key="child.type + index"
            style="position:relative"
          >
            <img
              v-if="child.type == 'image'"
              :src="child.src"
              :height="child.height"
              :wigth="child.width"
            />

            <v-menu offset-y v-else-if="child.type == 'rect' && child.y">
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  rounded="0"
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    zIndex: 9 + index,
                    position: 'absolute',
                    height: child.height + 'px',
                    width: child.width + 'px',
                    top: child.y + 'px',
                    left: child.x + 'px',
                  }"
                  :color="child.fill"
                  @click="getDevice(child.device)"
                >
                </v-card>
              </template>
              <v-list>
                <v-list-item @click="setDeviceDialog(child)">
                  <v-list-item-icon>
                    <v-icon small>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar dispositivo </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list>
                <v-list-item
                  @click="openDeviceLink(location._id, child.device)"
                  v-if="!!child.device"
                >
                  <v-list-item-icon>
                    <v-icon small>check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    >Seleccionar dispositivo
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <!-- <div v-for="rect " style="">
  
            </div> -->
        </div>
      </div>
    </div>
    <div
      v-else
      style="background:#cdcdcd90;position:absolute;width:100%;height:100%"
    >
      <v-row style="height:100%" justify="center" align="center">
        <v-col col="12" sm="8" md="6" lg="4" class="text-center text-h4">
          Para visualizar este apartado debe cargar un plano.
          <div class="text-caption">
            * Solo acepta archivos .svg
          </div>
        </v-col>
        
      </v-row>
    </div>
    <div style="position:absolute;width: 100%;padding:5px;top:0px">
      <v-card-title>
        <input
          type="file"
          id="file"
          style="visibility:hidden"
          @change="loadImage"
          accept="image/svg+xml"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mr-2"
          fab
          x-small
          @click="imageZoom = imageZoom + 5"
        >
          <v-icon>zoom_in</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="mr-2"
          fab
          x-small
          @click="imageZoom = imageZoom - 5"
        >
          <v-icon>zoom_out</v-icon>
        </v-btn>
        <v-btn color="primary" x-small fab @click="editLayout">
          <v-icon small>edit</v-icon>
        </v-btn>
      </v-card-title>
    </div>
    <Loading :active="loading"></Loading>
    <v-dialog
      v-model="selectDeviceDialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-form @submit.prevent="setDevice()">
          <v-card-title primary-title>
            <div>Seleccionar dispositivo</div>
          </v-card-title>
          <v-card-text>
            <v-select
              color="primary--text"
              v-model="layoutEditedItem.device"
              :items="devices"
              item-text="name"
              item-value="_id"
              rounded
              dense
              filled
              persistent-hint
            >
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="closeSetDeviceDialog"
              >Cancelar</v-btn
            >
            <v-btn color="primary" type="submit">Aceptar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <Alert
      v-model="alertObj.active"
      :title="alertObj.title"
      :text="alertObj.text"
      :type="alertObj.type"
      @accept="loadSvg()"
    ></Alert>
  </v-card>
</template>
<script>
import LocationLayout from "@/services/LocationLayout";
import Loading from "@/components/Loading";
import Alert from "@/components/Alert";
export default {
  props: {
    location: { default: null, type: Object },
    devices: { type: Array, default: () => [] },
  },

  components: { Loading, Alert },
  data: () => ({
    imageZoom: 100,
    excludeElement: ["defs", "#text", "pattern", "use"],
    layoutPlane: {
      width: "0px",
      height: "0px",
      childrens: [],
    },
    layoutEditedIndex: -1,
    layoutEditedItem: {},
    editedIndex: -1,
    loading: false,
    selectDeviceDialog: false,
    alertObj: {
      active: false,
      title: "¿Estás seguro?",
      text: "Ya existe un plano, si continuas se eliminará el plano anterior.",
      type: "confirm",
    },
  }),
  watch: {
    location() {
      this.getLayout();
    },
  },
  created() {},
  methods: {
    setDeviceDialog(e) {
      this.layoutEditedItem = Object.assign({}, e);
      this.layoutEditedIndex = this.layoutPlane.childrens.indexOf(e);
      this.selectDeviceDialog = true;
    },
    closeEditDeviceDialog() {
      this.layoutEditedItem = Object.assign({});
      this.layoutEditedIndex = -1;
      this.selectDeviceDialog = false;
    },
    async setDevice() {
      this.loading = true;
      try {
        this.layoutPlane.childrens[this.layoutEditedIndex] = Object.assign(
          this.layoutPlane.childrens[this.layoutEditedIndex],
          this.layoutEditedItem
        );
        await LocationLayout.update(this.layoutPlane._id, this.layoutPlane);
        this.closeEditDeviceDialog();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    getDevice(e) {
      if (!e) {
      }
    },
    closeSetDeviceDialog() {
      this.layoutEditedItem = {};
      this.layoutEditedIndex = -1;
      this.selectDeviceDialog = false;
    },
    async getLayout() {
      if (!this.location._id) return;
      this.loading = true;
      this.loading = true;
      try {
        let res = await LocationLayout.findByLocation(this.location._id);
        if (!!res) this.layoutPlane = res;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    editLayout() {
      if (!!this.layoutPlane._id) {
        this.alertObj.active = true;
        this.layoutEditedItem = Object.assign(this.layoutPlane);
      } else {
        this.loadSvg();
      }
    },
    async save() {
      if (!this.layoutPlane._id) {
        try {
          let res = await LocationLayout.save({
            ...this.layoutPlane,
            location: this.location._id,
          });
          this.layoutPlane = res;
        } catch (error) {
          console.log(error);
        }
      } else {
        let res = await LocationLayout.update(this.layoutEditedItem._id, {
          ...this.layoutEditedItem,
          location: this.location._id,
        });
        this.layoutPlane = this.layoutEditedItem;
      }
    },
    loadImage() {
      let self = this;
      let _id = "";
      const svgImage = document.getElementById("svgImage");
      if (this.$refs.file.files && this.$refs.file.files[0]) {
        var reader = new FileReader();
        // const parser = new DOMParser();
        reader.onload = function(e) {
          let imageTxt = e.target.result.split("\n");
          if (window.DOMParser) {
            let res = new window.DOMParser().parseFromString(
              e.target.result,
              "text/xml"
            );
            if (!!self.layoutPlane._id) {
              _id = self.layoutPlane._id;
              self.layoutEditedItem = {
                ...self.getChildNodes(res.childNodes)[0],
                _id,
                location: self.location._id,
              };
              self.save();
            } else {
              self.layoutPlane = {
                ...self.getChildNodes(res.childNodes)[0],
                _id,
              };
              self.save();
            }
          }
          // for (let index = 0; index < imageTxt.length; index++) {
          //   const element = imageTxt[index];
          //   if(element.indexOf("<svg") === 0){
          //     console.log(element);
          //   }else if (element.indexOf("<rect") === 0 ) {
          //     // this.layoutPlane
          //   } else {

          //   }
          // }

          // readAsText
        };

        reader.readAsText(this.$refs.file.files[0]);
      }
    },
    getChildNodes(nodes) {
      let result = [];
      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];
        const attributes = element.attributes
          ? this.getAtributes(element.attributes)
          : {};
        if (!this.excludeElement.includes(element.nodeName)) {
          result.push({
            type: element.nodeName,
            width: attributes.width || 0,
            height: attributes.height || 0,
            fill: attributes.fill,
            stroke: attributes.stroke,
            src: attributes["xlink:href"],
            x: attributes.x,
            y: attributes.y,
            childrens: this.getChildNodes(element.childNodes),
          });
        } else {
          let res = this.getChildNodes(element.childNodes);
          res.forEach((element) => {
            result.push(element);
          });
        }
      }
      return result;
    },
    getAtributes(attributes) {
      let result = {};
      for (let index = 0; index < attributes.length; index++) {
        const element = attributes.item(index);
        result[element.nodeName] = element.nodeValue;
      }
      return result;
    },
    loadSvg() {
      let file = document.getElementById("file");
      file.click();
    },
    openDeviceLink(loc, device) {
      location.href = `/mylist/location/${loc}/${device}`;
    },
  },
};
</script>
<style scoped></style>
