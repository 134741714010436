<template>
  <v-carousel
    v-model="model"
    height="60px"
    :vertical="true"
    :hide-delimiters="true"
    :next-icon="false"
    :cycle="true"
    :prev-icon="false"
    :interval="7000"    
  >
    <v-carousel-item v-for="device in items" :key="device.device_id">
      <v-card light>
        <v-card-text>
          <v-row class="fill-height" align="center" justify="center">
            <div class="text-caption">
              <v-avatar
                  :color="`${ calcTime(device.last_send).color }`"
                  size="13"
                >
              </v-avatar>
              Dispositivo : {{ device.device_id }}
              <br />
              Ultima conexión : {{ calcTime(device.last_send).time }}
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import Messages from "../services/Messages";
import moment from "moment";
export default {
  props: { items: Array },

  data() {
    return {
      color: "#2E7D32",
      model: 0,
      fields: [],
    };
  },
  methods: {
    calcTime(time) {
      var a = moment(); //no
      var b = moment(time);
      const minutes = a.diff(b, "minutes");
      const hours = a.diff(b, "hours");
      const days = a.diff(b, "days");
      const weeks = a.diff(b, "weeks");
      const months = a.diff(b, "months");
      if (minutes < 1)
        return { time : "Justo ahora", color : "primary"};
      if (minutes < 60)
        return { time : "Hace " + minutes + ` ${minutes > 1 ? " minutos" : "minuto"}`, color : "primary"};
      else if (hours >= 1 && hours <= 24)
        return { time : "Hace " + hours + ` ${minutes > 1 ? " horas" : " hora"}`, color : "primary"};
      else if (days >= 1 && days <= 7)
        return { time : "Hace " + days + ` ${minutes > 1 ? " días" : " día"}`, color : "warning"};
      else if (weeks >= 1 && months < 1)
        return { time : "Hace " + weeks + ` ${minutes > 1 ? " semanas" : " semana"}`, color : "error" };
      else if (months >= 1 && months < 12)
        return { time : "Hace " + months + ` ${minutes > 1 ? " meses" : " mes"}`, color : "error"};
      return { time : "Hace mas de un año", color : "red"};
    },
  },
};
</script>