<template>
    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="400px"
        transition="dialog-transition"
        class="rounded-lg"
    >
        <v-form v-on:submit.prevent="save()">
            <v-card class="card-rounded">
                <v-card-title primary-title>
                    <v-btn color="grey darken-4" fab text @click="$emit('on-close',!dialog)">
                        <v-icon small>fa-times</v-icon>
                    </v-btn>
                    Editar Sensor
                    
                </v-card-title>
                <v-card-text>
                    <v-row justify="center" align="center" align-content="center">
                        <v-col cols="12" >
                            <v-text-field
                                v-model="sensor.name"
                                name="sensorName"
                                label="Nombre del sensor"
                                id="id"
                                required                                
                                rounded
                                dense
                                filled
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="10">
                            Seleccionar color 
                            <v-color-picker  hide-inputs v-model="sensor.color"></v-color-picker>
                            {{sensor.color}}
                            
                        </v-col> 
                        <v-col cols="8" >
                            
                            <v-row>
                                <v-col cols="8" >
                                    <span style="color:black">Activar alertas</span>
                                </v-col>
                                <v-col cols="4" >
                                    <v-switch
                                        inset
                                        v-model="sensor.alert_active"
                                        name="sensorRangeActive"
                                        color="primary"
                                        :value="true"
                                        width="20%"
                                        class="ma-0 pa-0"
                                    ></v-switch>
                                </v-col>
                            </v-row>               
                        </v-col>
                        
                        <v-col cols="12" >
                            <v-text-field
                                v-model="sensor.range_max"
                                name="sensorRangeMax"
                                label="Rango de medición máximo"
                                id="id"
                                required                                
                                rounded
                                dense
                                filled
                                :disabled="!sensor.range_active"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" >
                            <v-text-field
                                v-model="sensor.range_min"
                                name="sensorRangeMin"
                                label="Rango de medición mínimo"
                                id="id"
                                required                                
                                rounded
                                dense
                                filled
                                :disabled="!sensor.range_active"
                            ></v-text-field>
                        </v-col> 
                                        
                        
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded type="submit">
                        <span class="px-5">Guardar</span>
                    </v-btn>
                    <v-btn color="secondary darken-2" rounded outlined @click="$emit('on-close',!dialog)">
                        <span class="px-5">Cancelar</span>    
                    </v-btn>
                </v-card-actions>
            </v-card>        
        </v-form>            
    </v-dialog>
</template>
<script>
import Sensor from '../services/SensorsService'
export default {
    props:["dialog","sensor"],
    components:{},
    data:()=>({
        
    }),
    methods:{
        async save(){
            let res=await Sensor.update(this.sensor.code,this.sensor);
            this.$emit("update-sensor",Object.assign(this.sensor,res));
        },
    }
}
</script>