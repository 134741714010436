<template>
    <div >
        <span style="font-size:20px">Clima</span> 
        <v-row v-if="values">
            <v-col class="text-left" cols="7">
                <div class="secondary--text" style="font-size:40px; font-weight: bold;">
                    {{(values.current?values.current.temp:0).toFixed(1)}} <span style="font-size:20px;font-weight: bold;">°C</span> 
                </div>
                <div class="primary--text" style="text-transform: capitalize; font-size:20px; font-weight: bold;">
                    {{values.current?values.current.weather[0].description:''}}
                </div>
                <div class="grey--text" style="font-size:14px;">
                    Sensación: {{values.current?values.current.feels_like:''}} °C
                </div>
            </v-col>
            <v-col class="text-center" cols="5">
                <img height="70" :src="`http://openweathermap.org/img/wn/${values.current?values.current.weather[0].icon:''}.png`" alt="">
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col class="text-center">
                        <div class="secondary--text">{{values.current?values.current.humidity:''}} %</div>
                        <div class="primary--text">Humedad</div>
                        
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col class="text-center">
                        <div class="secondary--text">{{values.current?values.current.clouds:''}} %</div>
                        <div class="primary--text">Nubes</div>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col class="text-center">
                        <div class="secondary--text">{{values.current?values.current.wind_speed:''}} m/s</div>
                        <div class="primary--text">Viento</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="text-center">
                <v-slide-group
                    
                    class="pa-4"
                    active-class="success"
                >
                    <v-slide-item
                        v-for="(daily,i) in values.daily"
                        :key="i"
                    >
                        <v-card
                            
                            color="transparent"
                            class="ma-2 card-rounded cloud-style2"                            
                            width="150"
                        >
                            <v-row v-if="values">
                                <v-col class="text-center" cols="12">
                                    <div class="ma-4" style="text-transform: capitalize;">{{$moment(new Date()).add(i+1, 'days').format("dddd")}}</div>
                                    <div style="position:absolute;top:35px;left:45px;"><img height="60"  :src="`http://openweathermap.org/img/wn/${daily.weather[0].icon}.png`" alt=""></div>
                                </v-col>
                                <v-col class="text-center" cols="12">
                                    <div class="secondary--text" style="font-size:20px; font-weight: bold;">
                                        {{(daily.temp.day || 0).toFixed(1)}} <span style="font-size:15px;font-weight: bold;">°C</span> 
                                    </div>
                                    <div class="primary--text" style="text-transform: capitalize; font-size:12px; font-weight: bold;">
                                        {{daily.weather[0].description}}
                                    </div>
                                    <div class="grey--text" style="font-size:10px;">
                                        Sensación: {{daily.feels_like.day}} °C
                                    </div>
                                </v-col>                            
                            </v-row>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import moment from "moment";
export default {
    props:["values"],
    components:{},
    data:()=>({
        moment
    }),
    watch:{
        
    },
    mounted(){
       
    }
}
</script>
<style >
    
</style>