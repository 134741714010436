<template>
  <v-card height="100%" max-height="800px" flat>
    <v-card-title primary-title>
      Mediciones <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text style="max-height: 700px; overflow-y: auto">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        loading-text="Cargando... Espere por favor."
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.used="{ item }">
          {{
            new Intl.NumberFormat({
              style: "currency",
              currency: "USD",
            }).format(item.used)
          }}
          LTS
        </template>
        <template v-slot:item.type.text="{ item }">
          <span class="text-uppercase">
            {{ item.type.text }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Locations from "@/services/Locations";
export default {
  props: {
    location: { type: String },
    dateRange: { type: Array, default: () => [] },
  },
  components: {},
  data: () => ({
    loading: false,
    items: [],
    headers: [
      { text: "Ubicación", value: "type.text", align: "center" },
      { text: "Consumo", value: "used", align: "center" },
    ],
  }),
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const res = await Locations.getGroupedData(
          this.location,
          this.dateRange
        );
        this.items = res;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {},
};
</script>
