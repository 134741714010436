import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
    extends: Doughnut,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
        this.$refs.canvas.parentNode.style.height = '235px'; 
        this.renderChart(this.chartData, this.options);
    },
    watch: {
        chartData(){
            this.renderChart(this.chartData, this.options)
            
        }
    }
}