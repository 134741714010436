<template>
  <div>
    <v-card class="cloud-style2 secondary--text">
      <v-card-title primary-title>
        Volumen histórico

        <v-spacer></v-spacer>
        <span
          style="text-transform: capitalize; font-size: 13px"
          class="grey--text text--darken-4 mx-5"
        >
          {{ $moment(dateRange[0]).format("MMMM-DD YYYY") }} -
          {{ $moment(dateRange[1]).format("MMMM-DD YYYY") }}
        </span>

        <span style="font-size: 14px" class="primary--text">
          Consumo total:
        </span>
        <span style="font-size: 20px" class="mx-2">
          {{ new Intl.NumberFormat("en-US").format(totalFlow) }} Lts
        </span>

        <span style="position: relative" class="">
          <RangeScale
            :options="optObject"
            unit="lts"
            @update-options="updateRange($event)"
          />
        </span>
      </v-card-title>
      <v-card-text class="mb-0">
        <BarChart :chartData="chartData" :options="options" />
        <Loading :active="loading" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Sensors from "@/services/SensorsService";
import BarChart from "@/components/chartJs/Bars";
import Loading from "@/components/Loading";
import RangeScale from "@/components/RangeScale";
import _ from "lodash";
import moment from "moment";
export default {
  props: ["sensors", "dateRange", "device", "restore"],
  components: { BarChart, Loading, RangeScale },
  data: () => ({
    loading: false,
    totalFlow: 0,

    chartData: {
      labels: [],
      datasets: [],
    },
    optObject: {
      spanGaps: true,

      maintainAspectRatio: false,
      responsive: true,
      chartArea: {
        background: "#ffffff",
      },
      legend: {
        position: "top",
        display: false,
        labels: {
          // fontColor:"#ffffff"
        },
      },
      title: {
        position: "bottom",
        display: true,
        text: "",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },

      scales: {
        xAxes: [
          {
            fontColor: "#fff",
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Tiempo",
              // fontColor:"white"
            },
            gridLines: {
              color: "rgba(171,171,171,0.5)",
              lineWidth: 0.1,
              display: false,
            },

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              // labelString: 'Valor',
              fontSize: 16,
              fontStyle: "bold",
              // fontColor:"white"
            },
            ticks: {
              display: true,
              beginAtZero: true,
              steps: 10,
              // stepSize: 10,
              stepValue: 5,
              callback: function (value, index, values) {
                return value > 1000
                  ? new Intl.NumberFormat("en-EU").format(value / 1000) + " m3"
                  : value + " Lts";
              },
            },
            plotBands: [
              {
                color: "orange", // Color value
                from: 0, // Start of the plot band
                to: 0, // End of the plot band
              },
            ],
            gridLines: {
              color: "rgba(171,171,171,0.5)",
              lineWidth: 0.5,
              display: false,
            },
          },
        ],
      },
    },
  }),
  watch: {
    dateRange() {
      this.loadData();
    },
    sensors: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    restore() {
      this.loadData();
    },
  },
  methods: {
    getArea(total, area, unit) {
      if (unit === "ha") {
        return total / (area * 10000);
      }
      if (unit === "m²") {
        return total / area;
      }
    },
    updateRange(e) {
      this.optObject = Object.assign({}, e);
    },
    adjustReset(v) {
      if (v < 0) return this.adjustReset(v + 16777215);
      else return v;
    },
    async loadData() {
      if (this.loading) return;
      let self = this;
      this.loading = true;

      try {
        let labels = [];
        let datasets = [];
        let dateInit = this.$moment(this.dateRange[0]);
        let dateEnd = this.$moment(this.dateRange[1]);
        let days = dateEnd.diff(dateInit, "days");
        for (let index = 0; index < this.sensors.length; index++) {
          const sensor = this.sensors[index];
          if (sensor.selected) {
            let res = await Sensors.getDataByDateRange(
              sensor._id,
              new Date(this.dateRange[0]),
              new Date(this.dateRange[1]),
              800
            ); 
            res = res.map((item) => {
              let value = item.values.find((val) => val.value_type._id == 6);
              if (days == 0)
                value.hour =
                  self.$moment(value.created_at).format("HH") + ":00 hrs";
              else
                value.hour = self
                  .$moment(value.created_at)
                  .format("YYYY-MM-DD");
              return { ...value, reset: item.reset };
            });
            // if(this.device.device_type=="FlowV2"){
            //     if(res.length>1) this.totalFlow=res[res.length-1].value-res[0].value;
            // }
            let group = _.groupBy(res, "hour");
            let count = 0;
            let keys = Object.keys(group);
            let dataset = {};
            for (let i = 0; i < keys.length; i++) {
              let key = keys[i];
              let index = labels.indexOf(key);
              if (index == -1) {
                labels.push(key);
              }

              if (Object.hasOwnProperty.call(group, key)) {
                const element = group[key];
                if (
                  this.device.device_type == "FlowV2" ||
                  this.device.device_type == "FlowV3"
                ) {
                  if (element[element.length - 1].reset) {
                    let lastReset = element.find((item) => item.reset);
                    let lastResetIndex = element.indexOf(lastReset);
                    let res =
                      lastResetIndex >= 2
                        ? element[lastResetIndex - 1].value - element[0].value
                        : element[0].value;
                    if (res < 0) res = this.adjustReset(res);
                    group[key] = { value: res };
                  } else {
                    let sum = 0;
                    if (i > 0) {
                      sum =
                        element[element.length - 1].value -
                        group[keys[i - 1]][group[keys[i - 1]].length - 1].value;
                    } else {
                      sum =
                        element[element.length - 1].value - element[0].value;
                    }
                    if (sum < 0) sum = this.adjustReset(sum);
                    dataset[key] = { value: sum };
                  }
                } else
                  dataset[key] = {
                    value: element
                      .map((item) => item.value)
                      .reduce((a, b) => a + b) ,
                  };
              }
              count++;
            }
            datasets.push({ sensor, data: dataset });
          }
        }
        labels = labels.sort();
        this.convertirDatos(datasets, labels);
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
    convertirDatos(data, labels) {
      var self = this;

      var obj = Object.assign({
        labels: [],
        datasets: [],
      });
      var dataset = {
        // one line graph
        label: [],
        spanGaps: false,
        fondoColor: "",
        backgroundColor: [],

        borderWidth: 0,
        hidden: false,
        lineTension: 0.0,
        data: [],
      };

      try {
        this.totalFlow = 0;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          let d = [];
          for (let j = 0; j < labels.length; j++) {
            const label = labels[j];
            d.push(element.data[label].value);
          }
          // if(this.device.device_type!="FlowV2"){
          self.totalFlow =
            self.totalFlow +
            (d.length > 1
              ? d.reduce((a, b) => a + b)
              : d.length == 1
              ? d[0]
              : 0);
          // }
          obj.datasets.push({
            ...dataset,
            data: d,
            label: this.sensors[i].name,
            fondoColor: element.sensor.color,
          });
        }
        obj.labels = labels;
        this.chartData = obj;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    options() {
      let self = this;
      let callbacks = {
        title: function (context) {
          if (
            moment(context[0].label).format("dddd DD [de] MMM [de] YYYY") !=
            "Fecha inválida"
          )
            return moment(context[0].label).format(
              "dddd DD [de] MMM [de] YYYY"
            );
          return context[0].label;
        },
        label: function (context) {
          return (
            self.chartData.datasets[context.datasetIndex].label +
            " " +
            Intl.NumberFormat("en-EU").format(context.value) +
            " Lts"
          );
        },
      };
      return Object.assign(this.optObject, {
        tooltips: { ...this.optObject.tooltips, callbacks },
      });
      return;
    },
  },
};
</script>
