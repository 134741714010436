<template>
  <div style="height: 100%; width: 100%">
    <GmapMap
      :center="center"
      :zoom="zoom"
      :map-type-id="mapType"
      v-bind:options="mapStyle"
      style="width: 100%; height: 100%; border-radius: 20px"
    >
      <GmapCluster
        :zoomOnClick="true"
        :gridSize="6"
        :minimumClusterSize="5"
        ref="cluster"
      >
        <GmapMarker
          v-if="!!locations"
          :key="location._id"
          v-for="location in filterLocations"
          :position="location.latLng"
          :icon="{
            ...icon,
          }"
          @click="openInfoWindowTemplate(location, $event)"
        />
      </GmapCluster>
      <gmap-info-window
        :options="{
          maxWidth: 300,
          pixelOffset: { width: 0, height: -35 },
        }"
        :position="infoWindow.position"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open = false"
      >
        <!-- <div v-html="infoWindow.template"></div> -->
        <v-card class="mx-auto" max-width="344">
          <v-card-text>
            <div>{{ infowindowData.name }}</div>
            <div class="text--primary">
              Descripción: {{ infowindowData.description }}
            </div>
            <div class="text--primary">Status: {{ infowindowData.status }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              block
              :to="`/mylist/location/${infowindowData._id}`"
            >
              Ver mas
            </v-btn>
          </v-card-actions>
        </v-card>
      </gmap-info-window>
      <!-- @click="redirectFarm(location._id)" -->
    </GmapMap>
    <div style="position: absolute; top: 80px; left: 50px; width: 300px">
      <v-text-field
        v-model="searchLocations"
        name="search"
        label="Buscar"
        id="search"
        solo
        prepend-inner-icon="mdi-map-marker"
      ></v-text-field>
    </div>
    <div style="position: absolute; top: 80px; right: 10px">
      <v-btn color="white" @click="$emit('map', false)">
        <v-icon>dashboard</v-icon>
      </v-btn>
    </div>
    <div style="position: absolute; bottom: 20px; right: 10px">
      <div>
        <v-btn
          x-small
          color="secondary"
          elevation="0"
          class="mb-1"
          dark
          fab
          @click="zoomPlus()"
        >
          <v-icon color="" small>fa-plus</v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          x-small
          color="secondary"
          elevation="0"
          class="mb-1"
          dark
          fab
          @click="zoom--"
        >
          <v-icon color="" small>fa-minus</v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          x-small
          color="secondary"
          elevation="0"
          class="mb-1"
          dark
          fab
          @click="setFullScreen()"
        >
          <v-icon color="" small>fa-compress</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="fullscreen"
      scrollable
      fullscreen
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div style="heigh: 100%; width: 100%">
        <GmapMap
          :center="center"
          :zoom="zoom"
          :map-type-id="mapType"
          v-bind:options="mapStyle"
          style="width: 100%; height: 100%; position: relative"
        >
          <GmapCluster
            :zoomOnClick="true"
            :gridSize="6"
            :minimumClusterSize="5"
            ref="cluster"
          >
            <GmapMarker
              v-if="!!locations"
              :key="location._id"
              v-for="location in filterLocations"
              :position="location.latLng"
              :icon="{
                ...icon,
              }"
              @click="openInfoWindowTemplate(location, $event)"
            />
          </GmapCluster>
          <!-- <GmapMarker
              v-if="!!locations"
              :key="location._id"
              v-for="location in filterLocations"
              :position="location.latLng"
              :icon="{
                ...icon,
                
              }"
              @click="openInfoWindowTemplate(location, $event)"
            /> -->
          <gmap-info-window
            :options="{
              maxWidth: 300,
              pixelOffset: { width: 0, height: -35 },
            }"
            :position="infoWindow.position"
            :opened="infoWindow.open"
            @closeclick="infoWindow.open = false"
          >
            <!-- <div v-html="infoWindow.template"></div> -->
            <v-card class="mx-auto" max-width="344">
              <v-card-text>
                <div>{{ infowindowData.name }}</div>
                <div class="text--primary">
                  Descripción: {{ infowindowData.description }}
                </div>
                <div class="text--primary">
                  Status: {{ infowindowData.status }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="teal accent-4"
                  :to="`/mylist/location/${infowindowData._id}`"
                >
                  Ver mas
                </v-btn>
              </v-card-actions>
            </v-card>
          </gmap-info-window>
          <!-- @click="redirectFarm(location._id)" -->
        </GmapMap>
        <div style="position: absolute; top: 20px; left: 50px; width: 300px">
          <v-text-field
            v-model="searchLocations"
            name="search"
            label="Buscar"
            id="search"
            solo
            prepend-inner-icon="mdi-map-marker"
          ></v-text-field>
        </div>
        <div style="position: absolute; bottom: 20px; right: 10px">
          <div>
            <v-btn
              x-small
              color="secondary"
              elevation="0"
              class="mb-1"
              dark
              fab
              @click="zoomPlus()"
            >
              <v-icon color="" small>fa-plus</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn
              x-small
              color="secondary"
              elevation="0"
              class="mb-1"
              dark
              fab
              @click="zoom--"
            >
              <v-icon color="" small>fa-minus</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn
              x-small
              color="secondary"
              elevation="0"
              class="mb-1"
              dark
              fab
              @click="setFullScreen()"
            >
              <v-icon color="" small>fa-compress</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import darkStyle from "./maps/darkStyle.json";
import rieggoStyle from "./maps/rieggoStyle.json";
import Devices from "../services/DevicesService";
const URL = process.env.VUE_APP_URL;
export default {
  props: ["device", "locations"],
  components: {},
  data: () => ({
    searchLocations: "",
    dialog: false,
    fullscreen: false,
    window_open: false,
    zoom: 5,
    mapType: "roadmap",
    center: {
      lat: 23.3143515,
      lng: -103.3223592,
    },
    polygonOptions: {
      strokeColor: "#00C7B1",
      fillColor: "#00C7B1",
      fillOpacity: 0.8,
      strokeOpacity: 0.8,
      strokeWeight: 2,
    },
    icon: {
      url: URL + "img/marker-roto.svg",
    },
    address: [],
    editedItem: {},
    infoWindow: {
      position: { lat: 0, lng: 0 },
      open: false,
      template: "",
    },
    infowindowData: {
      description: "",
      name: "",
      status: "",
      _id: "",
    },
  }),
  watch: {},
  methods: {
    zoomPlus() {
      this.zoom = this.zoom + 1;
    },
    async setFullScreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        this.getFullscreen(document.documentElement);
      } else {
        await this.exitFullscreen();
      }
    },
    getFullscreen(element) {
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullscreen)
        element.webkitRequestFullscreen();
      else if (element.msRequestFullscreen) element.msRequestFullscreen();
    },
    async exitFullscreen() {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    },
    redirectFarm(url) {
      this.exitFullscreen();
      this.$router.push("/myfarm/location/" + url);
    },
    openInfoWindowTemplate(location, e) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      this.infoWindow.position = { lat: lat, lng: lng };
      this.infoWindow.open = !this.infoWindow.open;
      this.infowindowData = location;
    },

    // setPosition(mapData,location){
    //     let self=this;
    //     if (mapData.latLng) {
    //         this.editedItem.location=this.center = {
    //             lat: mapData.latLng.lat(),
    //             lng: mapData.latLng.lng()
    //         }
    //     }
    //     this.editedItem.location_code=location.code;
    //     this.editedItem.location=location;
    //     let geo=new this.google.maps.Geocoder();
    //     geo.geocode({location:this.editedItem.location},async function(res,est){
    //         if(est=== "OK") self.setAddress(res);
    //     })
    // },
    // setAddress(address){
    //     this.address=address;
    //     this.window_open=true;
    // },
    // openWindow(){
    //     let self=this;
    //     let geo=new this.google.maps.Geocoder();
    //     geo.geocode({location:this.editedItem.location},async function(res,est){
    //         if(est=== "OK") self.setAddress(res);
    //     })
    // },
    // async save(){
    //     this.address=[];
    //     this.window_open=false;
    //     let res=await Devices.update(this.editedItem._id,this.editedItem);
    //     this.$emit('update-device',this.editedItem);

    // },
    getCenter(paths) {
      if (paths.length <= 0) {
        return {
          lat: 23.3143515,
          lng: -103.3223592,
        };
      }
      var bounds = new this.google.maps.LatLngBounds();
      for (let i = 0; i < paths.length; i++) {
        bounds.extend(paths[i]);
      }
      return bounds.getCenter();
    },
  },
  created() {
    // this.center=(!!this.device.location && !!this.device.location.lat)?this.device.location:{lat:23.3143515,lng:-103.3223592};
    // this.editedItem=!!this.device?Object.assign({},this.device):{};
    addEventListener("fullscreenchange", function (event) {
      if (!event.srcElement.classList[0]) this.fullscreen = false;
    });
  },
  computed: {
    filterLocations() { 
      if (this.searchLocations.length <= 0) return this.locations;
      else
        return this.locations.filter((v) =>
          v.name.toUpperCase().includes(this.searchLocations.toUpperCase())
        );
    },
    google: gmapApi,
    mapStyle() {
      let opt = {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
      // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
      return this.$vuetify.theme.dark
        ? Object.assign(darkStyle, opt)
        : Object.assign(
            // rieggoStyle,
            opt
          );
    },
  },
};
</script>